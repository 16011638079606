import React, { useContext, useState, useEffect } from 'react';
import './AddCountryPopup.css';
import cn from "classnames";
import styles from './AddCountryPopup.module.sass';
import Modal from '@mui/material/Modal';
import Card from '../../../../components/Card';
import { GlobalState } from '../../../../App';
import TextInput from '../../../../components/TextInput';
import { getKeywordDocId, validate_if_not_empty } from '../../../../utils/extensions';
import Icon from '../../../../components/Icon';


const AddCountryPopup = () => {
  const { setIsClickedSave,
    countriesList, setCountriesList,
    activeEditCountry, setActiveEditCountry,
    setShowCountryPopup,
    showModalViewCountry, setShowModalViewCountry,
  } = useContext(GlobalState)

  const [country, setCountry] = useState()

  const handleClose = () => {
    setShowModalViewCountry(false);
  }

  const TAG = "keyword popup"
  useEffect(() => {
    setIsClickedSave(false)
    // console.log(TAG, countriesList)
  }, [])

  useEffect(() => {
    // console.log(TAG, activeEditCountry)
    if (activeEditCountry) {
      setCountry(activeEditCountry?.title)
    }
  }, [activeEditCountry])

  const countryDetailsObj = {
    id: getKeywordDocId(),
    title: '',
    dateCreated: '',
    dateEdited: ''
  }

  const createCountry = async () => {
    countryDetailsObj.title = country;
    // console.log(TAG, "after", countryDetailsObj)
    await setCountriesList((oldData) => [...oldData, countryDetailsObj])
    setIsClickedSave(true)
    setShowCountryPopup(false)
  }

  const updateCountry = async () => {
    for (let index = 0; index < countriesList.length; index++) {
      if (countriesList[index]?.id === activeEditCountry?.id) {
        // console.log(TAG, countriesList[index]?.id, activeEditCountry?.id, activeEditCountry?.title)
        countriesList[index].title = country;
        setActiveEditCountry()
        setIsClickedSave(true)
        setShowCountryPopup(false)
      }
    }
  }

  return (
    <Modal
      disableAutoFocus={true}
      open={showModalViewCountry}
      onClose={handleClose}
      className='dialog-wrapper'
      sx={{
        outline: '0 !important',
        border: 'none !important'
      }}
    >
      <Card
        className={cn(styles.card)}
        title="Add country"
        classTitle="title-green"
        classCardHead={styles.cardHead}
        head={
          <div style={{
            display: 'flex',
            gap: '12px'
          }}>

            <button
              onClick={() => {
                if (validate_if_not_empty(country, "countryTitlePopupErrId")) {
                  activeEditCountry ? updateCountry() :
                    createCountry()
                }
              }}
              className={cn("button", styles.button)} >
              <span>Save</span>
            </button>
            <button
              onClick={() => {
                setShowModalViewCountry(false)
              }}
              className={cn(styles.closeButton)} >
              <Icon name="close" fill="red" size="16" />
            </button>
          </div>

        }
      >
        <div className={styles.fieldContainer}>
          <TextInput
            className={styles.field}
            classInput={styles.fieldInput}
            label="Country"
            name="popupCountry"
            type="text"
            onChange={(e) => { setCountry(e.target.value) }}
            value={country}
            required
          />
          <p className={styles.errField} id="countryTitlePopupErrId"></p>
        </div>
      </Card>

    </Modal>

  )
}

export default AddCountryPopup
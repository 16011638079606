import React from "react";
import styles from "./Control.module.sass";
import cn from "classnames";
import Icon from "../../../../components/Icon";
import Actions from "../../../../components/Actions";
import { Link } from "react-router-dom";
// import { GlobalState } from "../../../../App";
// import { deleteCoupon, deleteSeller } from "../../../../Firebase/firebaseDatabase";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Control = ({ className, visibleActions, setVisibleActions, up, data }) => {

  const theme = useTheme();
  const matches1022 = useMediaQuery(theme.breakpoints.down(1023));
  // const { setIsActionComplete, setIsClicked } = useContext(GlobalState)

  const actions = [
    {
      title: "Edit",
      icon: "edit",
      action: () => { window.location.href = `/coupon/${data.couponId}/edit` },
    },
    // {
    //   title: "Delete forever",
    //   icon: "trash",
    //   action: () => {
    //     setIsClicked(true)
    //     deleteCoupon(data?.couponId, setIsActionComplete,
    //       setIsClicked)
    //   },
    // },
  ];

  // const delete_actions = [
  //   {
  //     title: "Yes",
  //     icon: "check",
  //     action: () => {
  //       //// console.log("delete client");
  //       setIsClicked(true)
  //       deleteCoupon(data?.couponId, setIsActionComplete,
  //         setIsClicked)
  //     },
  //   },
  //   {
  //     title: "No",
  //     icon: "close",
  //     action: () => {
  //     },
  //   }
  // ]
  return (
    <div className={cn(styles.control, className)}>

      <Link className={styles.editButton} to={`/coupon/${data?.couponId}/edit`}>
        <button className={styles.button}>
          <Icon name="edit" size="20" />
        </button>
      </Link>

      {matches1022 ?
        <Actions
          className={styles.actions}
          classActionsHead={styles.actionsHead}
          classActionsBody={styles.actionsBody}
          classActionsOption={styles.actionsOption}
          items={actions}
          data={data}
          visible={visibleActions}
          setVisible={setVisibleActions}
          up={up}

        />
        :<></>
        // <Actions
        //   className={styles.actions}
        //   classActionsHead={styles.actionsHead}
        //   classActionsBody={styles.actionsBody}
        //   // classActionsOption={styles.actionsOption}
        //   icon="trash"
        //   items={delete_actions}
        //   data={data}
        //   visible={visibleActions}
        //   setVisible={setVisibleActions}
        //   up={up}
        // />
      }
    </div>
  );
};

export default Control;

import React from 'react';
import './LoadingDialog.css';
import Modal from '@mui/material/Modal';
import LoadingIndicator from './LoadingIndicator';


const LoadingDialog = () => {
    return (
        <Modal
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className="loading-dialog-container">
                <LoadingIndicator />
            </div>
        </Modal>

    )
}

export default LoadingDialog
import React, { useContext, useState, useEffect } from 'react';
import './AddShippingMethodPopup.css';
import cn from "classnames";
import styles from './AddShippingMethodPopup.module.sass';
// import ThumbsUp from '../GetQuote/FormSection/Images/thumbsUp.png';
import Modal from '@mui/material/Modal';
import Card from '../../../../components/Card';
import { GlobalState } from '../../../../App';
import TextInput from '../../../../components/TextInput';
import { getShippingMethodDocId, validate_if_not_empty } from '../../../../utils/extensions';
import Icon from '../../../../components/Icon';


const AddShippingMethodPopup = () => {

  // console.log()
  const { setIsClickedSave,
    shippingMethodList, setShippingMethodList,
    activeShippingMethod, setActiveShippingMethod,
    setShowShippingMethodPopup,
    showModalViewShippingMethods, setShowModalViewShippingMethods } = useContext(GlobalState)

  const [title, setTitle] = useState()
  const [value, setValue] = useState()

  const [dateCreated, setDateCreated] = useState()

  const TAG = "shipping method popup"
  useEffect(() => {
    setIsClickedSave(false)
    // console.log(TAG, shippingMethodList)
  }, [])

  useEffect(() => {
    console.log(TAG, "active :", activeShippingMethod)

    if (activeShippingMethod) {
      setTitle(activeShippingMethod?.title)
      setValue(activeShippingMethod?.value)
    }


  }, [activeShippingMethod])

  const handleClose = () => {
    setShowModalViewShippingMethods(false);
  }

  const shippingMethodObj = {
    id: getShippingMethodDocId(),
    title: '',
    value: '',
    // dateCreated: dateCreated ?? '',
    // dateEdited: ''
  }

  const createShippingMethod = async () => {
    shippingMethodObj.title = title;
    shippingMethodObj.value = value;
    console.log(TAG, "after", shippingMethodObj)
    await setShippingMethodList((oldData) => [...oldData, shippingMethodObj])
    setIsClickedSave(true)
    setShowShippingMethodPopup(false)
  }

  const updateShippingMethod = async () => {
    for (let index = 0; index < shippingMethodList.length; index++) {
      if (shippingMethodList[index]?.id === activeShippingMethod?.id) {
        console.log(TAG, shippingMethodList[index]?.id, activeShippingMethod?.id, activeShippingMethod?.title, activeShippingMethod?.value)
        shippingMethodList[index].title = title;
        shippingMethodList[index].value = value;
        setIsClickedSave(true)
        setActiveShippingMethod()
        setShowShippingMethodPopup(false)
      }

    }

  }

  // const [location, setLocation] = useState()

  return (
    <Modal
      disableAutoFocus={true}
      open={showModalViewShippingMethods}
      onClose={handleClose}
      className='dialog-wrapper'
      sx={{
        outline: '0 !important',
        border: 'none !important'
      }}
    >
      <Card
        className={cn(styles.card)}
        title="Add shipping method"
        classTitle="title-green"
        classCardHead={styles.cardHead}
        head={
          <div style={{
            display: 'flex',
            gap: '12px'
          }}>

            <button
              onClick={() => {
                if (validate_if_not_empty(title, "shippingTitlePopupErrId") && validate_if_not_empty(value, "shippingValuePopupErrId")) {
                  activeShippingMethod ? updateShippingMethod() :
                    createShippingMethod()
                }
              }}
              className={cn("button", styles.button)} >
              <span>Save</span>
            </button>
            <button
              onClick={() => {
                setShowModalViewShippingMethods(false)
              }}
              className={cn(styles.closeButton)} >
              <Icon name="close" fill="red" size="16" />
            </button>
          </div>

        }
      >
        <div className={styles.fieldContainerWrapper}>
          <div className={styles.fieldContainer}>
            <TextInput
              className={styles.field}
              classInput={styles.fieldInput}
              label="Title"
              name="shippingMethodTitle"
              type="text"
              onChange={(e) => { setTitle(e.target.value) }}
              value={title}
              required
            />
            <p className={styles.errField} id="shippingTitlePopupErrId"></p>
          </div>

          <div className={styles.fieldContainer}>
            <TextInput
              className={styles.field}
              classInput={styles.fieldInput}
              label="Value"
              name="shippingMethodValue"
              type="number"
              onChange={(e) => { setValue(e.target.value) }}
              value={value}
              required
            />
            <p className={styles.errField} id="shippingValuePopupErrId"></p>
          </div>
        </div>

      </Card>

    </Modal>

  )
}

export default AddShippingMethodPopup
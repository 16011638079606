import React, { useState } from "react";
import styles from "./Row.module.sass";
import Control from "../../Control";
import cn from "classnames";

const Row = ({ item, value, onChange, up }) => {
  const [visibleActions, setVisibleActions] = useState(false);

  return (
    <>
      <div className={styles.row}
      >
        <div className={styles.col}>
          <div className={styles.label}>Coupon code</div>
          <div className={styles.box}>
            <div className={styles.number}>
              {item?.code}
            </div>
          </div>
        </div>

        <div className={styles.col}>
          <div className={styles.label}>Condition</div>
          <div className={styles.box}>
            <div className={styles.number}>
              {item?.condition}
            </div>
          </div>
          <Control
            className={styles.control}
            visibleActions={visibleActions}
            setVisibleActions={setVisibleActions}
            up={up}
            data={item}
          />
        </div>

        <div className={styles.col}>
          <div className={styles.label}>Amount/Percentage</div>
          <div className={styles.box}>
            <div className={styles.number}>
              {item?.discountAmount !== '' ? item.discountAmount + "SAR" : item?.discountPercentage !== '' ? item?.discountPercentage + "%" : <></>}
            </div>
          </div>
        </div>

        <div className={styles.col}>
          <div className={styles.label}>Expiry date</div>
          <div className={styles.box}>
            <div className={styles.number}>
              {item?.expiryDate}
            </div>
          </div>
        </div>

        <div className={styles.col}>
          <div className={styles.label}>Status</div>
          <div className={styles.box}>
            <div className={styles.number}>
              {item.status === "Active" ? (
                <div className={cn("status-green", styles.status)}>{item.status.charAt(0).toUpperCase() + item.status.slice(1).toLowerCase()}</div>
              ) :
                item.status === "Expired" ? (
                  <div className={cn("status-red", styles.status)}>{item.status.charAt(0).toUpperCase() + item.status.slice(1).toLowerCase()}</div>
                ) :<></>
              }
            </div>
          </div>
        </div>

      </div>

    </>
  );
};

export default Row;

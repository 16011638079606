import React, { useState, useEffect, useContext } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./VariationsDropdown.module.sass";
import Tooltip from "../Tooltip";
import { GlobalState } from "../../App";

const VariationsDropdown = ({
  className,
  classDropdownHead,
  classDropdownLabel,
  value,
  // setValue,
  options,
  label,
  tooltip,
  small,
  upBody,
  handlechangeVal,
  placeholder,
  onClick,
  isFrom,
  id,
  ...props
}) => {


  const [visible, setVisible] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0)
  const { variationsList } = useContext(GlobalState)
  const handleClick = async (value, index) => {

    if (index === 0) {
      variationsList[selectedIndex].status = ""
    } else {

      if (selectedIndex === id) {
        variationsList[selectedIndex].status = value
        setVisible(false);
      }

    }
  };



  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      {label && (
        <div className={cn(styles.label, classDropdownLabel)}>
          {label}{" "}
          {tooltip && (
            <Tooltip
              className={styles.tooltip}
              title={tooltip}
              icon="info"
              place="right"
            />
          )}
        </div>
      )}
      <div
        className={cn(
          styles.dropdown,
          className,
          { [styles.small]: small },
          {
            [styles.active]: visible,
          }
        )}
      >
        <div
          className={cn(styles.head, classDropdownHead)}
          onClick={() => {
            setVisible(!visible);
            setSelectedIndex(id)
          }}
        >

          <div className={styles.selection}>{
            variationsList[selectedIndex]?.status === '' ? "Select status" : variationsList[selectedIndex]?.status
          }</div>


        </div>
        <div className={cn(styles.body, { [styles.bodyUp]: upBody })}>
          {options.map((x, index) => (
            <div
              className={cn(styles.option, {
                [styles.selectioned]: x === value,
              })}
              onClick={() => {

                index === 0 ?
                  <></> :

                  handleClick(x, index)
              }
              }
              key={index}
              placeholder={placeholder}
              {...props}
            >
              {x}
            </div>
          ))}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default VariationsDropdown;

import React, { useState, useEffect } from "react";
import styles from "./Coupons.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Form from "../../../components/Form";
import Market from "./Market";

const Coupons = () => {

  const [search, setSearch] = useState("");

  return (
    <Card
      className={styles.card}
      title="All Coupons"
      classTitle={cn("title-purple", styles.title)}
      classCardHead={styles.head}
      head={
        <>
          <Form
            className={styles.form}
            value={search}
            setValue={setSearch}
            placeholder="Search by code"
            type="text"
            name="search"
            icon="search"
          />
        </>
      }
    >
      <div className={styles.products}>
        <div className={styles.wrapper}>
          <Market searchBy={search} />
        </div>
      </div>
    </Card>
  );
};

export default Coupons;

import React from "react";
import TooltipGlodal from "../../components/TooltipGlodal";
import Overview from "./Overview";

const Home = () => {
  return (
    <>
      <div>
        <Overview />
      </div>
      <TooltipGlodal />
    </>
  );
};

export default Home;

import React, { useState } from "react";
import styles from "./Control.module.sass";
import cn from "classnames";
import Icon from "../../../../components/Icon";
import Actions from "../../../../components/Actions";
import { deleteBanner } from "../../../../Firebase/firebaseDatabase";

const Control = ({ className, visibleActions, up, setVisibleActions, data, setIsActionComplete, setIsClicked }) => {

  console.log("control data:", data)
  const delete_actions = [
    {
      title: "Yes",
      icon: "check",
      action: () => {
        setIsClicked(true);
        deleteBanner(data.id).then(res => {
          if (res === true) {
            setIsActionComplete(true)
            setIsClicked(false)
          }
        })
      },
    },
    {
      title: "No",
      icon: "close",
      action: () => {
        // setVisibleActions(false)
      },
    }
  ]

  return (
    <>
      <div className={cn(styles.control, className)}>
        <Actions
          className={styles.actions}
          classActionsHead={styles.actionsHead}
          classActionsBody={styles.actionsBody}
          classActionsOption={styles.actionsOption}
          icon="trash"
          items={delete_actions}
          data={data}
          visible={visibleActions}
          setVisible={setVisibleActions}
          up={true}
        />
      </div>
    </>
  );
};

export default Control;

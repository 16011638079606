import React from "react";
import cn from "classnames";
import styles from "./TextInputQuantity.module.sass";
import Icon from "../Icon";
import Tooltip from "../Tooltip";
import './TextInputQuantity.css';

const TextInputQuantity = ({
  className,
  classLabel,
  classInput,
  label,
  icon,
  copy,
  currency,
  tooltip,
  place,
  onKeyUp,
  warningIcon,
  checkIcon,
  id,
  warningIconId,
  inputIconId,
  validateSuccessIcon,
  phoneNumInitials,
  value,
  ...props
}) => {

  return (
    <div
      className={cn(
        styles.field,
        { [styles.fieldIcon]: icon },
        { [styles.fieldCopy]: copy },
        { [styles.fieldCurrency]: currency },
        className
      )}
    >
      {label && (
        <div className={cn(classLabel, styles.label)}>
          {label}{" "}
          {tooltip && (
            <Tooltip
              className={styles.tooltip}
              title={tooltip}
              icon="info"
              place={place ? place : "right"}
            />
          )}
        </div>
      )}
      <div className={styles.wrap}>
        <input
          style={{
            paddingLeft: phoneNumInitials ? "45px" : "12px"
          }}
          defaultValue={value}
          id={id}
          onKeyUp={(e) => {
            // e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
            // let qty =  e.target.value
            document.getElementById(id).innerHTML = e.target.value
          }}
          className={cn(classInput, styles.input)} {...props}
        />
        {/* {phoneNumInitials ? <p style={{
          position: 'absolute',
          top: 15,
          left: 12,
          fontSize: '12px',
          fontWeight: '300'
        }}>+966</p> : <></>}
        {icon && (
          <div id={inputIconId} className={styles.icon}>
            <Icon name={icon} size="24" />{" "}
          </div>
        )}
        {warningIcon && (
          <div
            className="email-validation-icon"
            id={warningIconId}
          >
            <Icon fill="#FF6A55" name={warningIcon} size="20" />{" "}
          </div>
        )}
        {checkIcon && (
          <div
            className="email-validation-icon"
            id={validateSuccessIcon}
          >
            <Icon fill="#83BF6E" name={checkIcon} size="20" />{" "}
          </div>
        )}
        {copy && (
          <button className={styles.copy}>
            <Icon name="copy" size="24" />{" "}
          </button>
        )}
        {currency && <div className={styles.currency}>{currency}</div>} */}
      </div>
    </div>
  );
};

export default TextInputQuantity;

import React, { useState, useEffect, useContext } from "react";
import styles from "./Table.module.sass";
import Row from "./Row";
import { getSubCategories } from "../../../Firebase/firebaseDatabase";
import { GlobalState } from "../../../App";
import LoadingDialog from "../../../LoadingScreen/LoadingDialog";
import LoadingIndicator from "../../../LoadingScreen/LoadingIndicator";
import Icon from "../../../components/Icon";

const Table = () => {

  const [selectedFilters, setSelectedFilters] = useState([]);
  const [categoryId, setCategoryId] = useState()
  const [categoryTitle, setCategoryTitle] = useState()

  const handleChange = (id) => {
    if (selectedFilters.includes(id)) {
      setSelectedFilters(selectedFilters.filter((x) => x !== id));
    } else {
      setSelectedFilters((selectedFilters) => [...selectedFilters, id]);
    }
  };

  const { isClicked, setIsClicked,
    setIsActionComplete, isActionComplete } = useContext(GlobalState)

  const [subCategoryList, setSubCategoryList] = useState()
  const [loading, setLoading] = useState(true)

  const fetchSubCategories = async (categoryId) => {
    const list = await getSubCategories(categoryId)
    setSubCategoryList(list)
    setLoading(false)
  }

  const [isPrevActive, setIsPrevActive] = useState(false)
  const [isNextActive, setIsNextActive] = useState(true)
  const [activePageNum, setActivePageNum] = useState(1)
  const [pageCount, setPageCount] = useState(1)

  const page_items_count = 5;
  const [range, setRange] = useState({
    start: 0,
    end: page_items_count
  })

  const [itemsList, setItemsList] = useState([])

  const calculatePageCount = (active_products) => {
    const calculated_page_count = active_products?.length / page_items_count;
    const check_if_isInteger = Number.isInteger(calculated_page_count)
    if (check_if_isInteger) {
      setPageCount(calculated_page_count)
      if (calculated_page_count === 1) {
      }
    } else {
      setPageCount(Math.floor(calculated_page_count) + 1)
      // console.log(Math.floor(calculated_page_count) + 1)
      if (Math.floor(calculated_page_count) + 1 === 1) {
      }
    }
  }

  useEffect(() => {
    console.log(subCategoryList)
    if (subCategoryList?.length !== 0) {
      setItemsList(subCategoryList)
      calculatePageCount(subCategoryList);

    }
  }, [subCategoryList])

  useEffect(() => {
    setIsActionComplete(false)
    setIsClicked(false)
    setCategoryId(window.location.pathname?.split("/")[3])
    setCategoryTitle(window.location.pathname?.split("/")[2])
    const CategoryId = window.location.pathname?.split("/")[3]
    // console.log(CategoryId)
    fetchSubCategories(CategoryId)
  }, []);

  useEffect(() => {

  }, [isClicked, isActionComplete])

  const handleChangePrevClick = () => {

    if (activePageNum > 1) {
      setIsNextActive(true)
      const updatedPageNum = activePageNum - 1;
      setActivePageNum(updatedPageNum)

      if (updatedPageNum === 1) {
        setIsPrevActive(false)
      } else {
        setIsPrevActive(true)
      }

      setRange({
        start: updatedPageNum * page_items_count - page_items_count,
        end: updatedPageNum * page_items_count
      })
    } else {
      setIsPrevActive(false)
    }
  }

  const handleChangeNextClick = () => {

    if (activePageNum < pageCount) {
      setIsPrevActive(true)
      const updatedPageNum = activePageNum + 1;
      setActivePageNum(updatedPageNum);

      if (updatedPageNum === pageCount) {
        setIsNextActive(false)
      } else {
        setIsNextActive(true)
      }

      setRange({
        start: updatedPageNum * page_items_count - page_items_count,
        end: updatedPageNum * page_items_count
      })

    } else {
      setIsNextActive(false)
    }
  }


  return (
    <div className={styles.market}>
      {
        isClicked ?
          !isActionComplete ?
            <LoadingDialog />
            :
            window.location.reload()
          :
          <></>
      }

      {
        loading ?
          <LoadingIndicator />
          :
          <>
            <div className={styles.table}>
              {itemsList.sort((a, b) => {
                return (
                  new Date(b.data.dateCreated).getTime() - new Date(a.data.dateCreated).getTime()
                )
              })
                ?.map((x, index) => {
                  return (
                    (index >= range.start) && (index < range.end) ?
                      <Row
                        item={x?.data}
                        key={index}
                        up={subCategoryList.length - index <= 2}
                        value={selectedFilters.includes(x.id)}
                        onChange={() => handleChange(x.id)}
                        categoryId={categoryId}
                        categoryTitle={categoryTitle}
                      /> : <></>
                  )
                }
                )}
            </div>
            {
              pageCount !== 0 && pageCount !== 1 ?
                <div className={styles.foot}>
                  <button
                    style={{
                      opacity: isPrevActive ? 1 : 0.1
                    }}
                    onClick={() => {
                      handleChangePrevClick()
                    }} className={styles.arrow}>
                    <Icon name="arrow-left" size="20" />
                  </button>

                  <button
                    style={{
                      opacity: isNextActive ? 1 : 0.1
                    }}
                    onClick={() => {
                      handleChangeNextClick()
                    }} className={styles.arrow}>
                    <Icon name="arrow-right" size="20" />
                  </button>
                </div>
                : <></>
            }
          </>
      }


    </div>
  );
};

export default Table;

import React from 'react';
import NameAndValue from './NameAndValue';
import styles from './NewKeyword.module.sass';

const NewKeyword = () => {
  return (
    <div className={styles.row}>
        <div className={styles.col}>
          <NameAndValue className={styles.card} />
          </div></div>
  )
}

export default NewKeyword
import React, { useState, useContext, useEffect } from "react";
import cn from "classnames";
import styles from "./NameAndDescription.module.sass";
import Card from "../../../components/Card";
import TextInput from "../../../components/TextInput";
import Dropdown from "../../../components/Dropdown";
import {
  getCategories,
  getKeywords,
  getProductById,
  getSellers,
  getSubCategories,
  uploadFile,
  uploadProduct,
  uploadVariation,
} from "../../../Firebase/firebaseDatabase";
import {
  validate_if_not_empty,
  getLocalDateTimeNow,
  validate_if_not_empty_array_list,
} from "../../../utils/extensions";
import LoadingDialog from "../../../LoadingScreen/LoadingDialog";
import { GlobalState } from "../../../App";
import { VARIATION } from "../../../utils/constants";
import DropdownCategory from "../../../components/DropdownCategory";
import SearchDropdown from "../../../components/SearchDropdown";
import { collection, doc, setDoc } from "@firebase/firestore";
import { db } from "../../../Firebase/firebaseConfig";
import DropdownSubCategory from "../../../components/DropdownSubCategory";
import { randomNumGenerator } from "../../../utils/extensions";
import { useLocation } from "react-router-dom";
import DropdownKeywords from "../../../components/DropdownKeywords";
import LoadingIndicator from "../../../LoadingScreen/LoadingIndicator";
import TextInputKeywords from "../../../components/TextInputKeywords";
import { useStateWithCallbackLazy } from "use-state-with-callback";

const NameAndDescription = ({ className }) => {
  const location = useLocation();

  const {
    isClicked,
    setIsClicked,
    isActionComplete,
    setIsActionComplete,
    variationsList,
    setProductSku,
    formTypeProduct,
    setFormTypeProduct,
    newKeywordOptnsList,
    setNewKeywordOptnsList,
  } = useContext(GlobalState);

  const [isEditForm, setIsEditForm] = useState(false);

  const [optionsCategory, setOptionsCategory] = useState(["Select category"]);
  const [optionsSubCategory, setOptionsSubCategory] = useState([
    "Select Sub Category",
  ]);
  const [optionsSeller, setOptionsSeller] = useState(["Select seller"]);
  const [optionsKeywords, setOptionsKeywords] = useState(["Select a keyword"]);

  const optionsStatus = [
    "Select status",
    "On Sale",
    "Out Of Stock",
    "Archived",
  ];

  // form fields states
  const [sku, setSku] = useState();
  const [titleEn, setTitleEn] = useState();
  const [titleAr, setTitleAr] = useState();
  const [descEn, setDescEn] = useState();
  const [descAr, setDescAr] = useState();
  const [price, setPrice] = useState();
  const [category, setCategory] = useState(optionsCategory[0]);
  const [subCategory, setSubCategory] = useState(optionsSubCategory[0]);
  const [status, setStatus] = useState(optionsStatus[0]);
  const [keyword, setKeyword] = useStateWithCallbackLazy([optionsKeywords[0]]);
  const [seller, setSeller] = useState(optionsSeller[0]);
  const [dateCreated, setDateCreated] = useState();
  const [id, setId] = useState();
  // ---------------------fetching selllers data-------------------------------------
  const [allSellers, setAllSellers] = useState();

  const getAllSellers = async () => {
    await getSellers().then((list) => {
      // console.log("list of seller options", list)
      setAllSellers(list);
    });
  };

  useEffect(() => {
    if (allSellers?.length !== 0 && allSellers !== undefined) {
      allSellers?.filter((element) => {
        setOptionsSeller((oldData) => [...oldData, element?.data]);
      });
    }
  }, [allSellers]);

  // ---------------------end fetching selllers data-------------------------------------

  // -----------------fetching keywords list------------------------------------------
  const [allKeywords, setAllKeywords] = useStateWithCallbackLazy();

  const getAllKeywords = async () => {
    await getKeywords().then((list) => {
      list.forEach((globalKeyword) => {
        globalKeyword.selected = false;
        // setNewKeywordOptnsList((oldData) => [...oldData, globalKeyword])
      });
      setAllKeywords(list);
    });
  };

  useEffect(() => {
    if (allKeywords?.length !== 0 && allKeywords !== undefined) {
      console.log("setting keyWrd data.......................................");
      allKeywords?.filter((element) => {
        setOptionsKeywords((oldData) => [...oldData, element]);
      });
    }
  }, [allKeywords]);
  // -----------------end fetching keywords list------------------------------------------

  // -------------------fetch categories data and sub categories according to category value each time--------------------
  const [allCategories, setAllCategories] = useState();
  const [allSubCategories, setAllSubCategories] = useState();

  const getCategory = async () => {
    const list = await getCategories();
    setAllCategories(list);
  };

  useEffect(() => {
    if (allCategories?.length !== 0) {
      allCategories?.filter((data) =>
        setOptionsCategory((oldData) => [...oldData, data?.data])
      );
    }
  }, [allCategories]);

  useEffect(() => {
    if (allSubCategories?.length !== 0) {
      allSubCategories?.filter((data) =>
        setOptionsSubCategory((oldData) => [...oldData, data?.data])
      );
    }
  }, [allSubCategories]);

  const getSubCategory = async (categoryId) => {
    const list = await getSubCategories(categoryId);
    setAllSubCategories(list);
    // list.filter((data) =>
    //   setOptionsSubCategory((oldData) => [...oldData, data?.data])
    // );
  };

  useEffect(() => {
    if (validate_if_not_empty(category)) {
      if (allCategories?.length !== 0) {
        allCategories?.filter((data) => {
          if (data?.data?.id === category[0]?.id) {
            setOptionsSubCategory(["Select Sub Category"]);
            getSubCategory(category[0]?.id);
          }
        });
      }
    }
  }, [category]);
  // -------------------end fetch categories data and sub categories according to category value each time--------------------

  useEffect(() => { }, [isClicked, isActionComplete]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setIsActionComplete(false);
    getCategory();
    getAllSellers();
    getAllKeywords();
  }, []);

  const [randomNum, setRandomNum] = useState();

  const getProductSku = async () => {
    const randomNums = randomNumGenerator(6);
    setRandomNum(randomNums.toString());
    setSku(randomNums.toString());
    setProductSku(randomNums.toString());
  };

  // -----------update product sku, each time the seller state gets updated-----------------------
  useEffect(() => {
    if (seller !== "Select seller") {
      setSku(seller[0].id + "-" + randomNum);
      setProductSku(seller[0].id + "-" + randomNum);
    }
  }, [seller]);
  // -----------end update product sku, each time the seller state gets updated-----------------------

  // const [pageType, setPageType] = useState()
  useEffect(() => {
    const pageType = location.pathname.split("/")[2];
    setFormTypeProduct(pageType);
    if (pageType === "edit" || pageType === "details") {
      setLoading(true);
      setIsEditForm(true);
      const productId = location.pathname.split("/")[3];
      if (pageType === "edit" || pageType === "details") {
        getProductById(productId).then((data) => {
          console.log("product details in edit form:", data);
          const productData = data[0]?.data;
          setSku(productData?.sku);
          setTitleEn(productData?.titleEn);
          setTitleAr(productData?.titleAr);
          setDescEn(productData?.descriptionEn);
          setDescAr(productData?.descriptionAr);
          setPrice(productData?.price);
          setDateCreated(productData?.dateCreated);
          setId(productData?.id);
          setRandomNum(productData?.sku?.split("-")[1]);
          allCategories?.forEach((element) => {
            if (element?.data.id === productData?.category) {
              setCategory([
                { id: element?.data.id, title: element?.data.titleEn },
              ]);
            }
          });

          getSubCategories(productData?.category).then((list) => {
            list.forEach((element) => {
              if (element?.data?.id === productData?.subCategory) {
                setSubCategory([
                  { id: element?.data?.id, title: element?.data?.titleEn },
                ]);
              }
            });
          });
          setStatus(productData?.status);
          setKeyword(
            productData?.keyword.length === 0 || productData?.keyword[0] === ""
              ? ["Select a keyword"]
              : productData?.keyword,
            (keyword) => {
              getKeywords().then((myList) => {
                setNewKeywordOptnsList(["Select a keyword"]);
                keyword.forEach((localKeyword, index) => {
                  if (
                    myList.filter(function (e) {
                      return e.id === localKeyword.id;
                    }).length > 0
                  ) {
                    myList[
                      myList.map((e) => e.id).indexOf(localKeyword.id)
                    ].selected = true;
                  }
                  if (index === keyword.length - 1) {
                    myList.forEach((globalKeyword, index) => {
                      setNewKeywordOptnsList((oldData) => [
                        ...oldData,
                        globalKeyword,
                      ]);
                    });
                  }
                });
              });
            }
          );

          allSellers?.forEach((element) => {
            if (element?.data.id === productData?.sellerId) {
              setSeller([
                { id: element?.data.id, title: element?.data.nameEn },
              ]);
            }
            setLoading(false);
          });
        });
      }
      // setLoading(false)
    }
  }, [allSellers]);

  const productNameDescForm = {
    id: id ?? "",
    sku: sku ?? getProductSku(),
    qoyodId: "",
    titleEn: titleEn ?? "",
    titleAr: titleAr ?? "",
    descriptionEn: descEn ?? "",
    descriptionAr: descAr ?? "",
    price: price ?? "",
    category: category ?? "",
    subCategory: subCategory ?? "",
    keyword: keyword[0] === "Select a keyword" ? keyword[0] : keyword ?? "",
    status: status ?? "",
    dateCreated: dateCreated ?? "",
    dateEdited: "",
    sellerId: seller ?? "",
  };

  const validateFields = () => {
    return new Promise((resolve, reject) => {
      if (
        validate_if_not_empty(titleEn, "productTitleEnErrId") &&
        validate_if_not_empty(titleAr, "productTitleArErrId") &&
        validate_if_not_empty(descEn, "productDescEnErrId") &&
        validate_if_not_empty(descAr, "productDescArErrId") &&
        validate_if_not_empty(price, "productPriceErrId") &&
        validate_if_not_empty(category, "productCategoryErrId") &&
        validate_if_not_empty(subCategory, "productSubCategoryErrId") &&
        validate_if_not_empty(status, "productStatusErrId") &&
        validate_if_not_empty(seller, "productSellerErrId")
      ) {
        resolve(true);
      } else {
        resolve(false);
        setIsClicked(false);
      }
    });
  };

  const validateVariations = () => {
    return new Promise(async (resolve, reject) => {
      if (
        await validate_if_not_empty_array_list(variationsList, "allVariations")
      ) {
        resolve(true);
      } else {
        resolve(false);
        setIsClicked(false);
      }
    });
  };

  const updateProductData = async () => {
    // console.log("product form: ", productNameDescForm)
    productNameDescForm.dateEdited = new Date().toISOString();
    const docRef = doc(db, "product", productNameDescForm.id);
    setDoc(docRef, productNameDescForm, {
      merge: true,
    })
      .then(async () => {
        // calculate total images
        var totalImages = 0;
        await variationsList.map((variationData) => {
          totalImages = variationData["images"].length;
          console.log("var imgs:", variationData["images"]);
        });
        // var uploadedImages = 0;
        await variationsList.map(async (variationData, index) => {
          const varDocRef = doc(
            db,
            "product",
            productNameDescForm.id,
            VARIATION,
            variationsList[index].id
          );
          if (variationData.images.length !== 0) {
            var arrayList = [];
            var totalUpImg = variationData.images.length;
            await variationData.images.forEach(async (elem, i) => {
              if (elem?.name === undefined) {
                arrayList.push(elem);
                variationsList[index].images = arrayList;
              } else {
                await uploadFile(elem, "product").then(async (url) => {
                  arrayList.push(url);
                  variationsList[index].images = arrayList;
                });
              }

              if (arrayList.length === totalUpImg) {
                //// console.log("seting variations...........")
                // const docRef = doc(db, "product", productNameDescForm.id);
                if (
                  variationData?.dateCreated === "" ||
                  variationData?.dateCreated === undefined
                ) {
                  variationData.dateCreated = new Date().toISOString();
                } else {
                  variationData.dateEdited = new Date().toISOString();
                }
                setDoc(varDocRef, variationData, {
                  merge: true,
                })
                  .then(async () => {
                    // console.log("set variations successfully............!", variationsList.length, index);

                    if (variationsList.length - 1 === index) {
                      // console.log('completed setting var................................')
                      // window.location.replace("/products/dashboard");
                      setIsActionComplete(true);
                      setIsClicked(false);
                    }
                  })
                  .catch((e) => {
                    console.log("failed upload variations", e);
                  });
              }
            });
          } else {
            if (
              variationData?.dateCreated === "" ||
              variationData?.dateCreated === undefined
            ) {
              variationData.dateCreated = new Date().toISOString();
            } else {
              variationData.dateEdited = new Date().toISOString();
            }
            setDoc(varDocRef, variationData, {
              merge: true,
            })
              .then(async () => {
                // console.log("set variations successfully............!");
                if (variationsList.length - 1 === index) {
                  // window.location.replace("/products/dashboard");
                  setIsActionComplete(true);
                  setIsClicked(false);
                }
              })
              .catch((e) => {
                console.log("failed upload variations", e);
              });
          }
        });

        setTimeout(() => {
          if (totalImages === 0) {
            setIsActionComplete(true);
            setIsClicked(false);
            // window.location.replace("/products/dashboard");
            // call redirect function here
          }
        }, 2000);
      })
      .catch((err) => { });
  };

  async function uploadProduct() {
    try {
      var totalImages = 0;
      var totalUploadedArray = []
      const productCollectionRef = doc(collection(db, "product"));
      productNameDescForm.id = productCollectionRef.id;
      productNameDescForm.dateCreated = getLocalDateTimeNow();
      const docRef = doc(db, "product", productNameDescForm.id);
      await variationsList.forEach((variationDataTotalImg, index, array) => {
        totalImages += variationDataTotalImg["images"].length;
      });
      await setDoc(docRef, productNameDescForm)
        .then(async () => {
          // loop through variations list
          await variationsList.map(async (variationData, index) => {
            const varDocRef = doc(
              db,
              "product",
              productNameDescForm.id,
              VARIATION,
              variationsList[index].id
            );
            if (variationData.images.length !== 0) {
              var arrayList = [];
              var totalUpImg = variationData.images.length;
              await variationData.images.forEach(async (elem, i, array) => {
                await uploadFile(elem, "product")
                  .then(async (url) => {
                    arrayList.push(url);
                    totalUploadedArray.push(url)
                    variationsList[index].images = arrayList;
                  })
                  .then(async () => {
                    // console.log(`TOTAL UPLOADED AND TOTAL IMAGE ${totalUploadedArray.length} ${totalImages}`)
                    if (totalUploadedArray.length === totalImages) {
                      let list = variationsList
                      await list.map(async (varLocData, ind) => {
                        const varDocRef2 = doc(
                          db,
                          "product",
                          productNameDescForm.id,
                          VARIATION,
                          list[ind].id
                        )
                        varLocData.dateCreated = getLocalDateTimeNow();
                        await setDoc(varDocRef2, varLocData)
                          .then(async () => {
                            console.log("set variations successfully............!");
                            if (list.length - 1 === ind) {
                              setTimeout(() => {
                                setIsActionComplete(true);
                                setIsClicked(false);
                              }, 5000);
                            }
                          })
                          .catch((e) => {
                            console.log("failed upload variations: " + e);
                          });
                      })
                    }

                  });
              });
            } else {
              variationData.dateCreated = getLocalDateTimeNow();
              await setDoc(varDocRef, variationData)
                .then(async () => {
                  if (variationsList.length - 1 === index) {
                    setIsActionComplete(true);
                    setIsClicked(false);
                  }
                })
                .catch((e) => {
                  console.log("failed upload variations", e);
                });
            }

            setTimeout(() => {
              if (totalImages === 0) {
                setIsActionComplete(true);
                setIsClicked(false);
              }
            }, 2000);
          });

          console.log(productNameDescForm.id);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  }


  return (
    <Card
      className={cn(styles.card, className)}
      title={formTypeProduct === "details" ? "" : "Name & description"}
      classTitle={formTypeProduct === "details" ? "" : "title-green"}
      head={
        <div
          style={{
            display: "flex",
            gap: "12px",
          }}
        >
          <button
            onClick={async () => {
              setIsClicked(true);
              const isNotEmptyFields = await validateFields();
              if (isNotEmptyFields) {
                console.log(productNameDescForm);
                var category = productNameDescForm.category[0]?.id;
                var subcategory = productNameDescForm.subCategory[0]?.id;
                var seller = productNameDescForm.sellerId[0]?.id;
                productNameDescForm.category =
                  category === null || category === undefined ? "" : category;
                productNameDescForm.subCategory =
                  subcategory === null || subcategory === undefined
                    ? ""
                    : subcategory;
                productNameDescForm.sellerId =
                  seller === null || seller === undefined ? "" : seller;
                if (productNameDescForm.keyword === "Select a keyword") {
                  productNameDescForm.keyword = [];
                }

                await variationsList.map((k, index) => {
                  let qty = document.getElementById(k.id + "$QTY");
                  variationsList[index]["quantity"] = qty.value;
                  return <></>;
                });

                const isNotEmptyVariations = await validateVariations();
                if (isNotEmptyVariations) {
                  // uploadProductNow();
                  // console.log("is valid all variations......................")
                  await variationsList.map((k, index) => {
                    for (
                      let i = 0;
                      i < variationsList[index].attributes.length;
                      i++
                    ) {
                      const label =
                        variationsList[index].attributes[i]?.data?.label;
                      // console.log(variationsList[index].sku)
                      // update variation sku only while creating , as sku id is alredy updated while edit form
                      isEditForm
                        ? i === 0
                          ? (variationsList[index].sku =
                            variationsList[index].sku.split("-")[0] +
                            "-" +
                            variationsList[index].sku.split("-")[1] +
                            "-" +
                            label.toUpperCase())
                          : (variationsList[index].sku =
                            variationsList[index].sku +
                            "-" +
                            label.toUpperCase())
                        : (variationsList[index].sku =
                          variationsList[index].sku +
                          "-" +
                          label.toUpperCase());
                      variationsList[index].attributes[i] =
                        variationsList[index].attributes[i]?.id;
                    }
                    return <></>;
                  });

                  isEditForm ? updateProductData() : uploadProduct()
                }
                // call publish function here...
              }
            }}
            className={cn("button", styles.button)}
          >
            <span> {isEditForm ? "Update" : "Publish"}</span>
          </button>
        </div>
      }
    >
      {isClicked ? (
        isActionComplete ? (
          window.location.replace("/products/dashboard")
        ) : (
          <LoadingDialog />
        )
      ) : (
        <></>
      )}
      {loading ? (
        <LoadingIndicator />
      ) : (
        <div className={styles.description}>
          {isEditForm ? (
            <div className={styles.fieldContainer}>
              <TextInput
                className={styles.field}
                classInput={styles.fieldInput}
                label="SKU"
                name="sku"
                id="sellerproductsku"
                type="text"
                // onChange={(e) => {
                //   setSku(e.target.value);
                // }}
                value={sku}
                readOnly={true}
              />
              <p className={styles.errField} id="productSkuErrId"></p>
            </div>
          ) : (
            <></>
          )}

          <div className={styles.fieldContainer}>
            <TextInput
              className={styles.field}
              classInput={styles.fieldInput}
              label="Title (English)"
              name="titleEn"
              type="text"
              onChange={(e) => {
                setTitleEn(e.target.value);
              }}
              value={titleEn}
              required
              readOnly={formTypeProduct === "details" ? true : false}
            />
            <p className={styles.errField} id="productTitleEnErrId"></p>
          </div>

          <div className={styles.fieldContainer}>
            <TextInput
              className={styles.field}
              classInput={styles.fieldInput}
              label="Title (Arabic)"
              name="titleAr"
              type="text"
              onChange={(e) => {
                setTitleAr(e.target.value);
              }}
              value={titleAr}
              required
              readOnly={formTypeProduct === "details" ? true : false}
            />
            <p className={styles.errField} id="productTitleArErrId"></p>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
            className={styles.fieldContainer}
          >
            <label className={styles.label}>Description (English)</label>
            <textarea
              className={styles.descriptionField}
              onChange={(e) => {
                setDescEn(e.target.value);
              }}
              value={descEn}
              readOnly={formTypeProduct === "details" ? true : false}
            />
            <p className={styles.errField} id="productDescEnErrId"></p>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
            className={styles.fieldContainer}
          >
            <label className={styles.label}>Description (Arabic)</label>
            <textarea
              className={styles.descriptionField}
              onChange={(e) => {
                setDescAr(e.target.value);
              }}
              value={descAr}
              readOnly={formTypeProduct === "details" ? true : false}
            />
            <p className={styles.errField} id="productDescArErrId"></p>
          </div>

          <div className={styles.fieldContainer}>
            <TextInput
              className={styles.field}
              classInput={styles.fieldInput}
              label="Price"
              name="price"
              // type="number"
              onChange={(e) => {
                e.target.value = e.target.value
                  .replace(/[^0-9.]/g, "")
                  .replace(/(\..*?)\..*/g, "$1");
                setPrice(e.target.value);
              }}
              value={price}
              readOnly={formTypeProduct === "details" ? true : false}
              required
            />
            <p className={styles.errField} id="productPriceErrId"></p>
          </div>

          <div className={styles.fieldset}>
            {formTypeProduct === "details" ? (
              <div
                className={`${styles.fieldContainer} ${styles.textInputSmallFields}`}
              >
                <TextInput
                  className={styles.field}
                  classInput={styles.fieldInput}
                  label="Category"
                  name="category"
                  value={category[0]?.title}
                  readOnly={formTypeProduct === "details" ? true : false}
                  required
                />
              </div>
            ) : (
              <div
                className={`${styles.fieldContainer}  ${styles.smallFields}`}
              >
                <DropdownCategory
                  className={styles.smallDropdownField}
                  classDropdownLabel={styles.smallDropdownlabel}
                  label="Category"
                  value={category}
                  setValue={setCategory}
                  options={optionsCategory}
                  readOnly={formTypeProduct === "details" ? true : false}
                />
                <p className={styles.errField} id="productCategoryErrId"></p>
              </div>
            )}

            {validate_if_not_empty(category) ? (
              formTypeProduct === "details" ? (
                <div
                  className={`${styles.fieldContainer} ${styles.textInputSmallFields}`}
                >
                  <TextInput
                    className={styles.field}
                    classInput={styles.fieldInput}
                    label="Sub Category"
                    name="subCategory"
                    value={subCategory[0]?.title}
                    readOnly={formTypeProduct === "details" ? true : false}
                    required
                  />
                </div>
              ) : (
                <div
                  className={`${styles.fieldContainer}  ${styles.smallFields}`}
                >
                  <DropdownSubCategory
                    className={styles.smallDropdownField}
                    classDropdownLabel={styles.smallDropdownlabel}
                    label="Sub Category"
                    value={subCategory}
                    setValue={setSubCategory}
                    options={optionsSubCategory}
                    readOnly={formTypeProduct === "details" ? true : false}
                  />
                  <p
                    className={styles.errField}
                    id="productSubCategoryErrId"
                  ></p>
                </div>
              )
            ) : (
              <></>
            )}
          </div>

          {formTypeProduct === "details" ? (
            <div className={styles.fieldContainer}>
              <TextInputKeywords
                className={styles.field}
                classInput={styles.fieldInput}
                label="Keywords (optional)"
                name="keywords"
                value={keyword}
                readOnly={formTypeProduct === "details" ? true : false}
                required
              />
            </div>
          ) : (
            <div className={styles.fieldContainer}>
              <DropdownKeywords
                className={styles.field}
                label="Keywords (optional)"
                value={keyword}
                setValue={setKeyword}
                options={optionsKeywords}
                readOnly={formTypeProduct === "details" ? true : false}
                isEditForm={isEditForm}
              />
              <p className={styles.errField} id="productKeywordErrId"></p>
            </div>
          )}

          {formTypeProduct === "details" ? (
            <div className={styles.fieldContainer}>
              <TextInput
                className={styles.field}
                classInput={styles.fieldInput}
                label="Status"
                name="status"
                value={status}
                readOnly={formTypeProduct === "details" ? true : false}
                required
              />
            </div>
          ) : (
            <div className={styles.fieldContainer}>
              <Dropdown
                className={styles.field}
                label="Status"
                value={status}
                setValue={setStatus}
                options={optionsStatus}
                readOnly={formTypeProduct === "details" ? true : false}
              />
              <p className={styles.errField} id="productStatusErrId"></p>
            </div>
          )}

          {formTypeProduct === "details" ? (
            <div className={styles.fieldContainer}>
              <TextInput
                className={styles.field}
                classInput={styles.fieldInput}
                label="Seller"
                name="seller"
                value={seller[0]?.title}
                readOnly={formTypeProduct === "details" ? true : false}
                required
              />
            </div>
          ) : (
            <div className={styles.fieldContainer}>
              <SearchDropdown
                className={styles.field}
                label="Seller"
                value={seller}
                setValue={setSeller}
                options={optionsSeller}
                readOnly={formTypeProduct === "details" ? true : false}

              // onClick={() => { handleChangeSellerDropdown() }}
              />
              <p className={styles.errField} id="productSellerErrId"></p>
            </div>
          )}
        </div>
      )}
    </Card>
  );
};

export default NameAndDescription;

import {
    collection,
    doc,
    // query, where, updateDoc, deleteDoc
} from '@firebase/firestore';
import { db } from '../Firebase/firebaseConfig';


function validate_email(email, fieldId, warningIconId, inputIconId, validateSuccessIcon) {
    const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
    //// console.log("email: ", email)
    //// console.log("testing..", regex.test(email))
    const field = document.getElementById(fieldId);

    const fieldWarning = document.getElementById(warningIconId);
    const fieldIcon = document.getElementById(inputIconId);
    const fieldInputValid = document.getElementById(validateSuccessIcon);

    if (!regex.test(email)) {

        if (email === "") {
            field.style.border = ""
            field.style.background = ""
            field.style.color = ""
            fieldWarning.style.visibility = "hidden"
            fieldIcon.style.visibility = "visible"
            fieldInputValid.style.visibility = "hidden"
            return 0
        }
        field.style.border = "2px solid #FF6A55";
        field.style.background = "rgba(255, 188, 153, 0.1)"
        field.style.color = "#FF6A55"
        fieldWarning.style.visibility = "visible"
        fieldIcon.style.visibility = "hidden"
        fieldInputValid.style.visibility = "hidden"
    } else {
        field.style.border = ""
        field.style.background = ""
        field.style.color = ""
        fieldWarning.style.visibility = "hidden"
        fieldIcon.style.visibility = "visible"
        fieldInputValid.style.visibility = "visible"
    }
}

function form_email_validation(value, err_field_id) {
    // console.log("email val", value, "err id", err_field_id)
    const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
    if (regex.test(value)) {
        // console.log(" valid email")
        document.getElementById(`${err_field_id}`).innerHTML = ""
        return true
    } else {
        document.getElementById(`${err_field_id}`).innerHTML = "Invalid email id!"
        return false
    }
}

function validate_if_not_empty(value, err_field_id) {

    if (value === "Select category" || value === "Select type" ||
        value === "Select status" || value === "Select assignee" ||
        value === "Select shipping method" || value === "Select condition" ||
        value === "Select location" || value === "Select a keyword" || value === "Select seller" ||
        value === "Select country" ||
        value === "" || value === undefined || value === null) {

        err_field_id !== undefined ?
            scrollToView(err_field_id)
            : <></>
        return false
    } else {
        err_field_id !== undefined ?
            document.getElementById(`${err_field_id}`).innerHTML = "" : <></>

        return true
    }
}

function scrollToView(err_field_id) {
    document.getElementById(`${err_field_id}`).innerHTML = "This field is required!"
    document.getElementById(`${err_field_id}`).scrollIntoView({
        behavior: 'smooth',
        block: 'center'
    })
}

function scrollToViewVariation(err_field_id) {
    document.getElementById(`${err_field_id}`).innerHTML = "Required atleast one variant of the product!"

    setTimeout(() => {
        document.getElementById(`${err_field_id}`).scrollIntoView({
            behavior: 'smooth',
            block: 'center'
        })
    }, 100);

}

function validate_if_not_empty_array_list(value, err_field_id) {

    // console.log("value: ", value, "id: ", err_field_id, document.getElementById(`${err_field_id}`))
    return new Promise(async (resolve, reject) => {

        if (value.length === 0) {

            err_field_id !== undefined ?
                scrollToViewVariation(err_field_id)
                // document.getElementById(`${err_field_id}`).innerHTML = "Required atleast one variant of the product!" 
                : <></>
            // document.getElementById(`${err_field_id}`).scrollIntoView()
            setTimeout(() => {
                document.getElementById(`${err_field_id}`).innerHTML = ""
            }, 2000);

            resolve(false)
        } else {
            await value.forEach((element, index) => {
                // console.log(element)

                if (element?.quantity === "") {
                    document.getElementById(`variationErrId${element?.id}`).innerHTML = "Quantity field is required!"
                    setTimeout(() => {
                        document.getElementById(`variationErrId${element?.id}`).scrollIntoView({
                            behavior: 'smooth',
                            block: 'center'
                        })
                    }, 100);
                    setTimeout(() => {
                        document.getElementById(`variationErrId${element?.id}`).innerHTML = ""
                    }, 5000);
                    resolve(false)
                }
                else {
                    if (element?.status === "Select status") {
                        document.getElementById(`variationErrId${element?.id}`).innerHTML = "Status field is required!"
                        setTimeout(() => {
                            document.getElementById(`variationErrId${element?.id}`).scrollIntoView({
                                behavior: 'smooth',
                                block: 'center'
                            })
                        }, 100);
                        setTimeout(() => {
                            document.getElementById(`variationErrId${element?.id}`).innerHTML = ""
                        }, 5000);
                        resolve(false)
                    } else {

                        if (element?.attributes?.length < 2) {
                            document.getElementById(`variationErrId${element?.id}`).innerHTML = "Required both color and size attribute!"
                            setTimeout(() => {
                                document.getElementById(`variationErrId${element?.id}`).scrollIntoView({
                                    behavior: 'smooth',
                                    block: 'center'
                                })
                            }, 100);

                            setTimeout(() => {
                                document.getElementById(`variationErrId${element?.id}`).innerHTML = ""
                            }, 5000);
                            resolve(false)
                        }
                        else {
                            if (element?.images?.length === 0) {
                                document.getElementById(`variationErrId${element?.id}`).innerHTML = "Required at least one image of a variant!"
                                setTimeout(() => {
                                    document.getElementById(`variationErrId${element?.id}`).scrollIntoView({
                                        behavior: 'smooth',
                                        block: 'center'
                                    })
                                }, 100);

                                setTimeout(() => {
                                    document.getElementById(`variationErrId${element?.id}`).innerHTML = ""
                                }, 5000);
                                resolve(false)
                            } else {
                                if (index === value.length - 1) {
                                    resolve(true)
                                }
                            }
                        }

                    }

                }

            });

            // return true

        }
    })

}

function validate_if_is_number(value) {
    if (typeof (value) === 'number') {
        return false
    } else {
        return false
    }
}

const addVerticalSpace = (height) => <div style={{
    height: height,
    width: 'auto'
}}></div>


const getVariationDocId = () => {
    const variationCollectionRef = doc(collection(db, "variation"));
    return variationCollectionRef.id
}


const getLocalDateTimeNow = () => new Date().toISOString()

const getLocationDocId = () => {
    const collectionRef = doc(collection(db, "location"));
    return collectionRef.id
}

const getKeywordDocId = () => {
    const collectionRef = doc(collection(db, "keyword"));
    return collectionRef.id
}


const getShippingMethodDocId = () => {
    const collectionRef = doc(collection(db, "shippingMethod"));
    return collectionRef.id
}

const randomStringGenerator = (length) => {
    const charSet =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let randomString = '';
    for (let i = 0; i < length; i++) {
        let randomPoz = Math.floor(Math.random() * charSet.length);
        randomString += charSet.substring(randomPoz, randomPoz + 1);
    };
    return randomString;
}

const randomNumGenerator = (length) => {
    return Math.floor(Math.pow(10, length - 1) + Math.random() * (Math.pow(10, length) - Math.pow(10, length - 1) - 1));
};

const sellerSkuGenerator = async () => {
    const charSet = randomStringGenerator(3);
    const numSet = randomNumGenerator(3);
    return (charSet.toUpperCase() + numSet).toString()
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function distinct(value, index, self) {
    return self.indexOf(value) === index;
}

export {
    validate_email, validate_if_not_empty, addVerticalSpace, getVariationDocId,
    getLocationDocId, getShippingMethodDocId, getLocalDateTimeNow, randomStringGenerator, randomNumGenerator,
    sellerSkuGenerator, form_email_validation, validate_if_is_number, getKeywordDocId,
    capitalizeFirstLetter, validate_if_not_empty_array_list, distinct
}

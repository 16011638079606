import React, { useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import Control from '../../Control';

const Row = ({ item, value, onChange, up }) => {
  const [visibleActions, setVisibleActions] = useState(false);

  return (
    <>
      <div className={styles.row}
        onMouseLeave={() => setVisibleActions(false)}
      >

        <div className={styles.col}>
          <div className={styles.label}>Label</div>
          <div className={styles.box}>
            {item.label}
          </div>
        </div>

        <div className={styles.col}>
          <div className={styles.label}>Value</div>
          <div className={styles.box}>
            {item.value}
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.label}>Status</div>
          <div className={styles.box}>
            {item.status === "Active" ? (
              <div className={cn("status-green", styles.status)}>{item.status}</div>
            ) : (
              <div className={cn("status-red", styles.status)}>{item.status}</div>
            )}
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.label}>Type</div>
          <div className={styles.box}>
            {item.type}
          </div>
        </div>

        <div className={styles.col}>

          <Control
            data={item}
            className={styles.control}
            visibleActions={visibleActions}
            setVisibleActions={setVisibleActions}
            up={up}
          />

        </div>

      </div>

    </>
  );
};

export default Row;

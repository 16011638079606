import React, { useState } from "react";
import cn from "classnames";
import styles from "./KeywordsList.module.sass";
import Card from "../../components/Card";
import Market from "./Market";


const KeywordsList = () => {

  return (
    <>
      <Card
        className={styles.card}
        classCardHead={styles.head}
        title="Keywords"
        classTitle={cn("title-green", styles.title)}
      >
        <div className={styles.wrapper}>
          <Market />
        </div>
      </Card>

    </>
  );
};

export default KeywordsList;

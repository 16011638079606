import React, { useState, useEffect, useContext } from "react";
import styles from "./Market.module.sass";
import Row from "./Row";
import { GlobalState } from "../../../App";
import LoadingDialog from "../../../LoadingScreen/LoadingDialog";
import { getKeywords } from "../../../Firebase/firebaseDatabase";
import LoadingIndicator from "../../../LoadingScreen/LoadingIndicator";
import Icon from "../../../components/Icon";

const Market = () => {

  const [selectedFilters, setSelectedFilters] = useState([]);
  const { isActionComplete, setIsActionComplete,
    isClicked } = useContext(GlobalState)

  const handleChange = (id) => {
    if (selectedFilters.includes(id)) {
      setSelectedFilters(selectedFilters.filter((x) => x !== id));
    } else {
      setSelectedFilters((selectedFilters) => [...selectedFilters, id]);
    }
  };

  const [loading, setLoading] = useState(true)
  const [allKeywords, setAllKeywords] = useState()

  const fetchKeywords = async () => {
    await getKeywords().then(list => {
      setAllKeywords(list)
      setLoading(false)
    })
  }

  const [isPrevActive, setIsPrevActive] = useState(false)
  const [isNextActive, setIsNextActive] = useState(true)
  const [activePageNum, setActivePageNum] = useState(1)
  const [pageCount, setPageCount] = useState(1)

  const page_items_count = 20;
  const [range, setRange] = useState({
    start: 0,
    end: page_items_count
  })

  const [itemsList, setItemsList] = useState([])

  const calculatePageCount = (active_products) => {
    const calculated_page_count = active_products.length / page_items_count;
    const check_if_isInteger = Number.isInteger(calculated_page_count)
    if (check_if_isInteger) {
      setPageCount(calculated_page_count)
      if (calculated_page_count === 1) {
      }
    } else {
      setPageCount(Math.floor(calculated_page_count) + 1)
      // console.log(Math.floor(calculated_page_count) + 1)
      if (Math.floor(calculated_page_count) + 1 === 1) {
      }
    }
  }

  useEffect(() => {
    console.log("keywords:", allKeywords)
    if (allKeywords !== undefined) {
      setItemsList(allKeywords)
      calculatePageCount(allKeywords);

      setLoading(false)
    }
  }, [allKeywords]);

  const handleChangePrevClick = () => {

    if (activePageNum > 1) {
      setIsNextActive(true)
      const updatedPageNum = activePageNum - 1;
      setActivePageNum(updatedPageNum)

      if (updatedPageNum === 1) {
        setIsPrevActive(false)
      } else {
        setIsPrevActive(true)
      }

      setRange({
        start: updatedPageNum * page_items_count - page_items_count,
        end: updatedPageNum * page_items_count
      })
    } else {
      setIsPrevActive(false)
    }
  }

  const handleChangeNextClick = () => {

    if (activePageNum < pageCount) {
      setIsPrevActive(true)
      const updatedPageNum = activePageNum + 1;
      setActivePageNum(updatedPageNum);

      if (updatedPageNum === pageCount) {
        setIsNextActive(false)
      } else {
        setIsNextActive(true)
      }

      setRange({
        start: updatedPageNum * page_items_count - page_items_count,
        end: updatedPageNum * page_items_count
      })

    } else {
      setIsNextActive(false)
    }
  }

  useEffect(() => {
    setIsActionComplete(false)
    fetchKeywords()
  }, []);


  return (
    <div className={styles.market}>

      {
        isClicked ?
          isActionComplete ?
            window.location.reload()
            :
            <LoadingDialog />
          :
          <></>
      }
      {
        loading !== true ?
          <>
            <div className={styles.table}>
              {itemsList?.sort((a, b) => {
                // console.log(a)
                return (
                  new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime()
                )
              })
                ?.map((x, index) => {
                  console.log("while maping:", x)
                  return (
                    (index >= range.start) && (index < range.end) ?
                      < Row
                        item={x}
                        key={index}
                        value={selectedFilters.includes(x.id)}
                        onChange={() => handleChange(x.id)}
                        up={allKeywords.length - index <= 2}
                      /> : <></>
                  )
                }
                )}
            </div>

            {
              pageCount !== 0 && pageCount !== 1 ?
                <div className={styles.foot}>
                  <button
                    style={{
                      opacity: isPrevActive ? 1 : 0.1
                    }}
                    onClick={() => {
                      handleChangePrevClick()
                    }} className={styles.arrow}>
                    <Icon name="arrow-left" size="20" />
                  </button>

                  <button
                    style={{
                      opacity: isNextActive ? 1 : 0.1
                    }}
                    onClick={() => {
                      handleChangeNextClick()
                    }} className={styles.arrow}>
                    <Icon name="arrow-right" size="20" />
                  </button>
                </div>
                : <></>
            }
          </>
          :
          <LoadingIndicator />
      }


    </div>
  );
};

export default Market;

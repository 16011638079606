import { getAuth, signInWithEmailAndPassword } from "firebase/auth";


function userSignIn({email, password, setIsLogin, errId}) {
    const auth = getAuth();
    // auth.currentUser

    signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
           // console.log("user: ", user)
            setIsLogin(true)
            // ...
        })
        .catch((error) => {
            document.getElementById(errId).innerHTML = "Invalid Email or Password!"
        });
      
}


export { userSignIn }
import React, { useContext } from "react";
import styles from "./Control.module.sass";
import cn from "classnames";
import Icon from "../../../components/Icon";
import { Link } from "react-router-dom";
import Actions from "../../../components/Actions";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { GlobalState } from "../../../App";
import { deleteKeyword } from "../../../Firebase/firebaseDatabase";

const Control = ({ className, visibleActions, setVisibleActions, up, data }) => {

  const theme = useTheme();
  const matches1022 = useMediaQuery(theme.breakpoints.down(1023));
  const { setIsActionComplete,
    setIsClicked } = useContext(GlobalState)
  const actions = [
    {
      title: "Edit attribute",
      icon: "edit",
      action: () => { window.location.href = `/products/keywords/edit/${data?.id}` },
    },
    {
      title: "Delete forever",
      icon: "trash",
      action: async () => {
        setIsClicked(true)
        await deleteKeyword(data?.id).then(res => {
          if (res === true) {
            setIsActionComplete(true)
            setIsClicked(false)
          }
        })
      },
    },
  ];

  const delete_actions = [
    {
      title: "Yes",
      icon: "check",
      action: async () => {
        setIsClicked(true)
        await deleteKeyword(data?.id).then(res => {
          if (res === true) {
            setIsActionComplete(true)
            setIsClicked(false)
          }
        })
      }
    },
    {
      title: "No",
      icon: "close",
      action: () => {
      },
    }
  ]

  return (
    <div className={cn(styles.control, className)}>
      <Link to={{
        pathname: `/products/keywords/edit/${data?.id}`,
        state: { data: data }
      }} style={{
        width: '35px',
        marginRight: "20px"
      }}>
        <button className={styles.button}>
          <Icon name="edit" size="20" />
        </button>
      </Link>
      {matches1022 ?
        <Actions
          className={styles.actions}
          classActionsHead={styles.actionsHead}
          classActionsBody={styles.actionsBody}
          classActionsOption={styles.actionsOption}
          items={actions}
          data={data}
          visible={visibleActions}
          setVisible={setVisibleActions}
          up={up}

        />
        :
        <Actions
          className={styles.actions}
          classActionsHead={styles.actionsHead}
          classActionsBody={styles.actionsBody}
          // classActionsOption={styles.actionsOption}
          icon="trash"
          items={delete_actions}
          data={data}
          visible={visibleActions}
          setVisible={setVisibleActions}
          up={up}
        />
      }

    </div>
  );
};

export default Control;

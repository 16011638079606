import React from 'react';
import NotificationForm from './NotificationForm';
import styles from "./CreateNotifications.module.sass";

const CreateNotifications = () => {
  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          <NotificationForm className={styles.card} />
        </div>
      </div>
    </>
  )
}

export default CreateNotifications
import {
  collection, getDocs,
  doc, setDoc,
  query, where, deleteDoc, startAt, endAt, limit, orderBy, startAfter, getCountFromServer, endBefore, limitToLast, arrayRemove, updateDoc
} from '@firebase/firestore';
import { db, storage } from './firebaseConfig';
import { ref, getDownloadURL, uploadBytesResumable, uploadBytes } from "firebase/storage";

import { CREATE_NEW_ATTRIBUTE, PRODUCTS_TO_SHOW, VARIATION } from '../utils/constants';
import { capitalizeFirstLetter, getLocalDateTimeNow } from '../utils/extensions';


// {--------------------------products and variation---------------------------------}

// const uploadProduct = async (object1, object2, setIsActionComplete, setIsClicked) => {
//   const productCollectionRef = doc(collection(db, "product"));

//   object1.id = productCollectionRef.id
//   object1.dateCreated = new Date().toISOString()

//   await setDoc(productCollectionRef, object1).then(async () => {
//     await object2.map(async (elem, i) => {
//       const variationCollectionRef = doc(db, "product", object1.id, VARIATION, elem.id);
//       await setDoc(variationCollectionRef, elem).then(async () => {

//       }).catch((e) => {

//       });
//     })
//     setIsActionComplete(true)
//     setIsClicked(false)
//   }).catch((e) => {

//   });

// }

const uploadProduct = async (productNameDescForm) => {
  return new Promise(async (resolve, reject) => {
    const productCollectionRef = doc(collection(db, "product"));
    productNameDescForm.id = productCollectionRef.id;
    productNameDescForm.dateCreated = getLocalDateTimeNow();
    await setDoc(productCollectionRef, productNameDescForm)
      .then(async () => {
        resolve(productNameDescForm.id)
      }, 2000)
      .catch((e) => {
        resolve(null)
        console.log("failed upload product", e);
      });
  })
}

const getProducts = async () => {
  return new Promise((resolve, reject) => {

    const categoriesCollectionRef = collection(db, 'product')

    getDocs(categoriesCollectionRef).then((doc) => {
      const list = doc.docs.map(doc => ({
        data: doc.data(),
        id: doc.id
      }))
      // console.log("category list: ", list)
      resolve(list)
    })

  })
}

const getProductById = async (id) => {
  return new Promise((resolve, reject) => {
    const docRef = query(collection(db, "product"), where("id", "==", id))
    getDocs(docRef).then((doc) => {
      const productDetails = doc.docs.map(doc => ({
        data: doc.data(),
        id: doc.id
      }))
      resolve(productDetails)
    })

  })
}

const getProductByField = async (fieldName, fieldVal) => {
  return new Promise((resolve, reject) => {
    const docRef = query(collection(db, "product"), where(fieldName, "==", fieldVal))
    getDocs(docRef).then((doc) => {
      const productDetails = doc.docs.map(doc => ({
        data: doc.data(),
      }))
      resolve(productDetails)
    })

  })
}

const deleteProduct = async (id) => {
  return new Promise(async (resolve, reject) => {
    const docRef = doc(db, "product", id);
    await deleteDoc(docRef).then((res) => {
      resolve(true)
    })
  })
}

const uploadVariation = async (productId, variationData) => {
  return new Promise(async (resolve, reject) => {
    const varDocRef = doc(
      db,
      "product",
      productId,
      VARIATION,
      variationData.id
    );
    if (variationData.images.length !== 0) {
      var arrayList = [];
      var totalUpImg = variationData.images.length
      await variationData.images.forEach(async (elem, i) => {
        await uploadFile(elem, "product").then(async (url) => {
          arrayList.push(url);
          variationData.images = arrayList;
        });
        console.log(arrayList)
        if (arrayList.length === totalUpImg) {
          console.log("seting variations...........")
          variationData.dateCreated = getLocalDateTimeNow();
          await setDoc(varDocRef, variationData)
            .then(async () => {
              resolve(true)
            })
            .catch((e) => {
              console.log("failed upload variations", e);
              resolve(false)
            });
        }
      });
    } else {
      variationData.dateCreated = getLocalDateTimeNow();
      await setDoc(varDocRef, variationData)
        .then(async () => {
          resolve(true)
        })
        .catch((e) => {
          console.log("failed upload variations", e);
          resolve(false)
        });
    }

  })
}

const getVariationById = async (productId, variationId) => {
  return new Promise(async (resolve, reject) => {
    const docRef = query(collection(db, "product", productId, "variation"), where("id", "==", variationId))
    await getDocs(docRef).then((doc) => {
      const variationDetails = doc.docs.map(doc => ({
        data: doc.data(),
        id: doc.id
      }))
      resolve(variationDetails)
    })

  })
}

const getAllVariations = (id) => {
  return new Promise(async (resolve, reject) => {
    const docRef = collection(db, 'product', id, "variation")
    getDocs(docRef).then((doc) => {
      const variationsList = doc.docs.map(doc => (
        doc.data()
      ))
      resolve(variationsList)
    })

  })
}
// {--------------------------end products and variation---------------------------------}

const createAttribute = async (object, formType, setIsActionComplete, setIsClicked) => {

  if (formType === CREATE_NEW_ATTRIBUTE) {
    const attrCollectionRef = doc(collection(db, "attribute"));
    object.id = attrCollectionRef.id
    object.dateCreated = new Date().toISOString()

    await setDoc(attrCollectionRef, object).then((res) => {
      // console.log("create attribute response: ", res)
      setIsActionComplete(true)
      setIsClicked(false)
    }).catch((err) => {
      // console.log("Can not create attribute!")
      setIsActionComplete(true)
      setIsClicked(false)
    })
  }

}

const getAttributes = async (setAttributeList) => {

  const attributeCollectionRef = collection(db, 'attribute')
  await getDocs(attributeCollectionRef)
    .then(response => {
      const attributesList = response.docs.map(doc => ({
        data: doc.data(),
        id: doc.id
      }))
      // console.log("list: ", attributesList)
      setAttributeList(attributesList)
    })

    .catch(error => console.log(error.message))
}

async function getAttributeById(id, setAttributeById) {

  const attributeCollectionRef = query(collection(db, "attribute"), where("id", "==", id))
  await getDocs(attributeCollectionRef)
    .then(response => {
      const attribute = response.docs
        .map(doc => ({
          data: doc.data()
        }))
      // console.log("project by Id: ", attribute)
      setAttributeById(attribute)
    })
    .catch(error => console.log(error.message))
}

const updateAttributeById = (id, object, setIsActionComplete, setIsClicked) => {
  object.dateEdited = new Date().toISOString()
  const docRef = doc(db, "attribute", id);
  setDoc(docRef, object, {
    merge: true
  }).then(() => {
    setIsActionComplete(true)
    setIsClicked(false)
  });
}

const deleteAttribute = async (id, setIsActionComplete, setIsClicked) => {
  // console.log(id)
  const docRef = doc(db, "attribute", id);
  await deleteDoc(docRef).then((res) => {
    // console.log("deleted attribute successfuly!");
    setIsActionComplete(true);
    setIsClicked(false);
  })

}


// {-------------categories and subCategories----------------}

// const uploadFile = (file, path) => {
//   return new Promise((resolve, reject) => {
//     if (file) {
//       const storageRef = ref(storage, `${path}/${file.name}`);
//       const uploadTask = uploadBytesResumable(storageRef, file);
//       uploadTask.on("state_changed",
//         (snapshot) => {
//           const progress =
//             Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
//           console.log("progress", progress)
//         },
//         (error) => {
//           alert("err-progress", error.message);
//         },
//         () => {
//           getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
//             console.log(downloadURL);
//             return resolve(downloadURL)
//           });
//         }
//       );
//     } else {
//       console.log("No file selected!")
//     }
//   })
// }


const uploadFile = (file, path) => {
  return new Promise((resolve, reject) => {
    if (file) {
      const storageRef = ref(storage, `${path}/${file.name}`);
      const uploadTask = uploadBytes(storageRef, file);
      uploadTask.then((data) => {
        getDownloadURL(data.ref).then((downloadURL) => {
          console.log(downloadURL);
          return resolve(downloadURL);
        });
      })
    } else {
      console.log("No file selected!")
    }
  });
};

const createCategory = (object, setIsClicked, setIsActionComplete) => {
  return new Promise((resolve, reject) => {
    const docRef = doc(collection(db, "category"));
    object.id = docRef.id;
    object.dateCreated = new Date().toISOString();
    setDoc(docRef, object).then((res) => {
      // console.log("create category response: ", res)
      resolve(true)
      setIsActionComplete(true)
      setIsClicked(false)
    }).catch((err) => {
      // console.log("Can not create attribute!")
      setIsActionComplete(true)
      setIsClicked(false)
    })

  })
}

const createSubCategory = (id, object, setIsClicked, setIsActionComplete) => {
  const docRef = doc(collection(db, "category", id, "subCategory"));
  object.id = docRef.id;
  object.dateCreated = new Date().toISOString();
  setDoc(docRef, object).then(async () => {
    setIsActionComplete(true)
    setIsClicked(false)
  }).catch((e) => {

  });

}

const updateCatgory = async (object, setIsClicked, setIsActionComplete) => {

  // console.log("obj in firefase:", object, "id:", object.id)
  const id = object.id;
  const docRef = doc(db, "category", id);
  object.dateEdited = new Date().toISOString();
  // console.log("edited date:", object.dateEdited)
  await setDoc(docRef, object, {
    merge: true
  }).then(() => {
    setIsActionComplete(true)
    setIsClicked(false)
  });
}

const updateSubCatgory = async (categoryId, object, setIsClicked, setIsActionComplete) => {

  // console.log("obj in firefase:", object, "id:", object.id)
  const id = object.id;
  const docRef = doc(db, "category", categoryId, "subCategory", id);
  object.dateEdited = new Date().toISOString();
  // console.log("edited date:", object.dateEdited)
  await setDoc(docRef, object, {
    merge: true
  }).then(() => {
    setIsActionComplete(true)
    setIsClicked(false)
  });
}

const getCategories = async () => {

  return new Promise((resolve, reject) => {
    const categoriesCollectionRef = collection(db, 'category')
    getDocs(categoriesCollectionRef).then((doc) => {
      const categoriesList = doc.docs.map(doc => ({
        data: doc.data(),
        id: doc.id
      }))
      // console.log("category list: ", categoriesList)
      resolve(categoriesList)
    })

  })
}

const getCategoryById = async (id) => {

  return new Promise((resolve, reject) => {
    const dataRef = query(collection(db, "category"), where("id", "==", id))
    getDocs(dataRef).then((doc) => {
      const categoryDetails = doc.docs.map(doc => ({
        data: doc.data(),
        id: doc.id
      }))
      // console.log("category details: ", categoryDetails)
      resolve(categoryDetails)
    })

  })
}

const getSubCategories = async (id) => {
  return new Promise((resolve, reject) => {
    const docRef = collection(db, "category", id, "subCategory")
    getDocs(docRef).then((doc) => {
      const subCategoriesList = doc.docs.map(doc => ({
        data: doc.data(),
        id: doc.id
      }))
      resolve(subCategoriesList)
    })
  })
}

const getSubCategoryById = (categoryId, subCategoryId) => {
  return new Promise((resolve, reject) => {
    const docRef = query(collection(db, "category", categoryId, "subCategory"), where("id", "==", subCategoryId))
    getDocs(docRef).then((doc) => {
      const subCategoryDetails = doc.docs.map(doc => ({
        data: doc.data(),
        id: doc.id
      }))
      resolve(subCategoryDetails)
    })
  })
}

const deleteCategory = async (id, setIsActionComplete, setIsClicked) => {
  // console.log("delete id:", id)
  const docRef = doc(db, "category", id);
  await deleteDoc(docRef).then((res) => {
    // console.log("deleted category successfuly!");
    setIsActionComplete(true);
    setIsClicked(false);
  })
}

const deleteSubCategoryById = async (categoryId, subCategoryId, setIsActionComplete, setIsClicked) => {
  // console.log("delete id:", categoryId, subCategoryId)
  const docRef = doc(db, "category", categoryId, "subCategory", subCategoryId);
  await deleteDoc(docRef).then((res) => {
    // console.log("deleted sub category successfuly!");
    setIsActionComplete(true);
    setIsClicked(false);
  })
}
// {------------------end categories and subCategories------------------------------}

// {--------------notification-------------------}
const createNotification = (object) => {
  return new Promise((resolve, reject) => {
    const docRef = doc(collection(db, "notification"));
    object.id = docRef.id;
    object.dateCreated = new Date().toISOString();
    setDoc(docRef, object).then((res) => {
      // console.log("create category response: ", res)
      resolve(true)
    }).catch((err) => {
      resolve(false)
      // console.log("Can not create attribute!")
    })

  })
}
// {--------------end notification-------------------}

// {-----------------------orders------------------------------}
const getOrders = async () => {
  return new Promise((resolve, reject) => {
    const ordersCollectionRef = collection(db, 'order')
    getDocs(ordersCollectionRef).then((doc) => {
      const ordersList = doc.docs.map(doc => ({
        data: doc.data(),
        id: doc.id
      }))
      // console.log("order list: ", ordersList)
      resolve(ordersList)
    })

  })
}

const getTotalOrders = async (totalProducts, totalSellers, total) => {
  return new Promise((resolve, reject) => {
    const orderCollectionRef = collection(db, 'order')
    const productCollectionRef = collection(db, 'product')
    const sellerCollectionRef = collection(db, 'seller')

    getDocs(orderCollectionRef).then((doc) => {
      // console.log("order list: ", doc.docs.length)
      resolve(doc.docs.length)

      getDocs(productCollectionRef).then((doc) => {
        // console.log("products list: ", doc.docs.length)
        getDocs(sellerCollectionRef).then((doc) => {
          // console.log("seller list: ", doc.docs.length)

        })

      })
    })

  })
}

const getOrderById = async (id) => {
  return new Promise((resolve, reject) => {
    const orderCollectionRef = query(collection(db, "order"), where("id", "==", id))
    getDocs(orderCollectionRef).then((doc) => {
      const orderDetails = doc.docs.map(doc => ({
        data: doc.data(),
        id: doc.id
      }))
      // console.log("order list: ", orderDetails)
      resolve(orderDetails)
    })

  })
}

const updateOrderById = (id, object, setIsActionComplete, setIsClicked) => {
  return new Promise((resolve, reject) => {
    object.dateEdited = new Date().toISOString()
    const docRef = doc(db, "order", id);
    setDoc(docRef, object, {
      merge: true
    }).then(() => {
      setIsClicked(false)
      setIsActionComplete(true)
      resolve(true)
    }).catch(() => {
      resolve(false)
    });
  })
}

const getOrderItemsById = async (id) => {
  return new Promise((resolve, reject) => {
    const orderItemsCollectionRef = collection(db, "order", id, "item")
    getDocs(orderItemsCollectionRef).then((doc) => {
      const ItemsList = doc.docs.map(doc => ({
        data: doc.data(),
        id: doc.id
      }))
      // console.log("Items list: ", ItemsList)
      resolve(ItemsList)
    })

  })
}
// {-----------------------end orders------------------------------}

// {---------------------------seller------------------------------------------}
const createSeller = (object, id, setIsClicked, setIsActionComplete) => {
  // console.log("seller data in firebase:", object)
  const docRef = doc(db, "seller", object.id);
  // object.id = docRef.id;
  object.dateCreated = new Date().toISOString();
  setDoc(docRef, object).then((res) => {
    setIsActionComplete(true)
    setIsClicked(false)
  }).catch((err) => {
    // console.log("Can not create seller!")
    setIsActionComplete(true)
    setIsClicked(false)
  })
}

const getAllSellers = async () => {
  return new Promise(async (resolve, reject) => {
    const docRef = collection(db, 'seller')
    await getDocs(docRef).then((doc) => {
      const sellersList = doc.docs.map(doc => ({
        data: doc.data(),
        id: doc.id
      }))
      // console.log("sellers list: ", sellersList)
      resolve(sellersList)
    })
  })
}

const getAllSellersForSellersPage = async (startFrom, endValue) => {
  return new Promise(async (resolve, reject) => {
    const docRef = collection(db, 'seller')
    if (endValue !== '') {
      await getDocs(query(docRef, orderBy('nameEn', 'asc'), endBefore(endValue), limitToLast(PRODUCTS_TO_SHOW))).then((doc) => {
        const sellersList = doc.docs.map(doc => ({
          data: doc.data(),
          id: doc.id
        }))
        // console.log("sellers list: ", sellersList)
        resolve(sellersList)
      }).catch(e => reject(e))
    } else {
      await getDocs(query(docRef, orderBy('nameEn', 'asc'), startAfter(startFrom), limit(PRODUCTS_TO_SHOW))).then((doc) => {
        const sellersList = doc.docs.map(doc => ({
          data: doc.data(),
          id: doc.id
        }))
        // console.log("sellers list: ", sellersList)
        resolve(sellersList)
      }).catch(e => reject(e))
    }

  })
}

const getAllSellersBySearch = async (searchBy, searchFor) => {
  return new Promise(async (resolve, reject) => {
    const docRef = collection(db, 'seller')
    if (searchFor === 'nameEn') {
      await getDocs(query(docRef, where('nameEn', ">=", capitalizeFirstLetter(searchBy)),)).then((doc) => {
        const sellersList = doc.docs.map(doc => ({
          data: doc.data(),
          id: doc.id
        }))
        // console.log("sellers list: ", sellersList)
        resolve(sellersList)
      })
    } else {
      await getDocs(query(docRef, where('id', "==", searchBy),)).then((doc) => {
        const sellersList = doc.docs.map(doc => ({
          data: doc.data(),
          id: doc.id
        }))
        // console.log("sellers list: ", sellersList)
        resolve(sellersList)
      })
    }

  })
}

const getSellerById = async (id) => {

  return new Promise((resolve, reject) => {
    const docRef = query(collection(db, 'seller'), where("id", "==", id))
    getDocs(docRef).then((doc) => {
      const sellerData = doc.docs.map(doc => ({
        data: doc.data(),
        id: doc.id
      }))
      // console.log("seller data: ", sellerData)
      resolve(sellerData)
    })

  })
}

const updateSellerById = (object, setIsActionComplete, setIsClicked) => {

  object.dateEdited = new Date().toISOString()
  const docRef = doc(db, "seller", object.id);
  setDoc(docRef, object, {
    merge: true
  }).then(() => {
    setIsActionComplete(true)
    setIsClicked(false)
  });

}

const deleteSeller = async (id, setIsActionComplete, setIsClicked) => {
  const docRef = doc(db, "seller", id);
  await deleteDoc(docRef).then((res) => {
    setIsActionComplete(true);
    setIsClicked(false);
  })
}

// {------------------------end seller-----------------------------------}

// {---------------------------------coupons-------------------------------------------}
const createCoupon = (object, setIsClicked, setIsActionComplete) => {
  const docRef = doc(collection(db, "coupon"));
  object.couponId = docRef.id;
  object.dateCreated = new Date().toISOString();
  setDoc(docRef, object).then((res) => {
    setIsActionComplete(true)
    setIsClicked(false)
  }).catch((err) => {
    setIsActionComplete(true)
    setIsClicked(false)
  })
}

const getCoupons = async () => {
  return new Promise((resolve, reject) => {
    const docRef = collection(db, 'coupon')
    getDocs(docRef).then((doc) => {
      const couponList = doc.docs.map(doc => ({
        data: doc.data(),
        id: doc.id
      }))
      resolve(couponList)
    })
  })
}

const getCouponById = async (id) => {
  return new Promise(async (resolve, reject) => {
    const docRef = query(collection(db, 'coupon'), where("couponId", "==", id))
    await getDocs(docRef).then((doc) => {
      const data = doc.docs.map(doc => ({
        data: doc.data(),
        id: doc.id
      }))
      resolve(data)
    })

  })
}

const updateCouponById = (object, setIsActionComplete, setIsClicked) => {

  object.dateEdited = new Date().toISOString()
  const docRef = doc(db, "coupon", object.couponId);
  setDoc(docRef, object, {
    merge: true
  }).then(() => {
    setIsActionComplete(true)
    setIsClicked(false)
  });

}

const deleteCoupon = async (id, setIsActionComplete, setIsClicked) => {
  const docRef = doc(db, "coupon", id);
  await deleteDoc(docRef).then((res) => {
    setIsActionComplete(true);
    setIsClicked(false);
  })
}
// {---------------------------------end coupons-------------------------------------------}

// {----------------------------------store rules----------------------------------------------}
const createStoreRule = (object) => {
  return new Promise(async (resolve, reject) => {
    const coll = collection(db, "storeRule");
    const snapshot = await getCountFromServer(coll);

    if (snapshot.data().count === 0) {
      const docRef = doc(collection(db, "storeRule"));
      object.id = docRef.id;
      object.dateCreated = new Date().toISOString();
      setDoc(docRef, object).then((res) => {
        resolve(object.id)
      }).catch((err) => {

      })
    }
    else {
      const docRef = collection(db, "storeRule")
      getDocs(docRef).then(async (document) => {
        const productDetails = document.docs.map(doc => (
          doc.id
        ))
        const storeRuleId = productDetails[0]

        object.dateEdited = new Date().toISOString()
        object.id = storeRuleId;

        const docRef = await doc(db, "storeRule", storeRuleId);
        setDoc(docRef, object, {
          merge: true
        }).then(() => {
          resolve(object.id)
        });
      })
    }
  })
}

const getStoreRules = () => {
  return new Promise((resolve, reject) => {
    const docRef = collection(db, 'storeRule')
    getDocs(docRef).then((doc) => {
      const storeRules = doc.docs.map(doc => ({
        data: doc.data(),
        id: doc.id
      }))
      resolve(storeRules)
    })

  })
}
// {----------------------------------end store rules----------------------------------------------}

// {--------- coluntry---------------}
const createCountry = (object) => {
  return new Promise(async (resolve, reject) => {
    const docRef = doc(db, "country", object.id);
    object.dateCreated = new Date().toISOString();
    setDoc(docRef, object).then((res) => {
      resolve(true)
    }).catch((err) => {
      resolve(false)
    })
  })
}

const getCountries = () => {
  return new Promise((resolve, reject) => {
    const docRef = collection(db, 'country')
    getDocs(docRef).then((doc) => {
      const countryList = doc.docs.map(doc => (
        doc.data()
      ))
      // console.log("store rules data: ", countryList)
      resolve(countryList)
    })

  })
}

const getCountryById = async (id) => {
  return new Promise(async (resolve, reject) => {
    const docRef = query(collection(db, 'country'), where("id", "==", id))
    await getDocs(docRef).then((doc) => {
      const data = doc.docs.map(doc => ({
        data: doc.data(),
      }))
      resolve(data)
    }).catch(() => {
      resolve(false)
    })
  })
}

const updateCountry = (object) => {
  return new Promise((resolve, reject) => {
    object.dateEdited = new Date().toISOString()
    const docRef = doc(db, "country", object.id);
    setDoc(docRef, object, {
      merge: true
    }).then(() => {
      resolve(true)
    }).catch(err => {
      // console.log("can't update country:", err);
      resolve(false)
    })
  })
}

const deleteCountry = async (id) => {
  return new Promise(async (resolve, reject) => {
    // console.log("delete id:", id)
    const docRef = doc(db, "country", id);
    await deleteDoc(docRef).then((res) => {
      // console.log("deleted country successfuly!");
    })
  })
}
// {---------end country---------------}

// {--------- locations---------------}
const createLocations = (object) => {
  return new Promise(async (resolve, reject) => {
    const docRef = doc(db, "location", object.id);
    // object.id = docRef.id;
    object.dateCreated = new Date().toISOString();
    setDoc(docRef, object).then((res) => {
      resolve(true)
    }).catch((err) => {
      // console.log("Can not create seller!")
      resolve(false)
    })
  })
}

const getLocations = () => {
  return new Promise((resolve, reject) => {
    const docRef = collection(db, 'location')
    getDocs(docRef).then((doc) => {
      const locationsList = doc.docs.map(doc => (
        doc.data()
      ))
      // console.log("store rules data: ", locationsList)
      resolve(locationsList)
    })

  })
}

const getLocationById = async (id) => {
  return new Promise(async (resolve, reject) => {
    const docRef = query(collection(db, 'location'), where("id", "==", id))
    await getDocs(docRef).then((doc) => {
      const data = doc.docs.map(doc => ({
        data: doc.data(),
      }))
      resolve(data)
    }).catch(() => {
      resolve(false)
    })

  })
}

const updateLocation = (object) => {
  return new Promise((resolve, reject) => {
    object.dateEdited = new Date().toISOString()
    const docRef = doc(db, "location", object.id);
    setDoc(docRef, object, {
      merge: true
    }).then(() => {
      resolve(true)
    }).catch(err => {
      // console.log("can't update location:", err);
      resolve(false)
    })
  })
}

const deleteLocation = async (id) => {
  return new Promise(async (resolve, reject) => {
    // console.log("delete id:", id)
    const docRef = doc(db, "location", id);
    await deleteDoc(docRef).then((res) => {
      // console.log("deleted location successfuly!");
    })
  })
}
// {---------end locations---------------}


// {--------- Keywords---------------}
const createKeyword = (object) => {
  return new Promise(async (resolve, reject) => {
    const docRef = doc(db, "keyword", object.id);
    // object.id = docRef.id;
    object.dateCreated = new Date().toISOString();
    setDoc(docRef, object).then((res) => {
      resolve(true)
    }).catch((err) => {
      // console.log("Can not create keyword!")
      resolve(false)
    })
  })
}

const getKeywords = () => {
  return new Promise((resolve, reject) => {
    const docRef = collection(db, 'keyword')
    getDocs(docRef).then((doc) => {
      const keywordsList = doc.docs.map(doc => (
        doc.data()
      ))
      // console.log("store rules data: ", keywordsList)
      resolve(keywordsList)
    })

  })
}

const getKeywordById = async (id) => {
  // console.log("id in firebase:", id)
  return new Promise(async (resolve, reject) => {
    const docRef = query(collection(db, 'keyword'), where("id", "==", id))
    await getDocs(docRef).then((doc) => {
      const data = doc.docs.map(doc => ({
        data: doc.data(),
        id: doc.id
      }))
      // console.log("keyword data: ", data)
      resolve(data)
    })

  })
}

const updateKeyword = (object) => {
  return new Promise((resolve, reject) => {
    object.dateEdited = new Date().toISOString()
    const docRef = doc(db, "keyword", object.id);
    setDoc(docRef, object, {
      merge: true
    }).then(() => {
      resolve(true)
    }).catch(err => {
      // console.log("can't update keyword:", err);
      resolve(false)
    })
  })
}

const deleteKeyword = async (id) => {
  return new Promise(async (resolve, reject) => {
    const docRef = doc(db, "keyword", id);
    await deleteDoc(docRef).then((res) => {
      resolve(true)
      // console.log("deleted keyword successfuly!");
    })
  })
}
// {---------end keywords---------------}

// {---------shipping methods---------------}
const getShippingMethods = () => {
  return new Promise((resolve, reject) => {
    const docRef = collection(db, 'shippingMethod')
    getDocs(docRef).then((doc) => {
      const shippingMethodsList = doc.docs.map(doc => (
        doc.data()
      ))
      // console.log("shipping Methods List: ", shippingMethodsList)
      resolve(shippingMethodsList)
    })

  })
}

const getShippingMethodById = async (id) => {
  return new Promise(async (resolve, reject) => {
    const docRef = query(collection(db, 'shippingMethod'), where("id", "==", id))
    await getDocs(docRef).then((doc) => {
      const data = doc.docs.map(doc => ({
        data: doc.data(),
      }))
      resolve(data)
    }).catch(() => {
      resolve(false)
    })

  })
}

const createShippingMethods = (object) => {
  // console.log("shippig data:", object)
  return new Promise(async (resolve, reject) => {
    const docRef = doc(db, "shippingMethod", object.id);
    object.dateCreated = new Date().toISOString();
    object.dateEdited = ''
    setDoc(docRef, object).then((res) => {
      resolve(true)
    }).catch((err) => {
      // console.log("Can not create shipping method!");
      resolve(false)
    })
  })
}

const updateShippingMethod = (object) => {
  return new Promise((resolve, reject) => {
    object.dateEdited = new Date().toISOString()
    const docRef = doc(db, "shippingMethod", object.id);
    setDoc(docRef, object, {
      merge: true
    }).then(() => {
      resolve(true)
    }).catch(err => {
      // console.log("can't update shippingMethod:", err);
      resolve(false)
    })
  })
}

const deleteShippingMethod = async (id) => {
  return new Promise(async (resolve, reject) => {
    // console.log("delete id:", id)
    const docRef = doc(db, "shippingMethod", id);
    await deleteDoc(docRef).then((res) => {
      // console.log("deleted shippingMethod successfuly!");
    })
  })
}
// {---------end shipping methods---------------}

// {------------------------home page customiser---------------------------}

const createFeaturedProducts = (object) => {
  return new Promise(async (resolve, reject) => {
    const coll = collection(db, "featuredProducts");
    const snapshot = await getCountFromServer(coll);

    if (snapshot.data().count === 0) {
      const docRef = doc(collection(db, "featuredProducts"));
      object.id = docRef.id;
      object.dateCreated = new Date().toISOString();
      setDoc(docRef, object).then((res) => {
        resolve(true)
      }).catch((err) => {
        resolve(false)
      })
    }
    else {
      const docRef = collection(db, "featuredProducts")
      getDocs(docRef).then(async (document) => {
        const productDetails = document.docs.map(doc => (
          doc.id
        ))
        const featuredProductsId = productDetails[0]

        object.dateEdited = new Date().toISOString()
        object.id = featuredProductsId;

        const docRef = await doc(db, "featuredProducts", featuredProductsId);
        setDoc(docRef, object, {
          merge: true
        }).then(() => {
          resolve(true)
        });
      })
    }
  })
}

const getFeaturedProducts = () => {
  return new Promise((resolve, reject) => {
    const docRef = collection(db, 'featuredProducts')
    getDocs(docRef).then((doc) => {
      const featuredProductsList = doc.docs.map(doc => (
        doc.data()
      ))
      resolve(featuredProductsList)
    })

  })
}

const deleteFeaturedProduct = async (obj) => {
  return new Promise(async (resolve, reject) => {

    getFeaturedProducts().then(async (res) => {
      const updatedList = res[0].productsList.filter(data => {
        if (!obj?.includes(data.variationId)) {
          return data
        }
      })
      res[0].productsList = updatedList
      createFeaturedProducts(res[0]).then(response => {
        if (response === true) {
          resolve(true)
        }
      })

    })
  })
}

// {--------------------------------banners-------------------------}
const createBanner = (object) => {
  return new Promise(async (resolve, reject) => {
    const docRef = doc(collection(db, "banner"));
    object.id = docRef.id;
    object.dateCreated = new Date().toISOString();
    setDoc(docRef, object).then((res) => {
      resolve(true)
    }).catch((err) => {
      resolve(false)
    })
  })
}

const getBanners = () => {
  return new Promise((resolve, reject) => {
    const docRef = collection(db, 'banner')
    getDocs(docRef).then((doc) => {
      const bannersList = doc.docs.map(doc => (
        doc.data()
      ))
      resolve(bannersList)
    })

  })
}

const deleteBanner = async (id) => {
  return new Promise(async (resolve, reject) => {
    const docRef = doc(db, "banner", id);
    await deleteDoc(docRef).then((res) => {
      resolve(true)
    })
  })
}
// {--------------------------------end banners-------------------------}

// {------------------------end home page customiser---------------------------}

// {-----------------------collection--------------------------}
const createCollection = (object) => {
  return new Promise((resolve, reject) => {
    const docRef = doc(collection(db, "collection"));
    object.id = docRef.id;
    object.dateCreated = new Date().toISOString();
    setDoc(docRef, object).then((res) => {
      // console.log("create category response: ", res)
      resolve(true)
      // setIsActionComplete(true)
      // setIsClicked(false)
    }).catch((err) => {
      resolve(false)
      // console.log("Can not create attribute!")
      // setIsActionComplete(true)
      // setIsClicked(false)
    })

  })
}

const updateCollection = async (object) => {
  return new Promise(async (resolve, reject) => {
    const id = object.id;
    const docRef = doc(db, "collection", id);
    object.dateEdited = new Date().toISOString();
    // console.log("edited date:", object.dateEdited)
    await setDoc(docRef, object, {
      merge: true
    }).then(() => {
      resolve(true)
      // setIsActionComplete(true)
      // setIsClicked(false)
    }).catch(err => {
      resolve(false)
    })
  })
}

const getCollectionById = async (id) => {

  return new Promise((resolve, reject) => {
    const dataRef = query(collection(db, "collection"), where("id", "==", id))
    getDocs(dataRef).then((doc) => {
      const collectionDetails = doc.docs.map(doc => ({
        data: doc.data(),
        id: doc.id
      }))
      // console.log("category details: ", categoryDetails)
      resolve(collectionDetails)
    })

  })
}

const getCollection = async () => {

  return new Promise((resolve, reject) => {
    const collectionRef = collection(db, 'collection')
    getDocs(collectionRef).then((doc) => {
      const collectionList = doc.docs.map(doc => ({
        data: doc.data(),
        id: doc.id
      }))
      resolve(collectionList)
    })

  })
}

const deleteCollection = async (id) => {
  return new Promise(async (resolve, reject) => {
    const docRef = doc(db, "collection", id);
    await deleteDoc(docRef).then((res) => {
      resolve(true)
    }).catch(() => {
      resolve(false)
    })
  })
}

const createCollectionProducts = (id, object) => {
  return new Promise(async (resolve, reject) => {
    const coll = collection(db, "collection", id, "product");
    const snapshot = await getCountFromServer(coll);

    if (snapshot.data().count === 0) {
      const docRef = doc(collection(db, "collection", id, "product"));
      object.id = docRef.id;
      object.dateCreated = new Date().toISOString();
      setDoc(docRef, object).then((res) => {
        resolve(true)
      }).catch((err) => {
        resolve(false)
      })
    }
    else {
      const docRef = collection(db, "collection", id, "product")
      getDocs(docRef).then(async (document) => {
        const productDetails = document.docs.map(doc => (
          doc.id
        ))
        const featuredProductsId = productDetails[0]

        object.dateEdited = new Date().toISOString()
        object.id = featuredProductsId;

        const docRef = await doc(db, "collection", id, "product", featuredProductsId);
        setDoc(docRef, object, {
          merge: true
        }).then(() => {
          resolve(true)
        });
      })
    }
  })
}

const getCollectionProducts = (collectionId) => {
  return new Promise((resolve, reject) => {
    const docRef = collection(db, 'collection', collectionId, "product")
    getDocs(docRef).then((doc) => {
      const collectionProductsList = doc.docs.map(doc => (
        doc.data()
      ))
      resolve(collectionProductsList)
    })

  })
}

const deleteCollProducts = async (collectionId, obj) => {
  return new Promise(async (resolve, reject) => {

    getCollectionProducts(collectionId).then(async (res) => {
      const updatedList = res[0].productsList.filter(data => {

        if (!obj.includes(data.variationId)) {
          return data
        }

      })
      res[0].productsList = updatedList
      console.log("updated products:", res)

      createCollectionProducts(collectionId, res[0]).then(response => {
        if (response === true) {
          resolve(true)
        } else {
          resolve(false)
        }
      })

    })
  })
}
// {-----------------------end collection--------------------------}



export {
  // Products and variation
  getProducts, uploadProduct, getProductById, getProductByField, deleteProduct,
  getVariationById, getAllVariations, uploadVariation,

  // Attributes
  createAttribute, getAttributes, getAttributeById, updateAttributeById, deleteAttribute,

  // Category and subCategory
  createCategory, getCategoryById, updateCatgory, getCategories, deleteCategory,
  createSubCategory, getSubCategories, getSubCategoryById, updateSubCatgory, deleteSubCategoryById,

  // Orders
  getOrders, getOrderById, getOrderItemsById, updateOrderById, getTotalOrders,

  // To upload file
  uploadFile,

  // Sellers
  createSeller, getAllSellers as getSellers, getSellerById, updateSellerById, deleteSeller, getAllSellersBySearch, getAllSellersForSellersPage,

  // Coupons 
  createCoupon, getCoupons, getCouponById, updateCouponById, deleteCoupon,

  // Store rules
  createStoreRule, getStoreRules,
  createLocations, getLocations, getLocationById, updateLocation, deleteLocation,
  createShippingMethods, updateShippingMethod, deleteShippingMethod, getShippingMethods, getShippingMethodById,
  createKeyword, getKeywords, updateKeyword, deleteKeyword, getKeywordById,
  createCountry, getCountries, getCountryById, updateCountry, deleteCountry,

  // Notifications
  createNotification,

  //{---------------- Home page customiser-----------------------}
  // Featured products
  createFeaturedProducts, getFeaturedProducts, deleteFeaturedProduct,
  // Banners
  createBanner, getBanners, deleteBanner,
  //{----------------end Home page customiser-----------------------}

  // collection
  createCollection, updateCollection, getCollectionById, getCollection, deleteCollection,
  createCollectionProducts, getCollectionProducts, deleteCollProducts

}

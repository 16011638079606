import React, { useState, useContext, useEffect } from "react";
import cn from "classnames";
// import { Link, useLocation } from "react-router-dom";
import styles from "./NameAndValue.module.sass";
import Card from "../../../components/Card";
// import Icon from "../../../components/Icon";
import TextInput from "../../../components/TextInput";
import Dropdown from "../../../components/Dropdown";
import { createAttribute, getAttributeById, updateAttributeById } from "../../../Firebase/firebaseDatabase";
import { validate_if_not_empty } from "../../../utils/extensions";
import LoadingDialog from "../../../LoadingScreen/LoadingDialog";
import { GlobalState } from "../../../App";
import { CREATE_NEW_ATTRIBUTE, UPDATE_NEW_ATTRIBUTE } from "../../../utils/constants";
import { useLocation } from 'react-router-dom'


const NameAndValue = ({ className }) => {

  const { isClicked, setIsClicked,
    isActionComplete, setIsActionComplete,
    attributeById, setAttributeById
  } = useContext(GlobalState);

  const [isEditForm, setIsEditForm] = useState(false)

  const location = useLocation()

  useEffect(() => {
    setIsActionComplete(false)

    if (window.location.pathname !== "/products/attributes/add") {
      setLoading(true)
      setIsEditForm(true)
     // console.log(location.pathname.split("/")[2])
      const attributeId = location.pathname.split("/")[3]
     // console.log("project id in edit form: ", attributeId)
      if (attributeId !== "add") {
        getAttributeById(attributeId, setAttributeById)
      }
    }
  }, [])

  useEffect(() => {
    if (window.location.pathname !== "/products/attributes/add") {
      if (attributeById !== undefined) {
        setLoading(false)
        setAttrLbl(attributeById[0]?.data?.label ?? '')
        setAttrType(attributeById[0]?.data?.type ?? optionsType[0])
        setStatus(attributeById[0]?.data?.status ?? optionsStatus[0])
        setAttrVal(attributeById[0]?.data?.value ?? '')
      }
    }
  }, [attributeById])

  const optionsStatus = ["Select status", "Active", "Not Active"]
  const optionsType = ["Select type", "Size", "Color"]

  const [loading, setLoading] = useState(false)

  const [attrLbl, setAttrLbl] = useState()
  const [attrType, setAttrType] = useState(optionsType[0])
  const [attrVal, setAttrVal] = useState()
  const [status, setStatus] = useState(optionsStatus[0])

  const publishAttribute = () => {
    setIsClicked(true)
    setIsActionComplete(false)

    if (
      validate_if_not_empty(attrLbl, "attrLblErrId") &&
      validate_if_not_empty(attrType, "attrTypeErrId") &&
      validate_if_not_empty(attrVal, "attrValErrId") &&
      validate_if_not_empty(status, "attrStatusErrId")
    ) {
     // console.log("valid")
      !isEditForm ?
        createAttribute({
          id: '',
          dateCreated: '',
          dateEdited: '',
          status: status ?? '',
          type: attrType ?? '',
          label: attrLbl ?? '',
          value: attrVal ?? ''
        }, CREATE_NEW_ATTRIBUTE, setIsActionComplete, setIsClicked)
        :
        updateAttributeById(
          attributeById[0]?.data?.id,
          {
            id: attributeById[0]?.data?.id,
            dateCreated: attributeById[0]?.data?.dateCreated,
            dateEdited: '',
            status: status ?? '',
            type: attrType ?? '',
            label: attrLbl ?? '',
            value: attrVal ?? ''
          }, setIsActionComplete, setIsClicked)
    } else {
      setIsClicked(false)
    }
  }

  return (
    <Card
      className={cn(styles.card, className)}
      title="Name & Value"
      classTitle="title-green"
      head={
        <div style={{
          display: 'flex',
          gap: '12px'
        }}>

          <button
            onClick={() => {
              isClicked ? isActionComplete ? publishAttribute() : <></> : publishAttribute()
            }}
            className={cn("button", styles.button)} >
            <span>Save</span>
          </button>
        </div>

      }
    >

      {
        isClicked ?
          isActionComplete ?
            window.location.replace("/products/attributes")
            :
            <LoadingDialog />
          :
          <></>
      }

      {
        loading ?
          <LoadingDialog />
          :
          <></>
      }
      <div className={styles.description}>

        <div className={styles.fieldContainer}>
          <TextInput
            className={styles.field}
            classInput={styles.fieldInput}
            label="Attribute Label"
            name="attributeLabel"
            type="text"
            onChange={(e) => { setAttrLbl(e.target.value) }}
            value={attrLbl}
            required
          />
          <p className={styles.errField} id="attrLblErrId"></p>
        </div>

        <div className={styles.fieldContainer}>
          <Dropdown
            label="Attribute Type"
            value={attrType}
            setValue={setAttrType}
            options={optionsType}
          />
          <p className={styles.errField} id="attrTypeErrId"></p>
        </div>

        <div className={styles.fieldContainer}>
          <TextInput
            className={styles.field}
            classInput={styles.fieldInput}
            label="Attribute Value"
            name="attrVal"
            type="text"
            onChange={(e) => { setAttrVal(e.target.value) }}
            value={attrVal}
            required
          />
          <p className={styles.errField} id="attrValErrId"></p>
        </div>

        <div className={styles.fieldContainer}>
          <Dropdown
            label="Attribute Status"
            value={status}
            setValue={setStatus}
            options={optionsStatus}
          />
          <p className={styles.errField} id="attrStatusErrId"></p>
        </div>

      </div>
    </Card >
  );
};

export default NameAndValue;

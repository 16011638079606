import React, { useContext, useEffect, useState } from "react";
import styles from "./Products.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Form from "../../../components/Form";
import Market from "./Market";
import { GlobalState } from "../../../App";
import PopupScreen from './PopupScreen/PopupScreen';
import Panel from "./Panel";

const Products = () => {

  const [search, setSearch] = useState(null);
  const [selectedCount, setSelectedCount] = useState()

  const {
    showPopup, setShowPopup,
    setSelectedHomeCustomProducts, homeCustomProducts, setShowModalView,
    collSelecProdsList, setCollSelecProdsList
  } = useContext(GlobalState)

  useEffect(() => {
    setShowPopup(false)
    setCollSelecProdsList([])
  }, [])

  useEffect(() => {
    console.log(collSelecProdsList)
    setSelectedCount(collSelecProdsList.length)

  }, [collSelecProdsList])

  return (
    <>
      <Card
        className={styles.card}
        title="Featured Products"
        classTitle={cn("title-purple", styles.title)}
        classCardHead={styles.head}
        head={
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Form
              className={styles.form}
              value={search}
              setValue={setSearch}
              placeholder="Search by SKU/Name"
              type="text"
              name="search"
              icon="search"
            />
            <button className={cn("button", styles.button)}
              onClick={() => {
                // setActiveHomeCustomProducts(homeCustomProducts)
                setSelectedHomeCustomProducts(homeCustomProducts)
                setShowPopup(true)
                setShowModalView(true)
              }}
            >
              <span>Add</span>
            </button>


          </div>
        }
      >
        <div className={styles.products}>
          {
            showPopup ? <PopupScreen /> : <></>
          }
          <div className={styles.wrapper}>
            <Market searchBy={search} />
          </div>
        </div>
      </Card>
      {
        selectedCount !== 0 && <Panel item={collSelecProdsList} />
      }
    </>
  );
};

export default Products;

import React, { useState, useEffect, useContext } from "react";
import styles from "./Market.module.sass";
import Icon from "../../../../components/Icon";
import Row from "./Row";
import { getCollectionProducts, getFeaturedProducts, getProductById, getProducts } from "../../../../Firebase/firebaseDatabase";
import {
  collection,
  getDocs,
  query,
  where,
} from "@firebase/firestore";
import { db } from '../../../../Firebase/firebaseConfig';
import { GlobalState } from "../../../../App";
import LoadingIndicator from "../../../../LoadingScreen/LoadingIndicator";
import LoadingDialog from "../../../../LoadingScreen/LoadingDialog";

const Market = ({ searchBy }) => {

  const [loading, setLoading] = useState(false);

  const [selectedFilters, setSelectedFilters] = useState([]);
  const { homeCustomProducts, setIsClickedSave, setHomeCustomProducts,
    isActionComplete, setIsActionComplete,
    isClickedSave, categories
  } = useContext(GlobalState);

  const handleChange = (id) => {
    if (selectedFilters.includes(id)) {
      setSelectedFilters(selectedFilters.filter((x) => x !== id));
    } else {
      setSelectedFilters((selectedFilters) => [...selectedFilters, id]);
    }
  };

  const listAllFeaturedProducts = async (arrayList) => {
    arrayList.forEach(async (productIds) => {
      await getProductById(productIds?.productId).then(async (data) => {
        console.log("fetched product:", data)
        data.forEach(async (element, index) => {
          categories.forEach((category, i) => {
            if (category.id === element?.data?.category) {
              element.data.category = category?.data?.titleEn
            }
          })
          await getDocs(
            query(collection(db, "product", element?.id, "variation"), where("id", "==", productIds.variationId))
          ).then((doc) => {

            const variationDetails = doc.docs.map((doc) => ({
              data: doc.data(),
              id: doc.id,
            }));
            console.log(variationDetails)
            if (variationDetails.length === 0) {
              setHomeCustomProducts((oldData) => [...oldData, element])
            }
            else {
              variationDetails.forEach(async (variationData, i) => {
                variationData.product = element;
                variationData.data.images = variationData.data.images[0]
                setHomeCustomProducts((oldData) => [...oldData, variationData])
              })

            }
          })
            .catch((e) => {
              // console.log("ORDER ERROR VARIATION", e);
              // setLoading(false);
            });
        });

      }
      )

    });
  }

  useEffect(() => {
    setIsClickedSave(false)
    setIsActionComplete(false)
    setIsClickedSave(false)
    setHomeCustomProducts([])

    const collectionId = window.location.pathname.split("/")[3]
    getCollectionProducts(collectionId).then(list => {
      setLoading(true)
      console.log("featured products list:", list)
      if (list.length !== 0) {
        listAllFeaturedProducts(list[0].productsList)
      }
    })

  }, []);

  const [isPrevActive, setIsPrevActive] = useState(false)
  const [isNextActive, setIsNextActive] = useState(true)
  const [activePageNum, setActivePageNum] = useState(1)
  const [pageCount, setPageCount] = useState(1)

  const page_items_count = 10;
  const [range, setRange] = useState({
    start: 0,
    end: page_items_count
  })

  const [itemsList, setItemsList] = useState([])

  const calculatePageCount = (active_products) => {
    const calculated_page_count = active_products.length / page_items_count;
    const check_if_isInteger = Number.isInteger(calculated_page_count)
    if (check_if_isInteger) {
      setPageCount(calculated_page_count)
      if (calculated_page_count === 1) {
      }
    } else {
      setPageCount(Math.floor(calculated_page_count) + 1)
      if (Math.floor(calculated_page_count) + 1 === 1) {
      }
    }
  }

  useEffect(() => {
    if (searchBy !== null) {
      const active_products = homeCustomProducts.filter(data => {
        if (data.data.sku === searchBy) {
          return data
        } else {
          if (data.product.data.titleEn.toLowerCase().includes(searchBy.toLowerCase())) {
            return data
          }
        }
      })
      console.log(active_products)
      setItemsList(active_products)
      calculatePageCount(active_products);
    } else {
      console.log("home custom products:", homeCustomProducts)
      setItemsList(homeCustomProducts)
      calculatePageCount(homeCustomProducts);
      setLoading(false)
    }
  }, [searchBy, homeCustomProducts]);

  useEffect(() => {


  }, [isActionComplete, isClickedSave]);

  const handleChangePrevClick = () => {

    if (activePageNum > 1) {
      setIsNextActive(true)
      const updatedPageNum = activePageNum - 1;
      setActivePageNum(updatedPageNum)

      if (updatedPageNum === 1) {
        setIsPrevActive(false)
      } else {
        setIsPrevActive(true)
      }

      setRange({
        start: updatedPageNum * page_items_count - page_items_count,
        end: updatedPageNum * page_items_count
      })
    } else {
      setIsPrevActive(false)
    }
  }

  const handleChangeNextClick = () => {

    if (activePageNum < pageCount) {
      setIsPrevActive(true)
      const updatedPageNum = activePageNum + 1;
      setActivePageNum(updatedPageNum);

      if (updatedPageNum === pageCount) {
        setIsNextActive(false)
      } else {
        setIsNextActive(true)
      }

      setRange({
        start: updatedPageNum * page_items_count - page_items_count,
        end: updatedPageNum * page_items_count
      })

    } else {
      setIsNextActive(false)
    }
  }
  return (
    <div className={styles.market}>
      {
        isClickedSave ?
          isActionComplete ? window.location.reload() : <LoadingDialog />
          : <></>
      }
      {
        loading ?
          <div className={styles.loadingContainer}>
            <LoadingIndicator />
          </div>
          : <>
            <div className={styles.table}>
              <div className={styles.row}>
                <div className={styles.col}></div>
                <div className={styles.col}>Title</div>
                <div className={styles.col}>SKU</div>
                <div className={styles.col}>Category</div>
                <div className={styles.col}>Price</div>
                <div className={styles.col}>Qty</div>
                <div className={styles.col}>Status</div>
              </div>
              {
                itemsList.sort((a, b) => {
                  return (
                    new Date(b.data.dateCreated).getTime() - new Date(a.data.dateCreated).getTime()
                  )
                })
                  ?.map((x, index) => {
                    return (
                      (index >= range.start) && (index < range.end) ?
                        <Row
                          item={x}
                          indexVal={index}
                          up={homeCustomProducts.length - index <= 2}
                          value={selectedFilters.includes(x.id)}
                          onChange={() => handleChange(x.id)}
                        /> : <></>
                    )
                  })
              }
            </div>

            {
              pageCount !== 0 && pageCount !== 1 ?
                <div className={styles.foot}>
                  <button
                    style={{
                      opacity: isPrevActive ? 1 : 0.1
                    }}
                    onClick={() => {
                      handleChangePrevClick()
                    }} className={styles.arrow}>
                    <Icon name="arrow-left" size="20" />
                  </button>

                  <button
                    style={{
                      opacity: isNextActive ? 1 : 0.1
                    }}
                    onClick={() => {
                      handleChangeNextClick()
                    }} className={styles.arrow}>
                    <Icon name="arrow-right" size="20" />
                  </button>
                </div>
                : <></>
            }

          </>
      }

    </div>
  );
};

export default Market;

import React from 'react';
import Table from './Table';

const CollectionList = () => {
  return (
    <div>
      <Table />
    </div>
  )
}

export default CollectionList
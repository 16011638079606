import React, { useState, createContext, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./styles/app.sass";

import SignIn from "./screens/SignIn";
import Page from "./components/Page";
import Home from "./screens/Home";
import ProductsDashboard from "./screens/ProductsDashboard";
import NewProduct from "./screens/NewProduct";
import NewAttribute from "./screens/NewAttribute";
import AttributesList from "./screens/AttributesList";
import Orders from "./screens/Orders";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./Firebase/firebaseConfig";
import { getAttributes, getCategories, getCountries, getLocations } from "./Firebase/firebaseDatabase";
import OrderDetails from "./screens/OrderDetails";
import CategoryList from "./screens/CategoryList";
import NewCategory from "./screens/NewCategory";
import SubCategoryList from "./screens/SubCategoryList";
import NewSubCategory from "./screens/NewSubCategory";
import NewSeller from "./screens/NewSeller";
import SellersList from "./screens/SellersList";
import NewCoupon from "./screens/NewCoupon";
import CouponsList from "./screens/CouponsList";
import CreateNotifications from "./screens/CreateNotifications";
import StoreRulesForm from "./screens/StoreRules/StoreRulesForm";
import NewKeyword from "./screens/NewKeyword";
import KeywordsList from "./screens/KeywordsList";
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import LoadingIndicator from "./LoadingScreen/LoadingIndicator";
import HomePageCustomiser from "./screens/HomePageCustomiser";
import Banners from "./screens/Banners";
import CreateBanner from "./screens/CreateBanner";
import CollectionList from "./screens/CollectionList";
import NewCollection from "./screens/NewCollection";
import CollectionProductsList from "./screens/CollectionProductsList";
import * as dotenv from 'dotenv'
dotenv.config()
export const GlobalState = createContext();


function App() {

  //loging check states
  const [isLogin, setIsLogin] = useState(false)
  const [loading, setLoading] = useState(true)

  // to handle all click events and after event completion actions
  const [isClicked, setIsClicked] = useState(false)
  const [isActionComplete, setIsActionComplete] = useState(false)

  //  to handle all nested click events after event completion actions in component's child component
  const [isClickedSave, setIsClickedSave] = useState(false)
  const [isClickActionComplete, setIsClickActionComplete] = useState(false)

  // firebase data handling states for attributes data
  const [attributeList, setAttributeList] = useState([])
  const [attributeById, setAttributeById] = useState()

  // show popup dialog handling state
  const [showModalView, setShowModalView] = useState(false) //popup modal view open and close value handling state
  const [showModalViewCountry, setShowModalViewCountry] = useState(false)
  const [showModalViewLocation, setShowModalViewLocation] = useState(false)
  const [showModalViewShippingMethods, setShowModalViewShippingMethods] = useState(false)
  const [showPopup, setShowPopup] = useState(false)
  const [activeVariationAttr, setActiveVariationAttr] = useState()  //product form attributes select popup radio buttons value, if already selected earlier

  const [homeCustomProducts, setHomeCustomProducts] = useState([])  //home page customiser data list
  const [selectedHomeCustomProducts, setSelectedHomeCustomProducts] = useState([]) //to handle all selected values while selecting from popup
  const [newProductList, setNewProductList] = useState([])   //State to store alll products from firebase but with selected value for home page customiser popup screen checklist

  // collection selected products list checklist handling state
  const [collSelecProdsList, setCollSelecProdsList] = useState([])

  // variations in product form handling states
  const [variationsList, setVariationsList] = useStateWithCallbackLazy([])

  // attributes color size handling states
  const [attrColor, setAttrColor] = useState()
  const [attrSize, setAttrSize] = useState()

  // push images in product form variations handling state
  const [isPushedImages, setIsPushedImages] = useState(false)

  // order status handling states
  const [orderStatus2, setOrderStatus2] = useState();

  // update order confirmation popup handling states
  const [isNewOrderStatus, setIsNewOrderStatus] = useState(false)
  const [isConfirmUpdateStatus, setIsConfirmUpdateStatus] = useState(false)

  // locations list in store rules handling state
  const [locationsList, setLocationsList] = useState([])
  const [activeEditLocation, setActiveEditLocation] = useState()
  const [showLocationPopup, setShowLocationPopup] = useState(false)
  const [deleteLocationIds, setDeleteLocationIds] = useState([])

  // shipping methods list in store rules handling state
  const [shippingMethodList, setShippingMethodList] = useState([])
  const [activeShippingMethod, setActiveShippingMethod] = useState()
  const [showShippingMethodPopup, setShowShippingMethodPopup] = useState(false)
  const [deleteShippingMethodIds, setDeleteShippingMethodIds] = useState([])

  // keywords list in store rules handling states
  const [keywordsList, setKeywordsList] = useState([])
  const [activeEditKeyword, setActiveEditKeyword] = useState()
  const [showKeywordPopup, setShowKeywordPopup] = useState(false)
  const [deleteKeywordIds, setDeleteKeywordIds] = useState([])

  // keywords options list in edit product form handling state
  const [newKeywordOptnsList, setNewKeywordOptnsList] = useState(["Select a keyword"])

  // countries data in store rules from firebase handling state
  const [countriesList, setCountriesList] = useState([])
  const [activeEditCountry, setActiveEditCountry] = useState()
  const [showCountryPopup, setShowCountryPopup] = useState(false)
  const [deleteCountryIds, setDeleteCountryIds] = useState([])

  // product sku handling state
  const [productSku, setProductSku] = useState()

  // locations from firebase handling state
  const [allLocations, setAllLocations] = useState()

  // sellers list data from firebase handling state for seller search in seller list page
  const [sellers, setSellers] = useStateWithCallbackLazy()

  // countries from firebase handling state
  const [allCountries, setAllCountries] = useState()

  // categories list data from firebase handling state
  const [categories, setCategories] = useState()

  // form type of product form handling state (like, add, edit, or detail view)
  const [formTypeProduct, setFormTypeProduct] = useState()

  useEffect(() => {
    // const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        console.log("user: ", uid)
        setIsLogin(true)
        getAttributes(setAttributeList)
        await getLocations().then(list => {
          setAllLocations(list)
        })
        await getCategories().then(list => {
          setCategories(list)
        })
        await getCountries().then(list => {
          setAllCountries(list)
        })

        setLoading(false)
        // ...
      } else {
        // User is signed out
        setLoading(false)

      }
    });

  }, []);

  return (
    <>
      {loading ? <LoadingIndicator /> : <div>
        <Router>
          <Switch>
            <GlobalState.Provider value={{
              isLogin, setIsLogin,
              isClicked, setIsClicked,
              isActionComplete, setIsActionComplete,
              isClickedSave, setIsClickedSave,
              isClickActionComplete, setIsClickActionComplete,

              attributeList,
              attributeById, setAttributeById,
              showPopup, setShowPopup,
              showModalView, setShowModalView,
              showModalViewCountry, setShowModalViewCountry,
              showModalViewLocation, setShowModalViewLocation,
              showModalViewShippingMethods, setShowModalViewShippingMethods,
              activeVariationAttr, setActiveVariationAttr,
              variationsList, setVariationsList,
              homeCustomProducts, setHomeCustomProducts,
              selectedHomeCustomProducts, setSelectedHomeCustomProducts,
              collSelecProdsList, setCollSelecProdsList,
              newProductList, setNewProductList,
              attrColor, setAttrColor,
              attrSize, setAttrSize,
              isPushedImages, setIsPushedImages,
              orderStatus2, setOrderStatus2,

              locationsList, setLocationsList,
              showLocationPopup, setShowLocationPopup,
              activeEditLocation, setActiveEditLocation,
              deleteLocationIds, setDeleteLocationIds,

              shippingMethodList, setShippingMethodList,
              activeShippingMethod, setActiveShippingMethod,
              showShippingMethodPopup, setShowShippingMethodPopup,
              deleteShippingMethodIds, setDeleteShippingMethodIds,

              keywordsList, setKeywordsList,
              activeEditKeyword, setActiveEditKeyword,
              showKeywordPopup, setShowKeywordPopup,
              deleteKeywordIds, setDeleteKeywordIds,

              countriesList, setCountriesList,
              activeEditCountry, setActiveEditCountry,
              showCountryPopup, setShowCountryPopup,
              deleteCountryIds, setDeleteCountryIds,

              productSku, setProductSku,
              formTypeProduct, setFormTypeProduct,
              sellers, setSellers,
              newKeywordOptnsList, setNewKeywordOptnsList,
              isNewOrderStatus, setIsNewOrderStatus,
              isConfirmUpdateStatus, setIsConfirmUpdateStatus,

              allLocations, categories,
              allCountries
            }}>
              {
                isLogin ?
                  <>
                    <Route
                      exact
                      path="/"
                      render={() => (
                        <Page title="Dashboard">
                          <Home />
                        </Page>
                      )}
                    />
                    <Route
                      exact
                      path="/products/dashboard"
                      render={() => (
                        <Page title="Products list">
                          <ProductsDashboard />
                        </Page>
                      )}
                    />
                    <Route
                      exact
                      path="/products/add"
                      render={() => (
                        <Page title="Create product">
                          <NewProduct />
                        </Page>
                      )}
                    />

                    <Route
                      exact
                      path="/products/edit/:id/:id"
                      render={() => (
                        <Page title="Edit product">
                          <NewProduct />
                        </Page>
                      )}

                    />

                    <Route
                      exact
                      path={`/products/details/:id/:id/${decodeURIComponent(window.location.pathname.split("/")[5])}`}
                      render={() => (
                        <Page backButton={true} title={`Product ${decodeURIComponent(window.location.pathname.split("/")[5])} details`}>
                          <NewProduct />
                        </Page>
                      )}
                    />

                    <Route
                      exact
                      path="/products/attributes/add"
                      render={() => (
                        <Page title="Create attribute">
                          <NewAttribute />
                        </Page>
                      )}
                    />

                    <Route
                      exact
                      path="/products/attribute/:id/edit"
                      render={() => (
                        <Page title="Edit attribute">
                          <NewAttribute />
                        </Page>
                      )}
                    />

                    <Route
                      exact
                      path="/products/attributes"
                      render={() => (
                        <Page title="Attribute List">
                          <AttributesList />
                        </Page>
                      )}
                    />

                    <Route
                      exact
                      path="/products/keywords/add"
                      render={() => (
                        <Page title="Create keyword">
                          <NewKeyword />
                        </Page>
                      )}
                    />

                    <Route
                      exact
                      path="/products/keywords/edit/:id"
                      render={() => (
                        <Page title="Edit keyword">
                          <NewKeyword />
                        </Page>
                      )}
                    />

                    <Route
                      exact
                      path="/products/keywords"
                      render={() => (
                        <Page title="Keywords List">
                          <KeywordsList />
                        </Page>
                      )}
                    />

                    <Route
                      exact
                      path="/orders"
                      render={() => (
                        <Page title="Orders">
                          <Orders />
                        </Page>
                      )}
                    />

                    <Route
                      exact
                      path="/order/:id/details"
                      render={() => (
                        <Page backButton={true} title={`Order #${window.location.pathname.split("/")[2]}`}>
                          <OrderDetails />
                        </Page>
                      )}
                    />

                    <Route
                      exact
                      path="/categories"
                      render={() => (
                        <Page title="Categories" createButton={true} path="/category/add">
                          <CategoryList />
                        </Page>
                      )}
                    />

                    <Route
                      exact
                      path="/collections"
                      render={() => (
                        <Page title="Collections" createButton={true} path="/collection/add">
                          <CollectionList />
                        </Page>
                      )}
                    />

                    <Route
                      exact
                      path="/collection/add"
                      render={() => (
                        <Page title="Add collection">
                          <NewCollection />
                        </Page>
                      )}
                    />

                    <Route
                      exact
                      path="/collection/:id/edit"
                      render={() => (
                        <Page title="Edit collection">
                          <NewCollection />
                        </Page>
                      )}
                    />

                    <Route
                      exact
                      path="/collection/:name/:id"
                      render={() => (
                        <Page
                          title={`${decodeURIComponent(window.location.pathname.split("/")[2])} Selected Products`}
                          backButton={true}
                        >
                          <CollectionProductsList />
                        </Page>
                      )}
                    />

                    <Route
                      exact
                      path="/category/add"
                      render={() => (
                        <Page title="Add category">
                          <NewCategory />
                        </Page>
                      )}
                    />

                    <Route
                      exact
                      path="/category/:id/edit"
                      render={() => (
                        <Page title="Edit category">
                          <NewCategory />
                        </Page>
                      )}
                    />

                    <Route
                      exact
                      path="/subCategory/:name/:id/add"
                      render={() => (
                        <Page title={"Add sub-category"}>
                          <NewSubCategory />
                        </Page>
                      )}
                    />

                    <Route
                      exact
                      path="/subCategory/:name/:id/:id/edit"
                      render={() => (
                        <Page title={"Edit sub-category"}>
                          <NewSubCategory />
                        </Page>
                      )}
                    />

                    <Route
                      exact
                      path="/subCategory/:name/:id"
                      render={() => (
                        <Page title={`Sub-category | ${decodeURIComponent(window.location.pathname.split("/")[2])}`}
                          createButton={true}
                          path={`/subCategory/${decodeURIComponent(window.location.pathname.split("/")[2])}/${decodeURIComponent(window.location.pathname.split("/")[3])}/add`}
                          backButton={true}
                        >
                          <SubCategoryList />
                        </Page>
                      )}
                    />

                    <Route
                      exact
                      path="/seller/add"
                      render={() => (
                        <Page title="Create seller">
                          <NewSeller />
                        </Page>
                      )}
                    />

                    <Route
                      exact
                      path="/seller/:id/edit"
                      render={() => (
                        <Page title="Edit seller">
                          <NewSeller />
                        </Page>
                      )}
                    />

                    <Route
                      exact
                      path="/sellers"
                      render={() => (
                        <Page title="Sellers">
                          <SellersList />
                        </Page>
                      )}
                    />

                    <Route
                      exact
                      path="/featuredProducts"
                      render={() => (
                        <Page title="Home page customiser">
                          <HomePageCustomiser />
                        </Page>
                      )}
                    />

                    <Route
                      exact
                      path="/banners"
                      render={() => (
                        <Page title="Home page banners">
                          <Banners />
                        </Page>
                      )}
                    />

                    <Route
                      exact
                      path="/banners/add"
                      render={() => (
                        <Page title="Home page banners">
                          <CreateBanner />
                        </Page>
                      )}
                    />

                    <Route
                      exact
                      path="/coupon/add"
                      render={() => (
                        <Page title="Create coupons">
                          <NewCoupon />
                        </Page>
                      )}
                    />

                    <Route
                      exact
                      path="/coupon/:id/edit"
                      render={() => (
                        <Page title="Edit coupon">
                          <NewCoupon />
                        </Page>
                      )}
                    />

                    <Route
                      exact
                      path="/coupons"
                      render={() => (
                        <Page title="Coupons">
                          <CouponsList />
                        </Page>
                      )}
                    />

                    <Route
                      exact
                      path="/notifications"
                      render={() => (
                        <Page title="Notification">
                          <CreateNotifications />
                        </Page>
                      )}
                    />

                    <Route
                      exact
                      path="/storeRules"
                      render={() => (
                        <Page title="Store Rules">
                          <StoreRulesForm />
                        </Page>
                      )}
                    />
                  </>
                  :
                  <>
                    <Route exact path="/" render={() => <SignIn />} />
                  </>
              }
            </GlobalState.Provider>

          </Switch>
        </Router>
      </div>
      }
    </>
  );
}

export default App;

import React, { useState, useContext, useEffect } from "react";
import cn from "classnames";
import styles from "./ImagesAndCTA.module.sass";
import Card from "../../../components/Card";
import File from "../../../components/File";
import './Variations.css'
import TextInput from "../../../components/TextInput";
import { createCategory, getCategoryById, updateCatgory, uploadFile } from "../../../Firebase/firebaseDatabase";
import { GlobalState } from "../../../App";
import LoadingDialog from "../../../LoadingScreen/LoadingDialog";
import { validate_if_not_empty } from "../../../utils/extensions";
// import LoadingIndicator from "../../../LoadingScreen/LoadingIndicator";
import { useHistory } from "react-router-dom";
import Icon from "../../../components/Icon";

const ImagesAndCTA = ({ className }) => {

  let history = useHistory();


  const [titleEn, setTitleEn] = useState()
  const [titleAr, setTitleAr] = useState()
  const [image, setImage] = useState()

  const [isEditForm, setIsEditForm] = useState(false)
  const [categoryId, setCategoryId] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [dateCreated, setDateCreated] = useState()
  const categoryObj = {
    id: categoryId ?? '',
    dateCreated: dateCreated ?? '',
    dateEdited: '',
    titleAr: titleAr ?? '',
    titleEn: titleEn ?? '',
    status: '',
    image: image ?? ''
  }

  const {
    isClicked, setIsClicked,
    isActionComplete, setIsActionComplete
  } = useContext(GlobalState)

  const handleChangeFiles = () => {
    const id = document.getElementById('categoryCoverImg');
    // console.log("files: ", id.files[0])
    setImage(id.files[0])
  }

  const getCategoryDetails = async () => {
    try {
      const details = await getCategoryById(categoryId)
      // console.log("category details: ", details)
      if (details !== undefined && details !== '') {
        setTitleEn(details[0]?.data?.titleEn)
        setTitleAr(details[0]?.data?.titleAr)
        setImage(details[0]?.data?.image)
        setDateCreated(details[0]?.data?.dateCreated)
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setIsLoading(true)
    const id = window.location.pathname.split("/")[2]
    // console.log("path: ", id)
    if (id !== "add") {
      setIsEditForm(true);
      setCategoryId(id)
      getCategoryDetails()
    }
  }, [categoryId])

  useEffect(() => {
    setIsClicked(false)
    setIsActionComplete(false)
    setIsLoading(false)
  }, []);

  useEffect(() => {
    // setIsActionComplete(false)
  }, [isClicked, isActionComplete]);



  return (
    <Card
      className={cn(styles.card, className)}
      title="Category Details"
      classTitle="title-green"
      head={
        <div style={{

          display: 'flex',
          gap: '12px'
        }}>

          <button className={cn("button", styles.backButton)} onClick={() => history.goBack()} >
            <Icon name="arrow-left" size={20} fill="black" />
            <span>Back</span>
          </button>

          <button
            onClick={async () => {
              setIsClicked(true)
              if (!isEditForm) {
                if (
                  validate_if_not_empty(titleEn, "categoryTitleEnErrId") &&
                  validate_if_not_empty(titleAr, "categoryTitleArErrId") &&
                  validate_if_not_empty(image, "categoryCoverImgErrId")
                ) {
                  if (image !== '' && image !== undefined) {
                    const imageUrl = await uploadFile(image, "category")
                    categoryObj.image = imageUrl;
                  }
                  await createCategory(categoryObj, setIsClicked, setIsActionComplete)
                } else {
                  setIsClicked(false)
                }

              } else {
                if (categoryObj?.image?.toString().includes("https")) {
                  updateCatgory(categoryObj, setIsClicked, setIsActionComplete)
                } else {
                  const imageUrl = await uploadFile(image, "category")
                  categoryObj.image = imageUrl;
                  await updateCatgory(categoryObj, setIsClicked, setIsActionComplete)
                }

              }

            }}
            className={cn("button", styles.button)} >
            <span>Save</span>
          </button>
        </div>

      }
    >
      {
        isClicked ?
          !isActionComplete ?
            <LoadingDialog /> : window.location.href = "/categories"
          : <></>
      }

      {
        isLoading ? <LoadingDialog /> : <></>
      }


      <div className={styles.description}>

        <div className={styles.fieldset}>

          <div className={`${styles.fieldContainer}`}>
            <TextInput
              className={styles.field}
              classInput={styles.fieldInput}
              label="Category Title (English)"
              name="categoryTitleEn"
              type="text"
              onChange={(e) => { setTitleEn(e.target.value) }}
              value={titleEn}
            />
            <p className={styles.errField} id="categoryTitleEnErrId"></p>

          </div>
        </div>

        <div className={styles.fieldset}>

          <div className={`${styles.fieldContainer}`}>
            <TextInput
              className={styles.field}
              classInput={styles.fieldInput}
              label="Category Title (Arabic)"
              name="categoryTitleEn"
              type="text"
              onChange={(e) => { setTitleAr(e.target.value) }}
              value={titleAr}
            />
            <p className={styles.errField} id="categoryTitleArErrId"></p>
          </div>
        </div>

        <div className={styles.image_section}>

          <div className={styles.files}>
            <File
              classInput={styles.imageInput}
              className={styles.imageField}
              classInputWraper={styles.imageFieldWrapper}
              title={
                image?.name !== '' && image?.name !== undefined ?
                  image?.name
                  : "Click or drop image"
              }
              id={`categoryCoverImg`}
              label="Cover image"
              onChange={() => handleChangeFiles()}
              url={isEditForm ? image : ''}
            />
          </div>
          <p className={styles.errField} id="categoryCoverImgErrId"></p>
        </div>

      </div>
    </Card>
  );
};

export default ImagesAndCTA;

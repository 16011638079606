import React, { useState, useEffect, useContext } from "react";
import styles from "./Market.module.sass";
// import cn from "classnames";
import Row from "./Row";
import { getOrders } from "../../../Firebase/firebaseDatabase";
import { GlobalState } from "../../../App";
import LoadingIndicator from "../../../LoadingScreen/LoadingIndicator";

const Market = () => {

  const [selectedFilters, setSelectedFilters] = useState([]);

  const { attributeList,
    setIsActionComplete,
  } = useContext(GlobalState)

  const handleChange = (id) => {
    if (selectedFilters.includes(id)) {
      setSelectedFilters(selectedFilters.filter((x) => x !== id));
    } else {
      setSelectedFilters((selectedFilters) => [...selectedFilters, id]);
    }
  };

  const [loading, setLoading] = useState(true)

  const [allOrders, setAllOrders] = useState()

  const getAllOrders = async () => {
    try {
      const list = await getOrders()
      setAllOrders(list)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(true)
    setIsActionComplete(false)
    getAllOrders()
  }, []);

  useEffect(() => {
    if (allOrders?.length !== 0) {
    }
  }, [allOrders])

  return (
    <div className={styles.market}>
      {
        loading !== true ?
          <div className={styles.table}>
            <div className={styles.row}>
              <div className={styles.col}>Customer name</div>
              <div className={styles.col}>Order ID</div>
              <div className={styles.col}>Total</div>
              <div className={styles.col}>Status</div>
              <div className={styles.col}>Date created</div>
              <div className={styles.col}>Sales channel</div>
            </div>
            {allOrders?.map((x, index) => {
              console.log(x.data.id)
              return (
                x.data.id !== undefined &&
                <Row
                  item={x.data}
                  key={index}
                  value={selectedFilters.includes(x.id)}
                  onChange={() => handleChange(x.id)}
                  up={attributeList.length - index <= 2}
                />
              )
            }
            )}
          </div>
          :
          <LoadingIndicator />
      }


    </div>
  );
};

export default Market;

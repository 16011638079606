import React, { useContext, useState, useEffect } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import Checkbox from "../../../../../../components/Checkbox";
import { GlobalState } from "../../../../../../App";

const Row = ({ item, value, onChange, up, indexVal }) => {

  const { selectedHomeCustomProducts, setSelectedHomeCustomProducts, newProductList } = useContext(GlobalState)

  const [visibleActions, setVisibleActions] = useState(false);

  const handleClick = async (clickedVal, i) => {

    if (selectedHomeCustomProducts.length !== 0) {

      const checkId = obj => obj.id === clickedVal.id;
      const existValue = selectedHomeCustomProducts.some(checkId)

      if (!existValue) {
        newProductList[i].selected = !newProductList[i].selected;
        setSelectedHomeCustomProducts((oldData) => [...oldData, clickedVal])
      } else {
        newProductList[i].selected = !newProductList[i].selected;
        const filterList = await selectedHomeCustomProducts?.filter((data) => {
          if (data?.id !== clickedVal?.id) {
            return data
          }
        })
        setSelectedHomeCustomProducts([])
        filterList.forEach(element => {
          setSelectedHomeCustomProducts((oldData) => [...oldData, element])
        });
      }
    } else {
      newProductList[i].selected = !newProductList[i].selected;
      setSelectedHomeCustomProducts((oldData) => [...oldData, clickedVal])
    }


  }


  const [showPlaceHolder, setShowPlaceHolder] = useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setShowPlaceHolder(false)
    }, 100);
  }, [])

  useEffect(() => {

    if (item?.selected === undefined) {
      item.selected = false
    }
  }, [item]);

  React.useEffect(() => {

  }, [selectedHomeCustomProducts])


  return (
    <>
      <div
        key={indexVal}
        className={styles.row}
        onMouseLeave={() => setVisibleActions(false)}
      >
        <div className={styles.col}>
          <Checkbox
            id={item?.id}
            onChange={
              () => {
                handleClick(item, indexVal)
              }
            }
            value={item?.selected}
          />
        </div>
        <div className={styles.col}>
          <div
            className={styles.item}
          >
            <div className={styles.preview}>
              {
                showPlaceHolder ?
                  <img
                    srcSet={`/images/content/placeholderImg.png 2x`}
                    src={'/images/content/placeholderImg.png'}
                    alt="Product"
                  />
                  :
                  <img
                    srcSet={`${item.data.images} 2x`}
                    src={item.data.images}
                    alt="Product"
                  />
              }

            </div>
            <div className={styles.details}>
              <div className={styles.product}>{item?.product ? item?.product?.data?.titleEn : item.data.titleEn}</div>
              <div className={styles.product}>{item?.product ? item?.product?.data?.titleAr : item.data.titleAr}</div>
            </div>
          </div>
        </div>

        <div className={styles.col}>
          <div className={styles.label}>Sku</div>
          <div className={cn(styles.sales, styles.skuContainer)}>
            <div className={styles.number}>{item?.product ? item.data.sku : item.data.sku}</div>
          </div>

        </div>

        <div className={styles.col}>
          <div className={styles.label}>Category</div>
          <div className={styles.sales}>
            <div className={styles.number}>{item?.product ? item?.product?.data?.category : item.data.category}</div>
          </div>
        </div>

        <div className={styles.col}>
          <div className={styles.label}>Price</div>
          <div className={styles.sales}>
            <div className={styles.number}>{item?.product ? item?.product?.data?.price : item.data.price}</div>
          </div>
        </div>


        <div className={styles.col}>
          <div className={styles.label}>Qty</div>
          <div className={styles.sales}>
            <div className={styles.number}>{item.data.quantity ? item.data.quantity : 0}</div>
          </div>
        </div>


        <div className={styles.col}>
          <div className={styles.label}>Status</div>
          <div className={styles.sales}>
            {item.data.status === "On Sale" ? (
              <div className={cn("status-green", styles.status)}>{item.data.status}</div>
            ) : item.data.status === "Archived" ? (
              <div className={cn("status-yellow", styles.status)}>{item.data.status}</div>
            ) : item.data.status === "Out Of Stock" && (
              <div className={cn("status-red", styles.status)}>{item.data.status}</div>
            )
            }
          </div>
        </div>

      </div>

    </>
  );
};

export default Row;

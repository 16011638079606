import React from "react";
// import cn from "classnames";
import styles from "./CouponsList.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import Coupons from "./Coupons";

const CouponsList = () => {
  return (
    <>
      <div className={styles.section}>
        <Coupons />
      </div>
      {/* <TooltipGlodal /> */}
    </>
  );
};

export default CouponsList;

import React from 'react';
import ImagesAndCTA from './ImagesAndCTA';
import styles from "./NewCategory.module.sass";

const NewCategory = () => {
  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
         
          <ImagesAndCTA className={styles.card} />
        
        </div>
      </div>
    </>
  )
}

export default NewCategory
import React, { useState, useEffect, useContext } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./DropdownKeywords.module.sass";
import Tooltip from "../Tooltip";
import Checkbox from "../Checkbox";
import Icon from "../Icon";
import { distinct } from "../../utils/extensions";
import { GlobalState } from "../../App";

const DropdownKeywords = ({
  className,
  classDropdownHead,
  classDropdownLabel,
  value,
  setValue,
  options,
  label,
  tooltip,
  small,
  upBody,
  handlechangeVal,
  placeholder,
  onClick,
  isEditForm,
  ...props
}) => {

  const [visible, setVisible] = useState(false);
  const { newKeywordOptnsList } = useContext(GlobalState)

  const handleClick = async (clickedVal, i) => {
    if (i === 0) {
      setValue("")
    } else {
      for (let index = 0; index < value.length; index++) {
        if (value[index]?.id === clickedVal[0]?.id) {
          isEditForm ?
            newKeywordOptnsList[i].selected = !newKeywordOptnsList[i].selected :  options[i].selected = !options[i].selected 
          if (value.length === 1) {
            setValue(["Select a keyword"])
            return 1
          } else {
            setValue(value.filter(data => {
              if (data?.id !== clickedVal[0]?.id) {
                return { id: data?.id, title: data?.title }
              }
            }))
            return 1
          }
        }
      }

      for (let index = 0; index < value.length; index++) {
        if (value[index]?.id !== clickedVal[0]?.id) {
          isEditForm ?
            newKeywordOptnsList[i].selected = !newKeywordOptnsList[i].selected : options[i].selected = !options[i].selected 
          if (value[0] === "Select a keyword") {
            setValue([{ id: clickedVal[0]?.id, title: clickedVal[0]?.title }])
            return 1
          } else {
            setValue((oldData) => [...oldData, { id: clickedVal[0]?.id, title: clickedVal[0]?.title }])
            return 1
          }
        }
      }

    }
  };


  useEffect(() => {

  }, [value, newKeywordOptnsList]);


  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      {label && (
        <div className={cn(styles.label, classDropdownLabel)}>
          {label}{" "}
          {tooltip && (
            <Tooltip
              className={styles.tooltip}
              title={tooltip}
              icon="info"
              place="right"
            />
          )}
        </div>
      )}
      <div
        className={cn(
          styles.dropdown,
          className,
          { [styles.small]: small },
          {
            [styles.active]: visible,
          }
        )}
      >
        <div
          className={cn(styles.head, classDropdownHead)}
          onClick={() => setVisible(!visible)}
        >
          <div className={styles.selection}
          // data-id={value[0]?.id}
          >
            {
              value?.map((keyword, i) => {
                return (
                  value[0] === "Select a keyword" ? value[0] :
                    <div className={styles.selectedKeywordContainer}
                    >
                      <p className={styles.selectedKeyword} >{keyword?.title}</p>
                    </div>
                )
              })
            }
          </div>


        </div>
        <div className={cn(styles.body, { [styles.bodyUp]: upBody })}>

          {[...new Map((!isEditForm ? options : newKeywordOptnsList).map(item =>
            [item['id'], item])).values()].map((optionValue, index) => {
              return (
                <div
                  className={cn(styles.option, {
                    [styles.selectioned]: optionValue?.title === value,
                  })}
                  key={index}
                  id={optionValue?.id}
                  placeholder={placeholder}
                  {...props}
                >
                  <div style={{ display: 'flex', gap: '5px' }}>
                    {index !== 0 ?
                      <Checkbox
                        id={optionValue?.id}
                        onChange={
                          () => {
                            index === 0 ?
                              <></> :
                              optionValue.title ?
                                handleClick([{ id: optionValue?.id, title: optionValue?.title, selected: optionValue?.selected }], index) : handleClick(optionValue, index)
                          }
                        }
                        value={optionValue?.selected === undefined ? false : optionValue?.selected}
                      /> : <></>
                    }

                    {optionValue?.title ? optionValue?.title : optionValue}
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default DropdownKeywords;

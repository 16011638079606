import React from 'react';
import SellerDetails from './SellerDetails';
import styles from './NewSeller.module.sass';

const NewSeller = () => {
  return (
    <div className={styles.row}>
        <div className={styles.col}>
          <SellerDetails className={styles.card} />
          </div></div>
  )
}

export default NewSeller
import React, { useContext, useState, useEffect } from 'react';
import './ConfirmationPopup.css';
import cn from "classnames";
import styles from './ConfirmationPopup.module.sass';
// import ThumbsUp from '../GetQuote/FormSection/Images/thumbsUp.png';
import Modal from '@mui/material/Modal';
import Card from '../../../components/Card';
import { GlobalState } from '../../../App';
import TextInput from '../../../components/TextInput';



const ConfirmationPopup = () => {
  const { setIsConfirmUpdateStatus } = useContext(GlobalState)

  useEffect(() => {

    setTimeout(() => {
      setIsConfirmUpdateStatus(false)
    }, 2000);

  }, []);
  return (
    <Modal
      disableAutoFocus={true}
      open={true}
      className='dialog-wrapper'
      sx={{
        outline: '0 !important',
        border: 'none !important'
      }}
    >
      <Card
        className={cn(styles.card)}
        // title="Are you sure, you want to update the status?"
        // classTitle={cn("title-green", styles.title)}
        classCardHead={styles.cardHead}
      >
        <div className={styles.buttonsContainer}>
          <h1 className={styles.confirmText}>Status updated successfuly!</h1>
          {/* <button
            onClick={() => {
              setIsNewOrderStatus(false)
              setIsClicked(false)
            }}
            className={cn("button", styles.button)} >
            <span>No</span>
          </button>
          <button
            onClick={() => {
              setIsNewOrderStatus(false)
              setIsClicked(true)
              setIsConfirmUpdateStatus(true)
            }}
            className={cn("button", styles.button)} >
            <span>Yes</span>
          </button> */}
        </div>

      </Card>

    </Modal>

  )
}

export default ConfirmationPopup
import React, { useState, useContext, useEffect } from "react";
import cn from "classnames";
import styles from "./CouponForm.module.sass";
import Card from "../../../components/Card";
import TextInput from "../../../components/TextInput";
import Dropdown from "../../../components/Dropdown";
import {
  createCoupon, getCategories, getCouponById,
  getSellers, getSubCategories, updateCouponById
} from "../../../Firebase/firebaseDatabase";
import LoadingDialog from "../../../LoadingScreen/LoadingDialog";
import { GlobalState } from "../../../App";
import LoadingIndicator from "../../../LoadingScreen/LoadingIndicator";
import { FIXED_PRICE, PERCENTAGE } from "../../../utils/constants";
import { validate_if_not_empty } from "../../../utils/extensions";
import DropdownCategory from "../../../components/DropdownCategory";
import SearchDropdown from "../../../components/SearchDropdown";
import DropdownSubCategory from "../../../components/DropdownSubCategory";
import Icon from "../../../components/Icon";
import { useHistory } from "react-router-dom";
import TooltipGlodal from "../../../components/TooltipGlodal";


const CouponForm = ({ className }) => {

  const { isClicked, setIsClicked,
    isActionComplete, setIsActionComplete,
  } = useContext(GlobalState);

  let history = useHistory();

  const [isEditForm, setIsEditForm] = useState(false)
  const [loading, setLoading] = useState(false)

  const optionsStatus = ["Select status", "Active", "Expired"]
  const optionsShippingMethod = ["Select shipping method", "yes", "No"]
  const optionsConditions = ["Select condition", "Fixed Price", "Percentage"]
  const [optionCategories, setOptionCategories] = useState(["Select category"]);
  const [optionSubCategories, setOptionSubCategories] = useState(["Select Sub Category"]);
  const [optionsSeller, setOptionsSeller] = useState(["Select seller"]);

  const [couponId, setCoupnId] = useState()
  const [couponCode, setCouponCode] = useState()
  const [couponCondition, setCouponCondition] = useState(optionsConditions[0])
  const [couponStatus, setCouponStatus] = useState(optionsStatus[0])
  const [includesShipping, setIncludesShipping] = useState(optionsShippingMethod[0])
  const [couponCategories, setCouponCatgories] = useState(optionCategories[0])
  const [couponSubCategories, setCouponSubCatgories] = useState(optionSubCategories[0])
  const [couponMaxDiscount, setCouponMaxDiscount] = useState()
  const [seller, setSeller] = useState(optionsSeller[0])
  const [couponExpiry, setCouponExpiry] = useState()
  const [discountAmount, setDiscountAmount] = useState()
  const [discountPercentage, setDiscountPercentage] = useState()

  const [dateCreated, setDateCreated] = useState()


  const CouponDetailsObj = {
    couponId: couponId ?? '',
    code: couponCode ?? '',
    condition: couponCondition ?? '',
    status: couponStatus ?? '',
    includesShipping: includesShipping ?? '',
    categories: couponCategories ?? '',
    maximumDiscount: couponMaxDiscount ?? '',
    seller: seller ?? '',
    expiryDate: couponExpiry ?? '',
    dateEdited: '',
    dateCreated: dateCreated ?? '',
    discountAmount: discountAmount ?? '',
    discountPercentage: discountPercentage ?? ''
  }

  //// console.log(CouponDetailsObj)

  const getCouponData = async (id) => {
    await getCouponById(id).then(async (data) => {
      // console.log(TAG, data)
      const coupon = data[0].data;
      setCoupnId(coupon.couponId);
      setCouponCode(coupon.code);
      setCouponCondition(coupon.condition);
      setCouponStatus(coupon.status);
      setIncludesShipping(coupon.includesShipping);
      setCouponCatgories(coupon.categories);
      setCouponMaxDiscount(coupon.maximumDiscount);
      setSeller(coupon.seller);
      setCouponExpiry(coupon.expiryDate);
      setDiscountAmount(coupon.discountAmount);
      setDiscountPercentage(coupon.discountPercentage);
      setDateCreated(coupon.dateCreated);

      setLoading(false)
    })
  }

  const TAG = "coupon component";
  const [allCategories, setAllCategories] = useState();
  const [allSellers, setAllSellers] = useState();

  const getCategory = async () => {
    const list = await getCategories();
    //// console.log("categories list in product form: ", list)
    setAllCategories(list);
  };

  const getAllSellers = async () => {
    await getSellers().then((list) => {
      // console.log("list of seller options", list)
      setAllSellers(list);
    })
  };

  useEffect(() => {
    setIsActionComplete(false)
    getCategory();
    getAllSellers();
    // console.log(TAG, window.location.pathname.split("/")[2])
    if (window.location.pathname.split("/")[2] !== 'add') {
      setIsEditForm(true)
      setLoading(true)
      const couponId = window.location.pathname.split("/")[2];
      getCouponData(couponId)
    }
  }, [])

  useEffect(() => {
    if (allSellers?.length !== 0 && allSellers !== undefined) {
      // console.log("all sellers: ", allSellers)
      allSellers?.filter((element) => {
        // console.log("all sellers...............", element)
        setOptionsSeller((oldData) => [...oldData, element?.data])
      })
    }

  }, [allSellers])

  useEffect(() => {
    if (allCategories?.length !== 0) {
      allCategories?.filter((data) =>
        setOptionCategories((oldData) => [...oldData, data?.data])
      );
    }
  }, [allCategories]);

  const getSubCategory = async (categoryId) => {
    const list = await getSubCategories(categoryId);
    list.filter((data) =>
      setOptionSubCategories((oldData) => [...oldData, data?.data])
    );
  };

  useEffect(() => {
    if (validate_if_not_empty(couponCategories)) {
      if (allCategories?.length !== 0) {
        allCategories?.filter((data) => {
          if (data?.data?.id === couponCategories[0]?.id) {
            setOptionSubCategories(["Select Sub Category"]);
            getSubCategory(couponCategories[0]?.id);
          }
        });
      }
    }
  }, [couponCategories]);

  useEffect(() => {

  }, [isActionComplete, isClicked])

  useEffect(() => {
    setDiscountAmount('');
    setDiscountPercentage('')
  }, [couponCondition])

  const validateAllFields = () => {
    if (
      validate_if_not_empty(couponCode, "couponCodeErrId") &&
      validate_if_not_empty(couponCondition, "couponConditionErrId") &&
      (couponCondition === FIXED_PRICE ?
        validate_if_not_empty(discountAmount, "couponDiscountAmountSARErrId") :
        validate_if_not_empty(discountPercentage, "couponDiscountAmountPercErrId")) &&
      validate_if_not_empty(couponStatus, "couponStatusErrId") &&
      validate_if_not_empty(includesShipping, "couponShippingErrId") &&
      validate_if_not_empty(couponExpiry, "couponExpiryErrId")
    ) {
      return true
    } else {
      return false
    }
  }

  const publishCoupon = async () => {

    // console.log(CouponDetailsObj)
    const isValidAllFields = validateAllFields()
    if (isValidAllFields) {
      setIsClicked(true)
      setIsActionComplete(false)
      createCoupon(CouponDetailsObj, setIsClicked, setIsActionComplete)
    }

  }

  const updateCoupon = () => {

    // console.log(TAG, CouponDetailsObj)
    const isValidAllFields = validateAllFields()
    if (isValidAllFields) {
      setIsClicked(true)
      updateCouponById(CouponDetailsObj, setIsActionComplete, setIsClicked)
    }
  }

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Name & Value"
        classTitle="title-green"
        head={
          <div style={{
            display: 'flex',
            gap: '12px'
          }}>

            <button className={cn("button", styles.backButton)} onClick={() => history.goBack()} >
              <Icon name="arrow-left" size={20} fill="black" />
              <span>Back</span>
            </button>

            <button
              onClick={() => {
                isEditForm ?
                  updateCoupon()
                  :
                  publishCoupon()
              }}
              className={cn("button", styles.button)} >
              <span>Save</span>
            </button>
          </div>

        }
      >

        {
          isClicked ?
            isActionComplete ?
              window.location.href = "/coupons"
              :
              <LoadingDialog />
            :
            <></>
        }

        {loading ?
          <LoadingIndicator />
          :
          <div className={styles.description}>

            {isEditForm ?
              <div className={styles.fieldContainer}>
                <TextInput
                  className={styles.field}
                  classInput={styles.fieldInput}
                  label="Coupon Id"
                  name="couponId"
                  type="text"
                  readOnly={true}
                  onChange={(e) => { setCoupnId(e.target.value) }}
                  value={couponId}
                  required
                />
                <p className={styles.errField} id="couponIdErrId"></p>
              </div>
              : <></>}


            <div className={styles.fieldContainer}>
              <TextInput
                className={styles.field}
                classInput={styles.fieldInput}
                label="Code"
                name="couponCode"
                type="text"
                onChange={(e) => { setCouponCode(e.target.value) }}
                value={couponCode}
                required
              />
              <p className={styles.errField} id="couponCodeErrId"></p>
            </div>

            <div className={styles.fieldContainer}>
              <Dropdown
                label="Condition"
                value={couponCondition}
                setValue={setCouponCondition}
                options={optionsConditions}
              />
              <p className={styles.errField} id="couponConditionErrId"></p>
            </div>

            {couponCondition === FIXED_PRICE ?
              <div className={styles.fieldContainer}>
                <TextInput
                  className={styles.field}
                  classInput={styles.fieldInput}
                  label="Discount Amount (in SAR)"
                  name="couponAmount"
                  // type="number"
                  onChange={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')
                    setDiscountAmount(e.target.value)
                  }}
                  value={discountAmount}
                  required
                />
                <p className={styles.errField} id="couponDiscountAmountSARErrId"></p>
              </div>
              :
              couponCondition === PERCENTAGE ?
                <div className={styles.fieldContainer}>
                  <TextInput
                    className={styles.field}
                    classInput={styles.fieldInput}
                    label="Discount Percentage (in %)"
                    name="couponAmountPercent"
                    // type="number"
                    onChange={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')
                      setDiscountPercentage(e.target.value)
                    }}
                    value={discountPercentage}
                    required
                  />
                  <p className={styles.errField} id="couponDiscountAmountPercErrId"></p>
                </div> : <></>
            }


            <div className={styles.fieldContainer}>
              <Dropdown
                label="Status"
                value={couponStatus}
                setValue={setCouponStatus}
                options={optionsStatus}
              />
              <p className={styles.errField} id="couponStatusErrId"></p>
            </div>

            <div className={styles.fieldContainer}>
              <Dropdown
                label="Shipping"
                value={includesShipping}
                setValue={setIncludesShipping}
                options={optionsShippingMethod}
              />
              <p className={styles.errField} id="couponShippingErrId"></p>
            </div>

            <div className={styles.fieldset}>
              <div className={`${styles.fieldContainer}  ${styles.smallFields}`}>
                <DropdownCategory
                  className={styles.smallDropdownField}
                  classDropdownLabel={styles.smallDropdownlabel}
                  label="Category (optional)"
                  value={couponCategories}
                  setValue={setCouponCatgories}
                  options={optionCategories}
                />
                <p className={styles.errField} id="couponCategoriesErrId"></p>
              </div>

              {validate_if_not_empty(couponCategories) ? (
                <div className={`${styles.fieldContainer}  ${styles.smallFields}`}>
                  <DropdownSubCategory
                    className={styles.smallDropdownField}
                    classDropdownLabel={styles.smallDropdownlabel}
                    label="Sub Category (optional)"
                    value={couponSubCategories}
                    setValue={setCouponSubCatgories}
                    options={optionSubCategories}
                  />
                  <p className={styles.errField} id="productSubCategoryErrId"></p>
                </div>
              ) : (
                <></>
              )}
            </div>
            {/* <div className={styles.fieldContainer}>
            <DropdownCategory
              label="Categories (Optional)"
              value={couponCategories}
              setValue={setCouponCatgories}
              options={optionCategories}
            />
            <p className={styles.errField} id="couponCategoriesErrId"></p>
          </div> */}

            <div className={styles.fieldContainer}>
              <TextInput
                className={styles.field}
                classInput={styles.fieldInput}
                label="Maximum Discount (Optional)"
                name="couponMaxDiscount"
                onChange={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')
                  setCouponMaxDiscount(e.target.value)
                }}
                value={couponMaxDiscount}
                required
              />
              <p className={styles.errField} id="couponMaxDiscountErrId"></p>
            </div>

            <div className={styles.fieldContainer}>
              <SearchDropdown
                className={styles.field}
                label="Seller (Optional)"
                value={seller}
                setValue={setSeller}
                options={optionsSeller}
              // onClick={() => { handleChangeSellerDropdown() }}
              />
              <p className={styles.errField} id="couponSellerErrId"></p>
            </div>

            <div className={styles.fieldContainer}>
              <TextInput
                className={styles.field}
                classInput={styles.fieldInput}
                label="Expiry Date"
                name="couponExpiryDate"
                onChange={(e) => { setCouponExpiry(e.target.value) }}
                value={couponExpiry}
                required
                type="date"
                id="date"
              />
              <p className={styles.errField} id="couponExpiryErrId"></p>
            </div>

          </div>
        }

      </Card >
      <TooltipGlodal />
    </>
  );
};

export default CouponForm;

import React, { useContext, useEffect, useState } from "react";
import Card from "../../../../../components/Card";
import styles from "./Market.module.sass";
import cn from "classnames";
// import cn from "classnames";
// import Checkbox from "../../../../components/Checkbox";
// import Icon from "../../../../components/Icon";
import Row from "./Row";
import { GlobalState } from '../../../../../App'

const Market = () => {

  const { activeVariationAttr,
    variationsList, setIsClickActionComplete, isClickedSave, setIsClickedSave,
    setShowPopup, attributeList, attrColor, setAttrColor,
    attrSize, setAttrSize, productSku } = useContext(GlobalState)


  useEffect(() => {
    // console.log("active variation attribute object: ", activeVariationAttr)
    if (activeVariationAttr) {
      for (let index = 0; index < activeVariationAttr.attrList.length; index++) {
        if (activeVariationAttr.attrList[index]?.data?.type === "Color") {
          setAttrColor(activeVariationAttr.attrList[index])
        }

        if (activeVariationAttr.attrList[index]?.data?.type === "Size") {
          setAttrSize(activeVariationAttr.attrList[index])
        }

      }
    }
  }, [activeVariationAttr])

  useEffect(() => {
    // console.log("attribute list in form: ", attributeList)
  }, [attributeList])

  useEffect(() => {

    if (isClickedSave) {
      for (var i = 0; i < variationsList.length; i++) {

        if (variationsList[i]?.id === activeVariationAttr?.id) {

          // console.log("attr size updated: ", attrSize)
          // console.log("attr color updated: ", attrColor)

          variationsList[i]['attributes'] = []

          if (attrColor?.id !== undefined) {
            variationsList[i]?.attributes.push(attrColor)
            setAttrColor()
            // variationsList[i]?.attributes.push({id:attrColor?.id, title:attrColor?.value})
            // variationsList[i].sku = productSku + "-" + attrColor?.value.toUpperCase()
          }
          if (attrSize?.id !== undefined) {
            variationsList[i]?.attributes.push(attrSize)
            setAttrSize()
            // variationsList[i]?.attributes.push({id:attrSize?.id, title:attrSize?.value})
            // variationsList[i].sku = productSku + "-" + attrColor?.value.toUpperCase() + "-" + attrSize?.value.toUpperCase()
          }
          setIsClickedSave(false)
          setIsClickActionComplete(true)
          setShowPopup(false)
        }
      }

    }

  }, [isClickedSave])

  return (
    <>
      <Card
        className={cn(styles.card)}
        title="Color"
        classTitle={styles.cardTitle}
        classCardHead={styles.cardHead}
      >
        <div className={styles.market}>
          <div className={styles.table}>
            <div className={styles.row}>
              <div className={styles.col}>Select</div>
              <div className={styles.col}>Label</div>
              <div className={styles.col}>Value</div>
            </div>
            {/* <div className={styles.table}> */}
            {attributeList
              .filter(data => data?.data?.type === "Color")
              .map((x, index) => (
                <Row
                  item={x.data}
                  key={index}
                  up={attributeList.length - index <= 2}
                  // value={colorId}
                  onChange={() => {
                    // setColorId(x?.data?.id);
                    setAttrColor(x)
                    // setAttrColor({ id: x?.data?.id, type: x?.data?.type , value: x?.data?.label})
                    // handleChange(x?.data?.type)
                  }}
                  value={
                    // handleAttrList?.includes(x.id)
                    x?.data?.id === attrColor?.id
                  }
                  // onChange={() => {
                  //   handleChange(x?.data?.id)
                  // }}
                  // disabled={isDisableSelectionColor}
                  name="Color"
                />
              ))}
            {/* </div> */}
          </div>

        </div>
      </Card>

      <Card
        className={cn(styles.card)}
        title="Size"
        classCardHead={styles.cardHead}
      >
        <div className={styles.market}>
          <div className={styles.table}>
            <div className={styles.row}>
              <div className={styles.col}>Select</div>
              <div className={styles.col}>Label</div>
              <div className={styles.col}>Value</div>
            </div>
            {attributeList
              .filter(data => data?.data?.type === "Size")
              .map((x, index) => (
                <Row
                  item={x?.data}
                  key={index}
                  up={attributeList.length - index <= 2}
                  value={
                    x?.data?.id === attrSize?.id
                    // handleAttrList.length !== 0 ?
                    //   handleAttrList?.includes(x.id) : false
                  }
                  onChange={() => {
                    setAttrSize(x)
                    // setAttrSize({ id: x?.data?.id, type: x?.data?.type, value: x?.data?.label })
                  }
                  }
                  name="Size"
                />
              ))}
          </div>

        </div>
      </Card>
    </>
  );
};

export default Market;

import React from "react";
import cn from "classnames";
import styles from "./File.module.sass";
import Icon from "../Icon";
import Tooltip from "../Tooltip";

const File = ({ className, label, tooltip, title, classInput, classInputWraper, url, ...props }) => {
  return (
    <div className={cn(styles.file, className)}>
      {label && (
        <div className={styles.label}>
          {label}{" "}
          {tooltip && (
            <Tooltip
              className={styles.tooltip}
              title={tooltip}
              icon="info"
              place="right"
            />
          )}
        </div>
      )}
      {
        url !== '' && url?.toString().includes("https") ?
          <div className={styles.imgPrevievContainer}>
            <img style={{ borderRadius: '8px', objectFit:'cover' }} width={150} height={150} src={url} alt="" />
          </div>
          : <></>
      }
      <div className={cn(classInputWraper, styles.wrap)}>
        <input className={cn(classInput, styles.input)} type="file" {...props} accept="image/png, image/jpeg" />
        <div className={styles.box}>
          <Icon name="upload" size="24" />
          {title}
        </div>
      </div>
    </div>
  );
};

export default File;

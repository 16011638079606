import React, { useState } from "react";
import styles from "./Row.module.sass";
import Control from "../../Control";

const Row = ({ item, value, onChange, up, varId, formTypeProduct }) => {

  const [visibleActions, setVisibleActions] = useState(false);

  return (
    <>
      <div className={styles.row} onMouseLeave={() => setVisibleActions(false)}>


        <div className={styles.col}>
          <div className={styles.label}>Label</div>
          <div className={styles.sales}>
            <div className={styles.number}>{item?.label}</div>
          </div>
        </div>

        <div className={styles.col}>
          <div className={styles.label}>Value</div>
          <div className={styles.sales}>
            <div className={styles.number}>{item?.value}</div>
          </div>
        </div>

        <div className={styles.col}>
          <div className={styles.label}>Type</div>
          <div className={styles.sales}>
            <div className={styles.number}>{item?.type}</div>
          </div>
        </div>

        {
          formTypeProduct === "details" ? <></> :
            <div className={styles.col}>
              <Control data={item} varId={varId} />
            </div>
        }


      </div>

    </>
  );
};

export default Row;

import React from "react";
import styles from "./Control.module.sass";
import cn from "classnames";
import Icon from "../../../../components/Icon";
import { useContext } from "react";
import { GlobalState } from "../../../../App";


const Control = ({ className, visibleActions, setVisibleActions, up, data, varId }) => {
 // console.log("variation id in ctrl:", varId)
 // console.log("data in ctrl:", data)
  const { variationsList, isClickedSave, setIsClickedSave, attrColor, setAttrColor,
    attrSize, setAttrSize } = useContext(GlobalState)
  return (
    <div className={cn(styles.control, className)}>
      <button onClick={() => {
        variationsList[varId].attributes.forEach(element => {
          if (element?.id === data?.id) {
            variationsList[varId].attributes = variationsList[varId].attributes.filter(data => data?.id !== element?.id)

            // variationsList[varId].attributes.pop(element)
            // if (element === attrColor?.id) {
            //   setAttrColor('')
            // }
            // if (element === attrSize?.id) {
            //   setAttrSize('')
            // }
            setIsClickedSave(true)

            setTimeout(() => {
              setIsClickedSave(false)
            }, 1000);
          }
        });
       // console.log(variationsList)
      }} className={styles.button}>
        <Icon name="trash" size="20" />
      </button>


    </div >
  );
};

export default Control;

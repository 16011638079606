import React, { useState } from "react";
import styles from "./Market.module.sass";
import Row from "./Row";

const Market = ({ items, varId, formTypeProduct }) => {
  // console.log("items attr in row:", items)

  const [selectedFilters, setSelectedFilters] = useState([]);

  const handleChange = (id) => {
    if (selectedFilters.includes(id)) {
      setSelectedFilters(selectedFilters.filter((x) => x !== id));
    } else {
      setSelectedFilters((selectedFilters) => [...selectedFilters, id]);
    }
  };

  return (
    <div className={styles.market}>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>Label</div>
          <div className={styles.col}>Value</div>
          <div className={styles.col}>type</div>
        </div>
        {items.map((x, index) => {
          // console.log(x)
          return (
            <Row
              item={x?.data}
              key={index}
              up={items.length - index <= 2}
              value={selectedFilters.includes(x.id)}
              onChange={() => handleChange(x.id)}
              varId={varId}
              formTypeProduct={formTypeProduct}
            />
          )
        })}
      </div>

    </div>
  );
};

export default Market;

import React from "react";
import useDarkMode from "use-dark-mode";

const Image = ({ className, src, srcDark, srcSet, srcSetDark, alt }) => {
  const darkMode = false;

  return (
    <img
      className={className}
      srcSet={false ? srcSetDark : srcSet}
      src={false ? srcDark : src}
      alt={alt}
    />
  );
};

export default Image;

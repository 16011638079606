import React from "react";
import styles from "./CollectionProductsList.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import Products from "./Products";

const CollectionProductsList = () => {
  return (
    <>
      <div className={styles.section}>
        <Products />
      </div>
      <TooltipGlodal />
    </>
  );
};

export default CollectionProductsList;

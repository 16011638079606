import React from "react";
import styles from "./ProductsDashboard.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import Products from "./Products";

const ProductsDashboard = () => {
  return (
    <>
      <div className={styles.section}>
        <Products />
      </div>
      <TooltipGlodal />
    </>
  );
};

export default ProductsDashboard;

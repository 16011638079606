import React, { useContext, useState, useEffect } from 'react';
import './AddLocationPopup.css';
import cn from "classnames";
import styles from './AddLocationPopup.module.sass';
// import ThumbsUp from '../GetQuote/FormSection/Images/thumbsUp.png';
import Modal from '@mui/material/Modal';
import Card from '../../../../components/Card';
import { GlobalState } from '../../../../App';
import TextInput from '../../../../components/TextInput';
import { getLocationDocId, validate_if_not_empty } from '../../../../utils/extensions';
import Icon from '../../../../components/Icon';


const AddLocationPopup = () => {
  const { setIsClickedSave, locationsList, setLocationsList, setShowLocationPopup,
    activeEditLocation, setActiveEditLocation,
    showModalViewLocation, setShowModalViewLocation,
  } = useContext(GlobalState)
  const TAG = "location popup"
  useEffect(() => {
    setIsClickedSave(false)
    // console.log(TAG, locationsList)
  }, [])

  const handleClose = () => {
    setShowModalViewLocation(false);
  }

  useEffect(() => {
    // console.log(TAG, activeEditLocation)
    if (activeEditLocation) {
      setLocation(activeEditLocation?.title)
    }
  }, [activeEditLocation])

  const [location, setLocation] = useState()

  const locationDetailsObj = {
    id: getLocationDocId(),
    title: '',
    dateCreated: '',
    dateEdited: ''
  }

  const createLocation = async () => {
    locationDetailsObj.title = location;
    // console.log(TAG, "after", locationDetailsObj)
    await setLocationsList((oldData) => [...oldData, locationDetailsObj])
    setIsClickedSave(true)
    setShowLocationPopup(false)
  }

  const updateLocation = async () => {
    for (let index = 0; index < locationsList.length; index++) {
      if (locationsList[index]?.id === activeEditLocation?.id) {
        // console.log(TAG, locationsList[index]?.id, activeEditLocation?.id, activeEditLocation?.title)
        locationsList[index].title = location;
        setActiveEditLocation()
        setIsClickedSave(true)
        setShowLocationPopup(false)
      }

    }

  }

  // const [location, setLocation] = useState()

  return (
    <Modal
      disableAutoFocus={true}
      open={showModalViewLocation}
      onClose={handleClose}
      className='dialog-wrapper'
      sx={{
        outline: '0 !important',
        border: 'none !important'
      }}
    >
      <Card
        className={cn(styles.card)}
        title="Add location"
        classTitle="title-green"
        classCardHead={styles.cardHead}
        head={
          <div style={{
            display: 'flex',
            gap: '12px'
          }}>

            <button
              onClick={() => {
                if (validate_if_not_empty(location, "locationTitlePopupErrId")) {
                  activeEditLocation ? updateLocation() :
                    createLocation()
                }
              }}
              className={cn("button", styles.button)} >
              <span>Save</span>
            </button>
            <button
              onClick={() => {
                setShowModalViewLocation(false)
              }}
              className={cn(styles.closeButton)} >
              <Icon name="close" fill="red" size="16" />
            </button>
          </div>

        }
      >
        <div className={styles.fieldContainer}>
          <TextInput
            className={styles.field}
            classInput={styles.fieldInput}
            label="Location"
            name="popupLocation"
            type="text"
            onChange={(e) => { setLocation(e.target.value) }}
            value={location}
            required
          />
          <p className={styles.errField} id="locationTitlePopupErrId"></p>
        </div>
      </Card>

    </Modal>

  )
}

export default AddLocationPopup
import React, { useState, useEffect, useContext, useRef } from "react";
import cn from "classnames";
import styles from "./ImagesAndCTA.module.sass";
import Card from "../../../components/Card";
import File from "../../../components/File";
import Icon from "../../../components/Icon";
import './Variations.css'
import TextInput from "../../../components/TextInput";
import Market from "./Market";
import { addVerticalSpace, getVariationDocId } from "../../../utils/extensions";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PopupScreen from "./PopupScreen/PopupScreen";
import { GlobalState } from "../../../App";
import VariationsDropdown from "../../../components/VariationsDropdown";
import { useLocation } from 'react-router-dom'
import { getAllVariations } from "../../../Firebase/firebaseDatabase";
import LoadingIndicator from "../../../LoadingScreen/LoadingIndicator";
import { async } from "@firebase/util";
import TextInputQuantity from "../../../components/TextInputQuantity";
import leftArrow from './img/left-arr.png';
import rightArrow from './img/right-arr.png';

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const ImagesAndCTA = ({ className }) => {

  const location = useLocation()
  const {
    attributeList,
    showPopup, setShowPopup,
    setActiveVariationAttr, activeVariationAttr,
    variationsList, setVariationsList,
    setIsClickedSave, setIsClickActionComplete,
    isPushedImages, setIsPushedImages, isClickedSave, productSku, formTypeProduct, setShowModalView
  } = useContext(GlobalState)

  const [loading, setLoading] = useState(false)
  const [isEditForm, setIsEditForm] = useState(false)

  function scrollToView(scrollToElemId, index) {
    document.getElementById(`collapsible${index}`)?.click();
    document.getElementById(scrollToElemId).style.transform = document.getElementById(scrollToElemId).style.transform === 'rotate(180deg)' ? 'rotate(0deg)' : 'rotate(180deg)'
    document.getElementById(scrollToElemId)
      .scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
  }

  useEffect(() => {
    setShowPopup(false)
    setIsClickActionComplete(false)
    setIsClickedSave(false)
    setActiveVariationAttr('')
    setIsPushedImages(false)

    const pageType = location.pathname.split("/")[2]
    if (pageType !== "edit" || pageType !== "details") {
      setVariationsList([])
    }

    setTimeout(async () => {
      if (pageType === "edit" || pageType === "details") {
        setLoading(true)
        setIsEditForm(true)
        const productId = location.pathname.split("/")[3]
        const variationId = location.pathname.split("/")[4]

        if (pageType === "edit" || pageType === "details") {
          let indexNum;
          getAllVariations(productId)?.then(async list => {
            list?.forEach((element, index) => {
              if (element?.id === variationId) {
                indexNum = index;
              }
              element?.attributes?.forEach((variationAttrListData, i) => {
                attributeList?.forEach(attributeListData => {
                  if (attributeListData?.id === variationAttrListData) {
                    element.attributes[i] = attributeListData
                  }
                })
              })
            });
            await setVariationsList(list)

            setLoading(false)
            setTimeout(() => {
              scrollToView(variationId + "$VAR" + indexNum, indexNum)
            }, 100);

          })
        }

      }
    }, 500);
  }, [])

  const optionsStatus = ["Select status", "On Sale", "Out Of Stock", "Archived"]

  const [isVariationQtyUpdated, setIsVariationQtyUpdated] = useState(false)

  const [productSkuInVariation, setProductSkuInVariation] = useState()



  const variationObject = {
    id: getVariationDocId(),
    sku: productSkuInVariation ?? '',
    quantity: '',
    status: optionsStatus[0],
    attributes: [],    //done only delete remaining
    images: [],
    dateCreated: '',
    dateEdited: ''
  }

  useEffect(() => {

    setProductSkuInVariation(productSku)
    for (let index = 0; index < variationsList.length; index++) {
      variationsList[index].sku = productSku;
    }
  }, [productSku])


  var settings = {
    className: styles.slider,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: false,
    // responsive: false,
    centerPadding: "24px",
    waitForAnimate: false,
    nextArrow: (
      <SlickArrow>
        <div style={{ 'background': '#F4F4F4', width: '24', height: '24' }}>
          <img src={rightArrow} alt="left" height="24" width="24" />
        </div>
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        <div style={{ 'background': '#F4F4F4', width: '24', height: '24' }}>
          <img src={leftArrow} alt="right" height="24" width="24" />
        </div>
      </SlickArrow>
    ),
    responsive: [
      {
        breakpoint: 4000, // width to change options
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1418, // width to change options
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  }

  const sliderRef = useRef();

  const handleChangeFiles = (variationId, inputFileId) => {
    // console.log("variation id in upload func:", variationId)
    const id = document.getElementById(inputFileId);
    // console.log("files: ", id.files)
    const list = id.files;
    const fileList = [];
    for (let index = 0; index < list.length; index++) {
      const element = list[index];
      // console.log("array element: ", element);
      fileList.push(element);

    }
    for (var i = 0; i < variationsList.length; i++) {
      // console.log("checking...", variationsList[i]?.id, variationId)
      if (variationsList[i]?.id === variationId) {
        // console.log("checking into is valid...", variationsList[i]?.id, variationId)
        for (var j = 0; j < fileList.length; j++) {
          variationsList[i]?.images.push(fileList[j])
        }
      }
    }

    sliderRef.current.slickGoTo(list.length - 1);
    setIsPushedImages(true)
  }

  const handleChangeDeleteImg = async (imgList, selectedImgIndex, selectedImg) => {
    const index = imgList.indexOf(selectedImg);
    if (index > -1) { // only splice array when item is found
      imgList.splice(index, 1); // 2nd parameter means remove one item only
      await setIsClickedSave(true)
      setIsClickedSave(false)
    }
  }


  useEffect(() => {
    //// console.log("is called for variation list first then for images pushed")
    setTimeout(() => {
      setIsPushedImages(false)
    }, 2000);
    // console.log("selected attr. list: ", variationsList)
  }, [variationsList, isPushedImages, setIsPushedImages])


  useEffect(() => {
    setTimeout(() => {
      setIsVariationQtyUpdated(false)
    }, 2000);
  }, [isVariationQtyUpdated]);

  useEffect(() => {

  }, [activeVariationAttr, isClickedSave]);


  return (
    <Card
      className={cn(styles.card, className)}
      title="Variations"
      classTitle="title-green"
      id="allVariations"
      head={
        formTypeProduct === "details" ? <></> :
          <div style={{
            display: 'flex',
            gap: '12px'
          }}>

            <button
              onClick={() => {
                setVariationsList((oldData) => [...oldData, variationObject])
                // variationsList.length
                // console.log(variationsList)
                // scrollToView(variationsList[variationsList.length - 1].id + "$VAR" + variationsList.length - 1, variationsList.length - 1)
              }
              }
              className={cn("button", styles.button)} >
              <span>+Add</span>
            </button>
          </div>

      }
    >

      {
        showPopup ?
          <PopupScreen popupScreen={setShowPopup} />
          : <></>
      }
      {loading ? <LoadingIndicator /> :
        <div className={styles.variations_wrapper}>
          {
            variationsList.map((x, index) => {
              // console.log("attributes data n list....................:", x?.id + "QTY")
              return (
                <div key={index} class="wrap-collabsible">
                  <input
                    id={`collapsible${index}`}
                    class="toggle" type="checkbox" />
                  <label
                    onClick={(val) => {
                      document.getElementById(`collapsible${index}`).click();
                      let transformId = x?.id + "$VAR" + index
                      document.getElementById(transformId).style.transform = document.getElementById(transformId).style.transform === 'rotate(180deg)' ? 'rotate(0deg)' : 'rotate(180deg)'
                    }}
                    class="lbl-toggle" id={x?.id + "$TOG" + index}>
                    <div>
                      <h1 className="variation_title">Variation {index + 1}</h1>
                      <p className={styles.errField} id={`variationErrId${x?.id}`}></p>
                    </div>

                    <Icon className="lblToggle" id={x?.id + "$VAR" + index} name="arrow-down" size={24} />

                  </label>

                  <div class="collapsible-content">
                    {/* {isEditForm ?
                   <div className={styles.fieldset}>
                     <div className={`${styles.fieldContainer}`}>

                       <TextInput
                         className={styles.field}
                         classInput={styles.fieldInput}
                         label="SKU"
                         name="sku"
                         type="text"
                         value={x?.sku}
                         readOnly={true}
                       />
                     </div>
                   </div>
                   : <></>} */}


                    <div className={styles.fieldset}>
                      {
                        formTypeProduct === "details" ? <div className={`${styles.fieldContainer}  ${styles.smallFields}`}>
                          <TextInput
                            className={styles.field}
                            classInput={styles.fieldInput}
                            label="Qty"
                            name="qty"
                            type="decimal"
                            id={`${x?.id}$QTY`}
                            value={x?.quantity}
                            readOnly={true}
                          />
                        </div> :
                          <div className={`${styles.fieldContainer}  ${styles.smallFields}`}>
                            <TextInputQuantity
                              className={styles.field}
                              classInput={styles.fieldInput}
                              label="Qty"
                              name="qty"
                              type="decimal"
                              id={`${x?.id}$QTY`}
                              value={x?.quantity}
                              onChange={(e) => {
                                e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
                                // console.log(e.target.value)
                              }}
                            />
                            <p className={styles.errField} id="variationQtyErrId"></p>
                          </div>
                      }

                      {
                        formTypeProduct === "details" ? <div className={`${styles.fieldContainer}  ${styles.smallFields}`}>
                          <TextInput
                            className={styles.field}
                            classInput={styles.fieldInput}
                            label="Status"
                            name="status"
                            value={x?.status}
                            readOnly={true}
                          />
                        </div> :
                          <div className={`${styles.fieldContainer}  ${styles.smallFields}`}>

                            <VariationsDropdown
                              className={styles.smallDropdownField}
                              classDropdownLabel={styles.smallDropdownlabel}
                              label="Status"
                              value={x?.status
                                // setTimeout(() => variationsList[index]?.status === "" ? optionsStatus[0] : variationsList[index]?.status, 500)
                              }
                              id={index}
                              options={optionsStatus}
                              placeHolder="Select Status"
                            />
                            <p className={styles.errField} id="variantStatusErrId"></p>

                          </div>
                      }
                    </div>

                    <div className={styles.attributes_wrapper}>

                      <div className={styles.attribute_header}>
                        <h1 className={styles.title}>Attributes</h1>
                        {
                          formTypeProduct === "details" ? <></> :
                            <button
                              onClick={() => {
                                setActiveVariationAttr({ id: x?.id, attrList: variationsList.filter(data => data?.id === x?.id)[0].attributes })
                                setShowPopup(true)
                                setShowModalView(true)
                              }}
                              className={cn(styles.addButton)} >
                              <Icon fill="white" name="add" size="15" />
                            </button>
                        }
                      </div>

                      <div className={styles.attributes_table_wrapper}>
                        <Market
                          varId={index}
                          items={x?.attributes}
                          formTypeProduct={formTypeProduct}
                        // items={attributeList.filter(data => x?.attributes?.includes(data?.id))}
                        />
                      </div>

                    </div>

                    {/* <div><h1 className={styles.title}>Images</h1></div> */}
                    <div className={styles.image_section}>
                      <div className={styles.image_upload_header}>
                        <h1 className={styles.title}>Images</h1>
                        {/* <p className={styles.errField} id={`imageErrId${x?.id}`}></p> */}
                      </div>
                      <div className={styles.images_crousel_wrapper}>
                        <Slider
                          ref={sliderRef}
                          {...settings}
                          centerPadding="12px"
                        >
                          {
                            variationsList[index]?.images.map((elem, i) => {
                              // console.log("elem img:", elem)
                              return (
                                <div key={i} className={styles.variation_images}>
                                  <div className={styles.variant_img_cont_wrap}>
                                    <div className={styles.variant_image_container}>
                                      <div style={{
                                        height: '213px',
                                        borderRadius: '8.79px',
                                        backgroundImage: `url(${elem?.toString()?.includes("https") ? elem : window.URL.createObjectURL(new Blob([elem]))})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        backgroundPositionX: 'center',
                                        backgroundPositionY: 'center'
                                      }}>
                                      </div>

                                      {
                                        formTypeProduct === "details" ? <></> :
                                          <div onClick={async () => {
                                            handleChangeDeleteImg(variationsList[index]?.images, i, elem)
                                          }} className={styles.delete_btn_container}>
                                            <Icon fill="#6F767E" name="trash" size={15} />
                                          </div>
                                      }

                                    </div>
                                  </div>
                                </div>

                              )

                            })
                          }
                        </Slider>
                      </div>

                      {
                        formTypeProduct === "details" ? <></> :
                          <div className={styles.files}>
                            <File
                              classInput={styles.imageInput}
                              className={styles.imageField}
                              classInputWraper={styles.imageFieldWrapper}
                              title="Click or drop image"
                              multiple="multiple"
                              id={`variationImgs${index}`}
                              onChange={
                                (event) => {
                                  handleChangeFiles(x.id, `variationImgs${index}`)
                                  event.target.value = ''
                                }
                              }
                            />
                          </div>
                      }

                      {addVerticalSpace('18px')}
                    </div>
                  </div>
                </div>
              )
            })
          }

        </div>
      }

    </Card>
  );
};

export default ImagesAndCTA;

import React, { useState } from "react";
import styles from "./Row.module.sass";
import Control from "../../Control";

const Row = ({ item, value, onChange, up, keyIndex }) => {

  const [visibleActions, setVisibleActions] = useState(false);

  const [showPlaceHolder, setShowPlaceHolder] = useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setShowPlaceHolder(false)
    }, 100);
  }, [])

  return (
    <>
      <div key={keyIndex} className={styles.row}
      >
        <div className={styles.col}>
          <div
            className={styles.item}
          >
            <div className={styles.preview}>
              {
                showPlaceHolder ?
                  <img
                    srcSet={`/images/content/placeholderImg.png 2x`}
                    src={'/images/content/placeholderImg.png'}
                    alt="Product"
                  />
                  :
                  <img
                    srcSet={`${item.logo + `?a=${new Date().toISOString()}`} 2x`}
                    src={item.logo + `?a=${new Date().toISOString()}`}
                    alt=""
                  />
              }
            </div>
            <div className={styles.details}>
              <div className={styles.wrap}>
                <div className={styles.product}>{item?.nameEn}</div>
                <div className={styles.product}>{`(${item?.id})`}</div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.col}>
          <div className={styles.label}>Email</div>
          <div className={styles.box}>
            <div className={styles.number}>
              {item?.email}
            </div>
          </div>
          <Control
            className={styles.control}
            visibleActions={visibleActions}
            setVisibleActions={setVisibleActions}
            up={up}
            data={item}
          />
        </div>

        <div className={styles.col}>
          <div className={styles.label}>Country</div>
          <div className={styles.box}>
            <div className={styles.number}>
              {item?.country !== '' ? item?.country : "-"}
            </div>
          </div>
        </div>

        <div className={styles.col}>
          <div className={styles.label}>Phone Number</div>
          <div className={styles.box}>
            <div className={styles.number}>
              {item?.phone}
            </div>
          </div>
        </div>

        <div className={styles.col}>
          <div className={styles.label}>Products</div>
          <div className={styles.box}>
            <div className={styles.number}>
              {item?.product}
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default Row;

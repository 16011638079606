import React, { useState, useEffect } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./DropdownCollection.module.sass";
import Tooltip from "../Tooltip";

const DropdownCollection = ({
  className,
  classDropdownHead,
  classDropdownLabel,
  value,
  setValue,
  options,
  label,
  tooltip,
  small,
  upBody,
  handlechangeVal,
  placeholder,
  onClick,
  ...props
}) => {

  const [visible, setVisible] = useState(false);
  const handleClick = async (value, index) => {
    console.log(value)
    if (index === 0) {
      setValue("")
    } else {
      // if (onClick) {
      //   setValue(value?.titleEn);
      //   setVisible(false);
      //   onClick()
      // } 
      // else {
      setValue(value);
      setVisible(false);
      // }
    }
  };


  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      {label && (
        <div className={cn(styles.label, classDropdownLabel)}>
          {label}{" "}
          {tooltip && (
            <Tooltip
              className={styles.tooltip}
              title={tooltip}
              icon="info"
              place="right"
            />
          )}
        </div>
      )}
      <div
        className={cn(
          styles.dropdown,
          className,
          { [styles.small]: small },
          {
            [styles.active]: visible,
          }
        )}
      >
        <div
          className={cn(styles.head, classDropdownHead)}
          onClick={() => setVisible(!visible)}
        >
          <div className={styles.selection}
            data-id={value[0]?.id}
          >{
              value[0]?.title === undefined ? value : value[0]?.title
            }</div>


        </div>
        <div className={cn(styles.body, { [styles.bodyUp]: upBody })}>
          {options.map((x, index) => {
            return (
              <div
                className={cn(styles.option, {
                  [styles.selectioned]: x?.titleEn === value,
                })}
                onClick={() => {
                  index === 0 ?
                    <></> :
                    x.titleEn ?
                      handleClick([{ id: x?.id, title: x?.titleEn }], index) : handleClick(x, index)
                }
                }
                key={index}
                id={x?.id}
                placeholder={placeholder}
                {...props}
              >
                {x?.titleEn ? x?.titleEn : x}
              </div>
            )
          })}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default DropdownCollection;

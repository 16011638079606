import React, { useState } from "react";
import styles from "./Row.module.sass";
import Control from '../../Control';

const Row = ({ item, value, onChange, up }) => {

  const [visibleActions, setVisibleActions] = useState(false);

  return (
    <>

      <div className={styles.row}
        onMouseLeave={() => setVisibleActions(false)}
      >

        <div className={styles.col}>
          <div className={styles.label}>Title</div>
          <div className={styles.box}>
            {item?.title}
          </div>
          {/* <Control data={item}
            className={styles.control}
            visibleActions={visibleActions}
            setVisibleActions={setVisibleActions}
            up={up}
          /> */}
        </div>

        <div className={styles.col}>

          <Control
            data={item}
            className={styles.control}
            visibleActions={visibleActions}
            setVisibleActions={setVisibleActions}
            up={up}
          />

        </div>

      </div>

    </>
  );
};

export default Row;

import React, { useContext } from "react";
import styles from "./Control.module.sass";
import cn from "classnames";
import Icon from "../../../components/Icon";
import Actions from "../../../components/Actions";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { GlobalState } from "../../../App";
import { deleteSubCategoryById } from "../../../Firebase/firebaseDatabase";


const Control = ({ className, visibleActions, setVisibleActions, up, data, categoryId, categoryTitle }) => {

  const theme = useTheme();
  const matches1022 = useMediaQuery(theme.breakpoints.down(1023));
  const { setIsClicked,
    setIsActionComplete } = useContext(GlobalState)

  const delete_actions = [
    {
      title: "Yes",
      icon: "check",
      action: () => {
        setIsClicked(true);
        deleteSubCategoryById(categoryId, data?.id, setIsActionComplete, setIsClicked)
      },
    },
    {
      title: "No",
      icon: "close",
      action: () => {
      },
    }
  ]


  const actions = [
    {
      title: "Edit product",
      icon: "edit",
      action: () => { window.location.href = `/subCategory/${categoryTitle}/${categoryId}/${data?.id}/edit` },
    },
    {
      title: "Delete forever",
      icon: "trash",
      action: () => {
        setIsClicked(true);
        deleteSubCategoryById(categoryId, data?.id, setIsActionComplete, setIsClicked)
      },
    },
  ];
  return (
    <div className={cn(styles.control, className)}>
      <button onClick={() => {
        window.location.href = `/subCategory/${categoryTitle}/${categoryId}/${data?.id}/edit`
      }} className={styles.button}>
        <Icon name="edit" size="20" />
      </button>
      {/* <button onClick={() => {
        setIsClicked(true);
        deleteSubCategoryById(categoryId, data?.id, setIsActionComplete, setIsClicked)
      }} className={styles.button}>
        <Icon name="trash" size="20" />
      </button> */}

      {
        matches1022 ?
          <Actions
            className={styles.actions}
            classActionsHead={styles.actionsHead}
            classActionsBody={styles.actionsBody}
            classActionsOption={styles.actionsOption}
            items={actions}
            visible={visibleActions}
            setVisible={setVisibleActions}
            up={up}
          />
          :
          <Actions
            className={styles.actions}
            classActionsHead={styles.actionsHead}
            classActionsBody={styles.actionsBody}
            // classActionsOption={styles.actionsOption}
            icon="trash"
            items={delete_actions}
            data={data}
            visible={visibleActions}
            setVisible={setVisibleActions}
            up={up}
          />
      }

    </div>
  );
};

export default Control;

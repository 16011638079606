import React, { useContext, useEffect, useState } from "react";
import styles from "./Banners.module.sass";
import cn from "classnames";
import Card from "../../components/Card";
import Form from "../../components/Form";
import Market from "./Market";
import Product from './Product'

const Banners = () => {

  const [search, setSearch] = useState(null);

  return (
    <Card
      className={styles.card}
      title="Active Banners"
      classTitle={cn("title-green", styles.title)}
      classCardHead={styles.head}
      head={
        <button
          onClick={() => window.location.href = "/banners/add"}
          className={cn("button", styles.button)}>
          <span>Add</span>
        </button>
      }
    >
      <div className={styles.products}>

        <div className={styles.wrapper}>
          <Product />
        </div>
      </div>
    </Card>
  );
};

export default Banners;

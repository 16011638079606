import React, { useContext } from "react";
import cn from "classnames";
import styles from "./Panel.module.sass";
import Icon from "../../../../components/Icon";
import { deleteCollProducts } from "../../../../Firebase/firebaseDatabase";
import { GlobalState } from "../../../../App";

const Panel = ({ item, collectionId }) => {
  const { homeCustomProducts, setIsClickedSave, setHomeCustomProducts,
    isActionComplete, setIsActionComplete,
    isClickedSave, categories
  } = useContext(GlobalState);
  return (
    <div className={cn("panel", styles.panel)}>
      <div className={styles.info}>
        <Icon name="check-all" size="24" />{item.length} products selected
      </div>
      <div className={styles.btns}>
        <button
          onClick={() => {
            console.log(collectionId, item)
            setIsClickedSave(true)
            deleteCollProducts(collectionId, item).then((res) => {
              if (res === true) {
                setIsActionComplete(true)
                setIsClickedSave(false)
              }
            })
          }}
          className={cn("button-stroke-red", styles.button)}>
          <span>Remove</span>
          <Icon name="trash" size="24" />
        </button>
      </div>
    </div>
  );
};

export default Panel;

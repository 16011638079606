import React, { useContext, useState } from "react";
import cn from "classnames";
import styles from "./SignIn.module.sass";
import { use100vh } from "react-div-100vh";
import { Link } from "react-router-dom";
import TextInput from "../../components/TextInput";
import Image from "../../components/Image";
import { GlobalState } from "../../App";
// import { userSignIn } from "../../firebase/firebaseAuth";
import { validate_email } from "../../utils/extensions";
import { userSignIn } from "../../Firebase/firebaseAuth";

const SignIn = () => {
  const heightWindow = use100vh();

  const { setIsLogin } = useContext(GlobalState);
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()

  return (
    <div className={styles.login} style={{ minHeight: heightWindow }}>
      <div className={styles.wrapper}>
        <Link className={styles.logo} to="/">
          <Image
            className={styles.pic}
            src="/images/logo-dark.png"
            srcDark="/images/logo-light.png"
            alt=""
          />
        </Link>
        <div
          className={cn("h2", styles.title)}
        >Sign in
        </div>
        <div className={styles.head}>

        </div>

        <div className={styles.body}>
          {/* <div className={styles.subtitle}>Or continue with email address</div> */}
          <TextInput
            className={styles.field}
            classInput={styles.classInput}
            name="email"
            type="email"
            placeholder="Your email"
            required
            icon="mail"
            warningIcon="warning"
            validateSuccessIcon="validate-success"
            checkIcon="check"
            id="signin-email-input"
            warningIconId="email-warning"
            inputIconId="login-email"
            onKeyUp={(e) => {
              setEmail(e.target.value);
              validate_email(e.target.value, "signin-email-input", "email-warning", "login-email", "validate-success"
              )
            }}
          />
          <TextInput
            className={styles.field}
            classInput={styles.classInput}
            name="password"
            type="password"
            placeholder="Password"
            required
            icon="lock"
            onKeyUp={(e) => {
              setPassword(e.target.value);
            }}
          />
          <button
            // onClick={() => {
            //   setIsLogin(true)
            // }}
            onClick={
              () => {
                userSignIn(
                  {
                    email: email,
                    password: password,
                    setIsLogin: setIsLogin,
                    errId: "invalid-email-pass"
                  }
                )
              }
            }
            className={cn("button", styles.button)}
          >Continue
          </button>
          <p style={{
            marginTop: '10px',
            color: 'red'
          }} id="invalid-email-pass"></p>
        </div>
      </div>
    </div>
  );
};

export default SignIn;

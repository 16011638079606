import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Overview.module.sass";
import TooltipGlodal from "../../../components/TooltipGlodal";
import Card from "../../../components/Card";
import Icon from "../../../components/Icon";
import Tooltip from "../../../components/Tooltip";
import {
  collection,
  getDocs,
} from "@firebase/firestore";
import { db } from "./../../../Firebase/firebaseConfig";
import LoadingIndicator from "../../../LoadingScreen/LoadingIndicator";

const Overview = ({ className }) => {

  const [totalProducts, setTotalProducts] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalOrdersByDate, setTotalOrdersByDate] = useState(0);
  const [totalSellers, setTotalSellers] = useState(0);
  const [loading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(true);
    getData();
    
  }, []);

  const items = [
    {
      title: "Products",
      counter: totalProducts === 0 ? "0" : totalProducts,
      icon: "shopping-bag",
      background: "#ecf9fe",
      chartColor: "#2A85FF",
    },
    {
      title: "Sellers",
      counter: totalSellers === 0 ? "0" : totalSellers,
      icon: "activity",
      background: "#edf8f2",
      chartColor: "#83BF6E",
    },
    {
      title: "Orders",
      counter: totalOrders === 0 ? "0" : totalOrders,
      icon: "payment",
      background: "#f2efff",
      chartColor: "#8E59FF",
    },
    {
      title: "Todays's order",
      counter: totalOrdersByDate === 0 ? "0" : totalOrdersByDate,
      icon: "payment",
      background: "rgba(255, 216, 141, 0.25)",
      chartColor: "#8E59FF",
    },
  ];
  const getData = async () => {
    await getDocs(collection(db, "seller")).then((doc) => {
      setTotalSellers(doc.docs.length);
    });
    await getDocs(collection(db, "order")).then((doc) => {
      setTotalOrders(doc.docs.length);
      const orderList = doc.docs.map((doc) => ({
        data: doc.data(),
        id: doc.id,
      }));
      const filteredData = orderList.filter((data) => {
        // console.log(data)
        if (
          data?.data?.dateCreated?.split("T")[0] ===
          new Date().toISOString().split("T")[0]
        ) {
          return data;
        }
      });

      setTotalOrdersByDate(filteredData.length);
    });
    await getDocs(collection(db, "product")).then((doc) => {
      setTotalProducts(doc.docs.length);
    });

    setIsLoading(false);
  };

  return (
    <>
      <Card
        className={cn(styles.card, className)}
      >
        {loading ? (
          <LoadingIndicator />
        ) : (
          <div className={styles.overview}>
            <div className={styles.list}>
              {items.map((x, index) => (
                <div
                  className={styles.item}
                  key={index}
                  style={{ backgroundColor: x.background }}
                >
                  <div className={styles.icon}>
                    <Icon name={x.icon} size="24" />{" "}
                  </div>
                  <div className={styles.line}>
                    <div className={styles.details}>
                      <div className={styles.category}>
                        {x.title}
                        <Tooltip
                          className={styles.tooltip}
                          title="Small description"
                          icon="info"
                          place="right"
                        />
                      </div>
                      <div className={styles.counter}>{x.counter}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </Card>
      <TooltipGlodal />
    </>
  );
};

export default Overview;

import React, { useState, useEffect } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./SearchDropdown.module.sass";
import Tooltip from "../Tooltip";
import Icon from "../Icon";

const SearchDropdown = ({
  className,
  classDropdownHead,
  classDropdownLabel,
  value,
  setValue,
  options,
  label,
  tooltip,
  small,
  upBody,
  handlechangeVal,
  placeholder,
  onClick,
  ...props
}) => {

  const [visible, setVisible] = useState(false);
  const handleClick = async (value, index) => {

    // if (index === 0) {
    //   setValue("")
    // } else {
      // if (onClick) {
      setValue(value);
      setVisible(false);
      // onClick()
      // } else {
      //   setValue(value);
      //   setVisible(false);
      // }
      // document.getElementById('sellerproductsku').innerText = document.getElementById('sellerproductsku').innerText + value?.id
    // }
  };

  const [searchByVal, setSearchByVal] = useState('');
  const [optionsList, setOptionsList] = useState(options)
  useEffect(() => {
    setOptionsList(options)
  }, [options]);

  const handleChangeFilter = (val) => {
   // console.log(val)
    setSearchByVal(val)
  }

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      {label && (
        <div className={cn(styles.label, classDropdownLabel)}>
          {label}{" "}
          {tooltip && (
            <Tooltip
              className={styles.tooltip}
              title={tooltip}
              icon="info"
              place="right"
            />
          )}
        </div>
      )}
      <div
        className={cn(
          styles.dropdown,
          className,
          { [styles.small]: small },
          {
            [styles.active]: visible,
          }
        )}
      >
        <div
          className={cn(styles.head, classDropdownHead)}
          onClick={() => setVisible(!visible)}
        >
          <div id="searchSeller" className={styles.selection} data-id={value[0].id}>{
            value[0]?.title === undefined ? "Select seller" : value[0]?.title
          }</div>

        </div>
        <div className={cn(styles.body, { [styles.bodyUp]: upBody })}>

          <div className={styles.searchWrapper}>
            <div className={styles.search}>
              <input
                className={styles.input}
                type="text"
                // value={value}
                onChange={(e) => handleChangeFilter(e.target.value)}
                name="Search"
                placeholder={placeholder}
                required
              />
              <button className={styles.result}>
                <Icon name="search" size="24" />
              </button>
            </div>
          </div>

          <div className={styles.optionsContainer}>
            {optionsList
              ?.filter(data => {
                return data?.nameEn?.toLowerCase()?.includes(searchByVal?.toLowerCase())
                  || data?.id?.toLowerCase()?.includes(searchByVal?.toLowerCase())
              }
              )
              ?.map((x, index) => {
               // console.log(x)
                return (
                  <div
                    className={cn(styles.option, {
                      [styles.selectioned]: x?.titleEn === value,
                    })}
                    onClick={() => {
                      // index === 0 && value === "Select seller" ?
                      //   <></> :
                      // x?.nameEn ?
                      handleClick([{ id: x?.id, title: x?.nameEn }], index)
                      // : handleClick(x, index)
                    }
                    }
                    key={index}
                    id={x?.id}
                    placeholder="Select seller"
                    {...props}
                  >
                    {x?.nameEn ? x?.nameEn : x}
                  </div>
                )
              })}
          </div>

        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default SearchDropdown;

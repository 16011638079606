import React, { useState } from "react";
import styles from "./Row.module.sass";
import Control from "../../Control";
import { Link } from "react-router-dom";

const Row = ({ item, value, onChange, up, setIsActionComplete, setIsClicked }) => {

  const [visibleActions, setVisibleActions] = useState(false);

  const [showPlaceHolder, setShowPlaceHolder] = useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setShowPlaceHolder(false)
    }, 100);
  }, [])

  return (
    <>

      <div className={styles.row}
        onMouseLeave={() => setVisibleActions(false)}
      >

        <div className={styles.col}>
          <Link to={`/subCategory/${encodeURIComponent(item?.titleEn)}/${item?.id}`}>
            <div
              className={styles.item}
            >
              <div className={styles.preview}>
                {
                  showPlaceHolder ?
                    <img
                      srcSet={`/images/content/placeholderImg.png 2x`}
                      src={'/images/content/placeholderImg.png'}
                      alt="Product"
                    />
                    :
                    <img
                      srcSet={`${item.image} 2x`}
                      src={item.image}
                      alt=""
                    />
                }
              </div>
              <div className={styles.details}>
                <div className={styles.categoryTitle}>{item.titleEn}</div>
                <div className={styles.count}>{item.titleAr}</div>
              </div>
            </div>
          </Link>
        </div>

        <div className={styles.col}>
          <div
            className={styles.controlsContainer}
          >
            <Control
              className={styles.control}
              visibleActions={visibleActions}
              setVisibleActions={setVisibleActions}
              up={up}
              data={item}
              setIsActionComplete={setIsActionComplete}
              setIsClicked={setIsClicked}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Row;

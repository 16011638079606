import React, { useEffect, useState, useContext } from 'react'
import { useLocation } from 'react-router';
import styles from './OrderDetails.module.sass';
import OrderDetailsView from './OrderDetailsView';
import PopularProducts from './PopularProducts'
import cn from "classnames";
import Icon from '../../components/Icon';
import Dropdown from '../../components/Dropdown';
import { getOrderById, updateOrderById } from '../../Firebase/firebaseDatabase';
import LoadingIndicator from '../../LoadingScreen/LoadingIndicator';
import { GlobalState } from '../../App'
import LoadingDialog from '../../LoadingScreen/LoadingDialog';
import { ORDER_STATUS, ORDER_STATUS_1 } from '../../utils/constants';
// import Control from './Control';
import ConfirmationPopup from './ConfirmationPopup/ConfirmationPopup';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const OrderDetails = () => {

    const theme = useTheme();
    const matches1023 = useMediaQuery(theme.breakpoints.down(1024));

    const location = useLocation()
    const [orderId, setOrderId] = useState()

    const [loading, setLoading] = useState(true)
    const [orderDetails, setOrderDetails] = useState()

    const optionsStatus = ["Select status", "New", "Delayed", "Extended", "Completed", "Cancelled"]
    const [orderStatus, setOrderStatus] = useState(optionsStatus[0])

    const { setIsActionComplete, isActionComplete, isClicked, setIsClicked,
        isNewOrderStatus, setIsNewOrderStatus, isConfirmUpdateStatus, setIsConfirmUpdateStatus } = useContext(GlobalState)
    const getOrderDetails = async () => {
        try {
            await getOrderById(orderId).then((details) => {
                setOrderDetails(details)
                setLoading(false)
            })

        } catch (error) {
            setLoading(false)
        }
    }

    useEffect(() => {
        setIsActionComplete(false);
    }, [])

    useEffect(() => {
    }, [isActionComplete, isClicked, isConfirmUpdateStatus])

    useEffect(() => {
        setLoading(true)
        setOrderId(location.pathname.split("/")[2])
        getOrderDetails()
    }, [orderId])


    useEffect(() => {
        if (orderDetails !== undefined) {
            if (orderDetails[0]?.data?.status !== '') {
                setOrderStatus(orderDetails[0]?.data?.status)
            }
        }
    }, [orderDetails])


    const handleChangeStatus = () => {
        // setIsNewOrderStatus(true)
        setIsClicked(true)
        // isNewOrderStatus === false ?
        //     isConfirmUpdateStatus ?
        setTimeout(() => {
            // setIsClicked(true)
            const updateStatus = document.getElementById(ORDER_STATUS_1).value
            // console.log("helfmwlkmfwklvlkv")
            // setIsActionComplete(false)
            if (orderDetails !== undefined) {
                if (updateStatus?.toUpperCase() !== orderDetails[0]?.data?.status) {

                    orderDetails[0].data.status = updateStatus?.toUpperCase();
                    updateOrderById(orderId, orderDetails[0].data, setIsActionComplete, setIsClicked).then((res) => {
                        if (res === true) {
                            setIsConfirmUpdateStatus(true)
                        }
                    })
                } else {
                    setIsClicked(false)
                }
            } else {
                setIsClicked(false)
            }
        }, 1000)
        // : <></> : <></>

    }
    return (
        <div className={styles.section}>

            {
                isConfirmUpdateStatus ? <ConfirmationPopup /> : <></>
            }

            {loading ?
                <LoadingIndicator />
                :
                <div className={styles.row}>

                    {
                        !matches1023 ?
                            <>
                                <div className={styles.col}>
                                    <OrderDetailsView orderDetails={orderDetails} />
                                </div>
                                <div className={styles.col}>
                                    <div className={styles.orderActionButtonsContainer}>
                                        <button
                                            className={cn("button", styles.button)} >
                                            <Icon name="mail" size={22} />
                                            <span>Send E-Mail</span>
                                        </button>

                                        <button
                                            className={cn("button", styles.button, styles.creditMemoBtn)} >
                                            <Icon fill="black" name="multiselect" size={22} />
                                            <span>Credit Memo</span>
                                        </button>
                                    </div>


                                    <div className={styles.statusDropdownContainer}>
                                        {
                                            isClicked ?
                                                !isActionComplete ? <LoadingDialog /> : <></>
                                                : <></>
                                        }
                                        {/* <Control /> */}
                                        <Dropdown
                                            className={styles.smallDropdownField}
                                            classDropdownLabel={styles.smallDropdownlabel}
                                            label="Status"
                                            value={orderStatus}
                                            setValue={setOrderStatus}
                                            options={optionsStatus}
                                            isFrom={ORDER_STATUS}
                                            onClick={() => handleChangeStatus()}
                                        />
                                    </div>

                                    <PopularProducts orderId={orderId} />
                                </div>
                            </> :
                            <>

                                <div className={styles.col}>
                                    <div className={styles.orderActionButtonsContainer}>
                                        <button
                                            className={cn("button", styles.button)} >
                                            <Icon name="mail" size={22} />
                                            <span>Send E-Mail</span>
                                        </button>

                                        <button
                                            className={cn("button", styles.button, styles.creditMemoBtn)} >
                                            <Icon fill="black" name="multiselect" size={22} />
                                            <span>Credit Memo</span>
                                        </button>
                                    </div>

                                    <div className={styles.statusDropdownContainer}>
                                        {
                                            isClicked ?
                                                !isActionComplete ? <LoadingDialog /> : <></>
                                                : <></>
                                        }
                                        {/* <Control /> */}
                                        <Dropdown
                                            className={styles.smallDropdownField}
                                            classDropdownLabel={styles.smallDropdownlabel}
                                            label="Status"
                                            value={orderStatus}
                                            setValue={setOrderStatus}
                                            options={optionsStatus}
                                            isFrom={ORDER_STATUS}
                                            onClick={() => handleChangeStatus()}
                                        />
                                    </div>

                                    <PopularProducts orderId={orderId} />
                                </div>
                                <div className={styles.col}>
                                    <OrderDetailsView orderDetails={orderDetails} />
                                </div>
                            </>
                    }

                </div>
            }



            {/* <Products /> */}
        </div>
    )
}

export default OrderDetails
import React, { useState, useEffect, useContext } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./Dropdown.module.sass";
import Tooltip from "../Tooltip";
import { ORDER_STATUS } from "../../utils/constants";
import { GlobalState } from "../../App";

const Dropdown = ({
  className,
  classDropdownHead,
  classDropdownLabel,
  value,
  setValue,
  options,
  label,
  tooltip,
  small,
  upBody,
  handlechangeVal,
  placeholder,
  onClick,
  isFrom,
  ...props
}) => {

  const { orderStatus2, setOrderStatus2 } = useContext(GlobalState)
  const [visible, setVisible] = useState(false);
  const handleClick = async (value, index) => {

    if (index === 0) {
      setValue("")
    } else {
      if (onClick) {
        setValue(value);
        setVisible(false);
        if (isFrom === ORDER_STATUS) {
          setOrderStatus2(value)
        }
        onClick()
      } else {
        setValue(value);
        setVisible(false);

      }

    }
  };


  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      {label && (
        <div className={cn(styles.label, classDropdownLabel)}>
          {label}{" "}
          {tooltip && (
            <Tooltip
              className={styles.tooltip}
              title={tooltip}
              icon="info"
              place="right"
            />
          )}
        </div>
      )}
      <div
        className={cn(
          styles.dropdown,
          className,
          { [styles.small]: small },
          {
            [styles.active]: visible,
          }
        )}
      >
        <div
          className={cn(styles.head, classDropdownHead)}
          onClick={() => setVisible(!visible)}
        >
          <div className={styles.selection} id={isFrom === ORDER_STATUS ? ORDER_STATUS : ""}>{
            value?.charAt(0).toUpperCase() + value?.slice(1).toLowerCase()
          }</div>


        </div>
        <div className={cn(styles.body, { [styles.bodyUp]: upBody })}>
          {options?.map((x, index) => (
            <div
              className={cn(styles.option, {
                [styles.selectioned]: x === value,
              })}
              onClick={() => {

                index === 0 ?
                  <></> :
                  handleClick(x, index)
              }
              }
              key={index}
              placeholder={placeholder}
              {...props}
            >
              {x}
            </div>
          ))}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default Dropdown;

import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import cn from "classnames";
import styles from "./Page.module.sass";
import Sidebar from "../Sidebar";
import Header from "../Header";
import Icon from "../Icon";
import { useHistory } from "react-router-dom";

const Page = ({ wide, children, title, createButton, path, backButton }) => {
  const [visible, setVisible] = useState(false);
  let history = useHistory();
  return (
    <>
      <div className={styles.page}>
        <Sidebar
          className={cn(styles.sidebar, { [styles.visible]: visible })}
          onClose={() => setVisible(false)}
        />
        <Header onOpen={() => setVisible(true)} />
        <div className={styles.inner}>
          <div
            className={cn(styles.container, {
              [styles.wide]: wide,
            })}
          >

            {
              backButton ?
                <button className={cn("button", styles.backButton)} onClick={() => history.goBack()} >
                  <Icon name="arrow-left" fill="black" size={20} />
                  <span>Back</span>
                </button> : <></>
            }
            <div style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}>
              {title && <div className={cn("h3", styles.title)}>{title}</div>}

              <div className={styles.buttonsContainor}>

                {createButton ?
                  <Link className={cn("button", styles.button)} to={path}>
                    <Icon className={styles.plusIcon} name="add" size="20" />
                    <span>Add</span>
                  </Link> :
                  <></>
                }

              </div>

            </div>
            {/* {title && <div className={cn("h3", styles.title)}>{title}</div>} */}
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(Page);

import React, { useContext, useState, useEffect } from 'react';
import './PopupScreen.css';
import cn from "classnames";
import styles from './PopupScreen.module.sass';
// import ThumbsUp from '../GetQuote/FormSection/Images/thumbsUp.png';
import Modal from '@mui/material/Modal';
import Card from '../../../../components/Card';
import Market from "./Market";
import { GlobalState } from '../../../../App';
import { createCollectionProducts, createFeaturedProducts } from '../../../../Firebase/firebaseDatabase';
import Icon from '../../../../components/Icon';


const PopupScreen = ({collectionId}) => {
  const {
    setIsClickedSave, isClickedSave,
    setHomeCustomProducts,
    selectedHomeCustomProducts, setSelectedHomeCustomProducts,
    setShowPopup, setIsActionComplete, showModalView, setShowModalView
  } = useContext(GlobalState)

  // const [openModal, setOpenModel] = useState(true)

  useEffect(() => {
    setIsClickedSave(false)
    setShowModalView(true)
  }, [])

  const handleClose = () => {
    setShowModalView(false);
  }

  useEffect(() => {
    if (isClickedSave) {
      setHomeCustomProducts([])
      var arrayList = [];
      selectedHomeCustomProducts?.forEach((selectedProdctData, i) => {
        setHomeCustomProducts((oldData) => [...oldData, selectedProdctData])
        const featuredProductObj = {
          productId: selectedProdctData?.product?.id,
          variationId: selectedProdctData?.id
        }
        arrayList.push(featuredProductObj)

      });

      if (arrayList.length === selectedHomeCustomProducts.length) {
        const productsList = {
          productsList: arrayList
        }
        createCollectionProducts(collectionId, productsList).then(res => {

          setSelectedHomeCustomProducts([])

          setTimeout(() => {
            setIsClickedSave(false)
          }, 1000);
          setShowPopup(false)
          setIsActionComplete(true)

          // }
        })
      }
    }
  }, [isClickedSave])

  return (
    <Modal
      // disableAutoFocus={true}
      open={showModalView}
      onClose={handleClose}
      className='dialog-wrapper'
      sx={{
        outline: '0 !important',
        border: 'none !important'
      }}
    >
      {/* <div className={cn(styles.card)} style={{ border: '1px solid red', height:'fit-content' }}>
        <div>x</div> */}
      <Card
        className={cn(styles.card)}
        title="Select attributes"
        classTitle="title-green"
        classCardHead={styles.cardHead}
        head={
          <div style={{
            display: 'flex',
            gap: '12px'
          }}>
            <button
              onClick={() => {
                setIsClickedSave(true)
                // popupScreen(false)
              }}
              className={cn("button", styles.button)} >
              <span>Save</span>
            </button>
            <button
              onClick={() => {
                setShowModalView(false)
              }}
              className={cn(styles.closeButton)} >
              <Icon name="close" fill="red" size="16" />
            </button>
          </div>

        }
      >
        <Market
        />
      </Card>
      {/* </div> */}


    </Modal>

  )
}

export default PopupScreen
import React, { useContext } from "react";
import styles from "./Control.module.sass";
import cn from "classnames";
import Icon from "../../../components/Icon";
import Actions from "../../../components/Actions";
import { Link } from "react-router-dom";
import { GlobalState } from "../../../App";
import { deleteProduct } from "../../../Firebase/firebaseDatabase";

const Control = ({ className, visibleActions, setVisibleActions, up, data }) => {

  const { setIsActionComplete, setIsClicked } = useContext(GlobalState)
  console.log(data)
  const actions = [
    {
      title: "Yes",
      icon: "check",
      action: () => {
        setIsClicked(true)
        deleteProduct(data).then(res => {
          setIsActionComplete(true)
          setIsClicked(false)
        })
      },
    },
    {
      title: "No",
      icon: "close",
      action: () => { },
    },
  ];

  return (
    <div className={cn(styles.control, className)}>
      <Actions
        className={cn("button", styles.button, styles.deleteProductBtn, styles.actions)}
        classActionsHead={styles.classActionsHead}
        classActionsBody={styles.classActionsBody}
        classActionsOption={styles.actionsOption}
        items={actions}
        visible={visibleActions}
        setVisible={setVisibleActions}
        icon="trash"
        up={false}
        BtnTitle="Delete Product"
      />
    </div>
  );
};

export default Control;

import React from "react";
import styles from "./Control.module.sass";
import cn from "classnames";
import Icon from "../../../../../components/Icon";
import { useContext } from "react";
import { GlobalState } from "../../../../../App";


const Control = ({ className, visibleActions, setVisibleActions, up, data, setShowPopup }) => {

  const { setActiveEditLocation, locationsList, setLocationsList, setDeleteLocationIds } = useContext(GlobalState)

  const deleteFunc = () => {
    for (let index = 0; index < locationsList.length; index++) {
      if (locationsList[index]?.id === data?.id) {
        setDeleteLocationIds((oldData) => [...oldData, data?.id])
        setLocationsList(locationsList.filter(list => {
          if (list?.id !== data?.id) {
            return list
          }
        }
        ))
      }
    }
  }

  return (
    <div className={cn(styles.control, className)}>
      <button onClick={async () => {
        await setActiveEditLocation({ id: data?.id, title: data?.title })
        setShowPopup(true)
      }} className={styles.button}>
        <Icon name="edit" size="15" />
      </button>

      <button onClick={() => {
        deleteFunc()
      }} className={styles.button}>
        <Icon name="trash" size="15" />
      </button>


    </div>
  );
};

export default Control;

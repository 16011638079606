import React, { useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";

const Row = ({ item, value, onChange, up }) => {

  const [visibleActions, setVisibleActions] = useState(false);

  return (
    <>

      <div id="rowId" className={styles.row}
        onMouseLeave={() => setVisibleActions(false)}
        onClick={() => { window.location.href = `/order/${item?.id}/details` }}
      >

        <div className={styles.col}>
          <div className={styles.label}>Customer name</div>
          <div className={styles.box}>
            {item.customerName}
          </div>
        </div>

        <div className={styles.col}>
          <div className={styles.label}>Order ID</div>
          <div className={styles.box}>
            #{item.id}
          </div>
        </div>

        <div className={styles.col}>
          <div className={styles.label}>Total</div>
          <div className={styles.box}>
            ${item.total}
          </div>
        </div>

        <div className={styles.col}>
          <div className={styles.label}>Status</div>
          <div className={styles.box}>
            {item.status === "COMPLETED" ? (
              <div className={cn("status-completed", styles.status)}>{item.status}</div>
            ) : item.status === "NEW" ? (
              <div className={cn("status-new", styles.status)}>{item.status}</div>
            ) : item.status === "DELAYED" ? (
              <div className={cn("status-delay", styles.status)}>{item.status}</div>
            ) : item.status === "EXTENDED" ?
              (
                <div className={cn("status-extended", styles.status)}>{item.status}</div>
              ) : item.status === "CANCELLED" && (
                <div className={cn("status-cancle", styles.status)}>{item.status}</div>
              )
            }
          </div>
        </div>

        <div className={styles.col}>
          <div className={styles.label}>Date created</div>
          <div className={styles.box}>
            {item?.dateCreated?.split("T")[0].replaceAll("-", "/").split('/').reverse().join('/')}
          </div>
        </div>

        <div className={styles.col}>
          <div className={styles.label}>Sales channel</div>
          <div className={styles.box}>
            {item.salesChannel}
          </div>
        </div>

      </div>

    </>
  );
};

export default Row;

import React, { useState, useEffect, useContext } from "react";
import cn from "classnames";
import styles from "./OrderDetailsView.module.sass";
import Card from "../../../components/Card";
import TextInput from "../../../components/TextInput";
import LoadingIndicator from "../../../LoadingScreen/LoadingIndicator";
import { GlobalState } from "../../../App";
import { ORDER_STATUS, ORDER_STATUS_1 } from "../../../utils/constants";


const OrderDetailsView = ({ orderDetails }) => {

  const { orderStatus2, setOrderStatus2 } = useContext(GlobalState)

  useEffect(() => {
    setTimeout(() => {
      const statusDiv = document.getElementById(ORDER_STATUS).innerText;
      setOrderStatus2(statusDiv)
    }, 1000);
  }, [orderStatus2]);

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (orderDetails) {
      setLoading(false)
    }
  }, [orderDetails])

  return (

    <Card
      className={cn(styles.card)}
    >
      {
        loading ? <LoadingIndicator /> :
          orderDetails?.map((x, index) => {
            return (
              <div key={index} className={styles.description}>

                <div className={styles.fieldContainer}>
                  <TextInput
                    className={styles.field}
                    classInput={styles.fieldInput}
                    label="Customer name"
                    name="customerName"
                    type="text"
                    value={x?.data?.customerName}
                    readOnly={true}
                  />
                </div>

                <div className={styles.fieldContainer}>
                  <TextInput
                    className={styles.field}
                    classInput={styles.fieldInput}
                    label="Contact number"
                    name="contactNum"
                    type="text"
                    value={x?.data?.contactNumber}
                    readOnly={true}
                  />
                </div>

                <div className={styles.fieldContainer}>
                  <TextInput
                    className={styles.field}
                    classInput={styles.fieldInput}
                    label="Customer address"
                    name="customerAddrs"
                    type="text"
                    value={x?.data?.customerAddress}
                    readOnly={true}
                  />
                </div>

                <div className={styles.fieldContainer}>
                  <TextInput
                    className={styles.field}
                    classInput={styles.fieldInput}
                    label="Sub total"
                    name="subTotal"
                    type="number"
                    value={x?.data?.subTotal}
                    readOnly={true}
                  />
                </div>

                <div className={styles.fieldContainer}>
                  <TextInput
                    className={styles.field}
                    classInput={styles.fieldInput}
                    label="Shipping"
                    name="shipping"
                    type="number"
                    value={x?.data?.shipping}
                    readOnly={true}
                  />
                </div>

                <div className={styles.fieldContainer}>
                  <TextInput
                    className={styles.field}
                    classInput={styles.fieldInput}
                    label="VAT"
                    name="vat"
                    type="number"
                    value={x?.data?.vat}
                    readOnly={true}
                  />
                </div>

                <div className={styles.fieldContainer}>
                  <TextInput
                    className={styles.field}
                    classInput={styles.fieldInput}
                    label="Total"
                    name="total"
                    type="number"
                    value={x?.data?.total}
                    readOnly={true}
                  />
                </div>

                <div className={styles.fieldContainer}>
                  <TextInput
                    className={styles.field}
                    classInput={styles.fieldInput}
                    label="Status"
                    name="status"
                    type="text"
                    value={
                      orderStatus2?.charAt(0).toUpperCase() + orderStatus2?.slice(1).toLowerCase()
                    }
                    id={ORDER_STATUS_1}
                    readOnly={true}
                  />
                </div>

                <div className={styles.fieldContainer}>
                  <TextInput
                    className={styles.field}
                    classInput={styles.fieldInput}
                    label="Date created"
                    name="dateCreated"
                    type="text"
                    value={x?.data?.dateCreated.split("T")[0].replaceAll("-", "/").split('/').reverse().join('/')}
                    readOnly={true}
                  />
                </div>

                <div className={styles.fieldContainer}>
                  <TextInput
                    className={styles.field}
                    classInput={styles.fieldInput}
                    label="Sales channel"
                    name="salesChnl"
                    type="text"
                    value={x?.data?.salesChannel}
                    readOnly={true}
                  />
                </div>

                <div className={styles.fieldContainer}>
                  <TextInput
                    className={styles.field}
                    classInput={styles.fieldInput}
                    label="Payment Method"
                    name="payMethod"
                    type="text"
                    value={x?.data?.paymentMethod}
                    readOnly={true}
                  />
                </div>

                <div className={styles.fieldContainer}>
                  <TextInput
                    className={styles.field}
                    classInput={styles.fieldInput}
                    label="Transaction ID"
                    name="titleEn"
                    type="text"
                    value={x?.data?.transactionId}
                    readOnly={true}
                  />
                </div>

              </div>
            )
          })
      }

    </Card >
  );
};

export default OrderDetailsView;

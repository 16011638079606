import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./PopularProducts.module.sass";
import Card from "../../../components/Card";
import {
  getOrderItemsById,
  getProductById,
  getVariationById,
} from "../../../Firebase/firebaseDatabase";
import LoadingIndicator from "../../../LoadingScreen/LoadingIndicator";
import {
  collection,
  getDocs,
  doc,
  setDoc,
  query,
  where,
  deleteDoc,
} from "@firebase/firestore";
import { db, storage } from "./../../../Firebase/firebaseConfig";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";

const PopularProducts = ({ orderId }) => {

  const [loading, setLoading] = useState(false);
  const [orderItemsList, setOrderItemsList] = useState();

  const getOrderItemsList = async () => {
    try {
      const details = await getOrderItemsById(orderId);
      await details.forEach(async (element, index) => {
        console.log(element)
        let object = {
          variation: {
            sku: "",
            image: "",
          },
          title: "",
        };
        await getProductById(element?.data?.productId)
          .then(async (productDetails) => {
            console.log(productDetails)
            object.title = productDetails[0]?.data?.titleEn;
            await getDocs(
              collection(db, "product", productDetails[0]?.id, "variation"),
              where("id", "==", element?.data?.variationId)
            )
              .then(async (doc) => {
                const variationDetails = doc.docs.map((doc) => ({
                  data: doc.data(),
                  id: doc.id,
                }));
                console.log("var details:", variationDetails);
                object.variation.sku = await variationDetails[0]?.data?.sku;
                object.variation.image = await variationDetails[0]?.data
                  ?.images[0];

                details[index]["data"]["product"] = object;
                setTimeout(() => {
                  setOrderItemsList(details);
                  setLoading(false);
                }, 2000);

              })
              .catch((e) => {
                console.log("ORDER ERROR VARIATION", e);
                setLoading(false);
              });
          })
          .catch((e) => {
            console.log("ORDER ERROR", e);
            setLoading(false);
          });

      });
    } catch (e) {
      console.log("ORDER ERROR", e);
      setLoading(false);
    }
  };
  useEffect(() => {
    console.log(orderId)
    if (orderId !== undefined) {
      console.log("is false")
      setLoading(true)
    } else {
      setLoading(false);
    }
    getOrderItemsList();
  }, [orderId]);

  useEffect(() => {

  }, [orderItemsList]);

  return (
    <>
      <Card
        className={cn(styles.card)}
        title="Items"
        classCardHead={styles.cardHead}
      >
        {loading ? (
          <LoadingIndicator />
        ) : (
          <div className={styles.popular}>
            <div className={styles.list}>
              {orderItemsList?.map((itemList, index) => {
                return (
                  <div
                    className={styles.item}
                    key={index}
                  >
                    <div className={styles.preview}>
                      <img
                        srcSet={`${itemList?.data?.product?.variation?.image} 2x`}
                        src={itemList?.data?.product?.variation?.image}
                        alt="item"
                      />
                    </div>

                    <div className={styles.details}>
                      <div className={styles.title}>
                        {itemList?.data?.product?.title}
                      </div>
                      <div className={styles.price}>
                        Qty - {itemList?.data?.qty}
                      </div>
                      <div className={styles.price}>
                        {itemList?.data?.product?.variation?.sku}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </Card>
    </>
  );
};

export default PopularProducts;
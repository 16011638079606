import React from 'react';
import Lottie from "lottie-react";
// import loadingIndicator from '../Lotties/loadingIndicator.json';
import newLoadingIndicator from '../Lotties/newLoadingIndicator.json'
const LoadingIndicator = () => {

    return (
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', height:'100%' }}>
            <Lottie
                animationData={newLoadingIndicator}
                style={{ alignSelf: 'center', width: '50px' }}
                size={5}
            />
        </div>
    )
}

export default LoadingIndicator
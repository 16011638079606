import React, { useState, useEffect, useContext } from "react";
import cn from "classnames";
import styles from "./StoreRulesForm.module.sass";
import Card from "../../../components/Card";
import Icon from "../../../components/Icon";
import './Variations.css'
import TextInput from "../../../components/TextInput";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AddLocationPopup from "./AddLocationPopup/AddLocationPopup";
import { GlobalState } from "../../../App";
import Control from "./AddLocationPopup/Control";
import AddShippingMethodPopup from "./AddShippingMethodPopup/AddShippingMethodPopup";
import ShippingControl from "./AddShippingMethodPopup/ShippingControl";
import {
  createCountry, createLocations, createShippingMethods, createStoreRule, deleteCountry,
  deleteLocation, deleteShippingMethod, getCountries, getLocations, getShippingMethods,
  getStoreRules, updateCountry, updateLocation, updateShippingMethod
} from "../../../Firebase/firebaseDatabase";
import LoadingDialog from "../../../LoadingScreen/LoadingDialog";
import LoadingIndicator from "../../../LoadingScreen/LoadingIndicator";
import AddCountryPopup from "./AddCountryPopup/AddCountryPopup";
import CountryControl from "./AddCountryPopup/CountryControl";


const StoreRulesForm = ({ className }) => {

  const { locationsList, setLocationsList, showLocationPopup, setShowLocationPopup, isClickedSave, setIsClickedSave,
    shippingMethodList, setShippingMethodList, isClicked, isActionComplete, setIsClicked, setIsActionComplete,
    showShippingMethodPopup, setShowShippingMethodPopup, deleteLocationIds, setDeleteLocationIds,
    deleteShippingMethodIds, setDeleteShippingMethodIds,
    countriesList, setCountriesList,
    showCountryPopup, setShowCountryPopup,
    deleteCountryIds, setDeleteCountryIds,
    setShowModalViewCountry,
    setShowModalViewLocation,
    setShowModalViewShippingMethods
  } = useContext(GlobalState)

  const [vat, setVat] = useState()
  const [currency, setCurrency] = useState()
  const [id, setId] = useState()
  const [dateCreated, setDateCreated] = useState()

  const storeRuleDetailsObj = {
    id: id ?? '',
    vat: vat ?? '',
    currency: currency ?? '',
    dateCreated: dateCreated ?? ''
  }

  const [storeRulesData, setStoreRulesData] = useState()
  const [loading, setLoading] = useState(true)

  const fetchStoreRules = async () => {
    await getStoreRules().then(data => {
      setStoreRulesData(data)
      setLoading(false)
    })

  }

  const fetchLocations = async () => {
    await getLocations().then(list => {
      setLocationsList(list)
    })
  }

  const fetchCountries = async () => {
    await getCountries().then(list => {
      setCountriesList(list)
    })
  }

  const fetchShippingMethods = async () => {
    await getShippingMethods().then(list => {
      setShippingMethodList(list)
    })
  }

  useEffect(() => {
    setIsClickedSave(false)
    setIsActionComplete(false)
    fetchStoreRules()
    fetchLocations()
    fetchShippingMethods()
    fetchCountries()
  }, [])

  useEffect(() => {
    if (storeRulesData !== undefined) {
      const storeData = storeRulesData[0]?.data;
      setVat(storeData?.vat)
      setCurrency(storeData?.currency)
      setId(storeData?.id)
      setDateCreated(storeData?.dateCreated)
    }
  }, [storeRulesData]);

  useEffect(() => {

  }, [isClickedSave, isClicked, isActionComplete])

  const uploadCountry = async (object) => {
    console.log("while uploading country:", object)
    if (object.dateCreated !== "") {
      await updateCountry(object)
    } else {
      await createCountry(object)
    }
  }

  const uploadLocation = async (object) => {
    console.log("while uploading locations:", object)
    if (object.dateCreated !== '') {
      await updateLocation(object)
    } else {
      await createLocations(object)
    }
  }

  const deleteCountries = async (id) => {
    await deleteCountry(id)
  }
  const deleteLocations = async (id) => {
    await deleteLocation(id)
  }

  const deleteShippingMethods = async (id) => {
    await deleteShippingMethod(id)
  }

  const uploadShippingMethod = async (object) => {
    console.log(object.dateCreated)
    if (object.dateCreated !== undefined) {
      await updateShippingMethod(object)
    } else {
      await createShippingMethods(object)
    }
  }

  const uploadStoreRules = async () => {
    setIsClicked(true)
    await createStoreRule(storeRuleDetailsObj).then((id) => {

      if (deleteCountryIds.length !== 0) {
        for (let index = 0; index < deleteCountryIds.length; index++) {
          deleteCountries(deleteCountryIds[index])
        }
        setDeleteCountryIds([])
      }

      if (deleteLocationIds.length !== 0) {
        for (let index = 0; index < deleteLocationIds.length; index++) {
          deleteLocations(deleteLocationIds[index])
        }
        setDeleteLocationIds([])
      }

      if (deleteShippingMethodIds.length !== 0) {
        for (let index = 0; index < deleteShippingMethodIds.length; index++) {
          deleteShippingMethods(deleteShippingMethodIds[index])
        }
        setDeleteShippingMethodIds([])
      }

      for (let index = 0; index < locationsList.length; index++) {
        uploadLocation(locationsList[index])
      }

      for (let index = 0; index < countriesList.length; index++) {
        uploadCountry(countriesList[index])
      }

      for (let index = 0; index < shippingMethodList.length; index++) {
        console.log("cheking:", shippingMethodList[index])
        uploadShippingMethod(shippingMethodList[index])

        setTimeout(() => {
          if (index === shippingMethodList.length - 1) {
            setIsActionComplete(true)
            setIsClicked(false);
          }
        }, 2000);
      }

    })

  }

  return (
    <Card
      className={cn(styles.card, className)}
      title="All Store Rules"
      classTitle="title-green"
      head={
        <div style={{
          display: 'flex',
          gap: '12px'
        }}>

          <button
            onClick={() => {
              uploadStoreRules()
            }
            }
            className={cn("button", styles.button)} >
            <span>Save</span>
          </button>
        </div>

      }
    >

      {
        isClicked ?
          !isActionComplete ? <LoadingDialog /> : window.location.reload()
          : <></>
      }

      {
        showCountryPopup ?
          <AddCountryPopup />
          : <></>
      }

      {
        showLocationPopup ?
          <AddLocationPopup />
          : <></>
      }

      {
        showShippingMethodPopup ?
          <AddShippingMethodPopup />
          : <></>
      }

      {loading ? <LoadingIndicator /> :
        <div className={styles.description}>

          <div className={styles.fieldContainer}>
            <TextInput
              className={styles.field}
              classInput={styles.fieldInput}
              label="VAT in (%)"
              name="vat"
              onChange={(e) => {
                e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
                setVat(e.target.value)
              }}
              value={vat}
              required
            />
            <p className={styles.errField} id="vatErrId"></p>
          </div>

          <div className={styles.fieldContainer}>
            <TextInput
              className={styles.field}
              classInput={styles.fieldInput}
              label="Currency"
              name="currency"
              type="text"
              onChange={(e) => { setCurrency(e.target.value) }}
              value={currency}
              required
            />
            <p className={styles.errField} id="storeRulesCurrencyErrId"></p>
          </div>

          <div className={styles.attributes_wrapper}>

            <div className={styles.attribute_header}>
              <h1 className={styles.title}>Country</h1>

              <button
                onClick={() => {
                  setShowCountryPopup(true)
                  setShowModalViewCountry(true)
                }}
                className={cn(styles.addButton)} >
                <Icon fill="white" name="add" size="15" />
              </button>
            </div>

            <div className={styles.attributes_table_wrapper}>

              {
                countriesList?.map((data, index) => {
                  return (
                    <div key={index} className={styles.readOnly_field}>
                      <TextInput
                        className={styles.field}
                        classInput={styles.fieldInput}
                        name="country"
                        type="text"
                        readOnly={true}
                        value={data?.title}
                        id={data?.id}
                        required
                      />
                      <CountryControl setShowPopup={setShowCountryPopup} data={data} />
                    </div>
                  )
                })
              }

            </div>

          </div>

          <div className={styles.attributes_wrapper}>

            <div className={styles.attribute_header}>
              <h1 className={styles.title}>Location</h1>

              <button
                onClick={() => {
                  setShowLocationPopup(true)
                  setShowModalViewLocation(true)
                }}
                className={cn(styles.addButton)} >
                <Icon fill="white" name="add" size="15" />
              </button>
            </div>

            <div className={styles.attributes_table_wrapper}>

              {
                locationsList?.map((data, index) => {
                  return (
                    <div key={index} className={styles.readOnly_field}>
                      <TextInput
                        className={styles.field}
                        classInput={styles.fieldInput}
                        name="currency"
                        type="text"
                        readOnly={true}
                        value={data?.title}
                        id={data?.id}
                        required
                      />
                      <Control setShowPopup={setShowLocationPopup} data={data} />
                    </div>
                  )
                })
              }

            </div>

          </div>

          <div className={styles.attributes_wrapper}>

            <div className={styles.attribute_header}>
              <h1 className={styles.title}>Shiping Methods</h1>

              <button
                onClick={() => {
                  setShowShippingMethodPopup(true)
                  setShowModalViewShippingMethods(true)
                }}
                className={cn(styles.addButton)} >
                <Icon fill="white" name="add" size="15" />
              </button>
            </div>

            <div className={styles.attributes_table_wrapper}>

              {
                shippingMethodList?.map((data, index) => {
                  return (
                    <div key={index} className={styles.readOnly_field}>
                      <TextInput
                        className={styles.field}
                        classInput={styles.fieldInput}
                        name="currency"
                        type="text"
                        readOnly={true}
                        value={data?.title}
                        id={data?.id}
                        required
                      />
                      <ShippingControl setShowPopup={setShowShippingMethodPopup} data={data} />
                    </div>
                  )
                })
              }

            </div>

          </div>

        </div>
      }

    </Card>
  );
};

export default StoreRulesForm;

import React from "react";
import cn from "classnames";
import styles from "./TextInputKeywords.module.sass";
import Icon from "../Icon";
import Tooltip from "../Tooltip";
import './TextInputKeywords.css';

const TextInputKeywords = ({
  className,
  classLabel,
  classInput,
  label,
  icon,
  copy,
  currency,
  tooltip,
  place,
  onKeyUp,
  warningIcon,
  checkIcon,
  id,
  warningIconId,
  inputIconId,
  validateSuccessIcon,
  phoneNumInitials,
  value,
  ...props
}) => {

  return (
    <div
      className={cn(
        styles.field,
        { [styles.fieldIcon]: icon },
        { [styles.fieldCopy]: copy },
        { [styles.fieldCurrency]: currency },
        className
      )}
    >
      {label && (
        <div className={cn(classLabel, styles.label)}>
          {label}{" "}
          {tooltip && (
            <Tooltip
              className={styles.tooltip}
              title={tooltip}
              icon="info"
              place={place ? place : "right"}
            />
          )}
        </div>
      )}
      <div className={styles.wrap}>
        <div style={{
          paddingLeft: phoneNumInitials ? "45px" : "12px"
        }} id={id} onKeyUp={onKeyUp} className={cn(classInput, styles.input)} {...props} >
          {
            value?.map((keyword, i) => {
              return (
                value[0] === "Select a keyword" ? value[0] :
                  <div className={styles.selectedKeywordContainer}
                  >
                    <p className={styles.selectedKeyword} >{keyword?.title}</p>
                  </div>
              )
            })
          }
        </div>
      </div>
    </div>
  );
};

export default TextInputKeywords;

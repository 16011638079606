import React, { useState, useContext, useEffect } from "react";
import cn from "classnames";
import styles from "./NameAndValue.module.sass";
import Card from "../../../components/Card";
import TextInput from "../../../components/TextInput";
import { validate_if_not_empty } from "../../../utils/extensions";
import LoadingDialog from "../../../LoadingScreen/LoadingDialog";
import { GlobalState } from "../../../App";
import { useLocation } from 'react-router-dom'
import { getKeywordDocId } from "../../../utils/extensions";
import { createKeyword, getKeywordById, updateKeyword } from "../../../Firebase/firebaseDatabase";
import LoadingIndicator from "../../../LoadingScreen/LoadingIndicator";

const NameAndValue = ({ className }) => {

  const { isClicked, setIsClicked,
    isActionComplete, setIsActionComplete,
  } = useContext(GlobalState);

  const location = useLocation()

  const [loading, setLoading] = useState(false)
  const [isEditForm, setIsEditForm] = useState(false)

  const [keyword, setKeyword] = useState();
  const [id, setId] = useState()
  const [dateCreated, setDateCreated] = useState()

  const keywordDetailsObj = {
    id: id ?? getKeywordDocId(),
    dateCreated: dateCreated ?? '',
    dateEdited: '',
    title: keyword ?? ''
  }

  const uploadKeyword = async () => {
    await createKeyword(keywordDetailsObj).then(res => {
      if (res === true) {
        setIsActionComplete(true)
        setIsClicked(false)
      }
    })
  }

  const updateKeywordValue = async () => {
    await updateKeyword(keywordDetailsObj).then(res => {
      if (res === true) {
        setIsActionComplete(true)
        setIsClicked(false)
      }
    })
  }

  const checkValidations = () => {
    if (validate_if_not_empty(keyword, "keywordErrId")) {
      return true
    }
  }

  const fetchKeywordData = async (keywordId) => {
    await getKeywordById(keywordId).then(data => {
     // console.log("keyword res data:", data)
      const keyword = data[0]?.data
      setKeyword(keyword?.title)
      setId(keyword?.id)
      setDateCreated(keyword?.dateCreated)
      setLoading(false)
    })
  }

  useEffect(() => {
    setIsActionComplete(false)
    if (window.location.pathname !== "/products/keywords/add") {
      setLoading(true)
      setIsEditForm(true)
     // console.log(location.pathname.split("/")[2])
      const keywordId = location.pathname.split("/")[4]
     // console.log("project id in edit form: ", keywordId)
      if (keywordId !== "add") {
        fetchKeywordData(keywordId)
      }
    }
  }, []);

  return (
    <Card
      className={cn(styles.card, className)}
      title="Name & Value"
      classTitle="title-green"
      head={
        <div style={{
          display: 'flex',
          gap: '12px'
        }}>

          <button
            onClick={() => {

              if (checkValidations()) {
                setIsClicked(true)
                setIsActionComplete(false)
                if (isEditForm) {
                  updateKeywordValue()
                } else {
                  uploadKeyword()
                }
              }
            }}
            className={cn("button", styles.button)} >
            <span>Save</span>
          </button>
        </div>

      }
    >

      {
        isClicked ?
          isActionComplete ?
            window.location.replace("/products/keywords")
            :
            <LoadingDialog />
          :
          <></>
      }

      {
        loading ? <LoadingIndicator /> :
          <div className={styles.description}>

            <div className={styles.fieldContainer}>
              <TextInput
                className={styles.field}
                classInput={styles.fieldInput}
                label="Keyword"
                name="keyword"
                type="text"
                onChange={(e) => { setKeyword(e.target.value) }}
                value={keyword}
                required
              />
              <p className={styles.errField} id="keywordErrId"></p>
            </div>

          </div>
      }

    </Card >
  );
};

export default NameAndValue;

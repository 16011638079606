import React, { useState } from "react";
import styles from "./Sellers.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Form from "../../../components/Form";
import Market from "./Market";

const Sellers = () => {

  const [search, setSearch] = useState(undefined);

  return (
    <Card
      className={styles.card}
      title="All Sellers"
      classTitle={cn("title-purple", styles.title)}
      classCardHead={styles.head}
      head={
        <>
          <Form
            className={styles.form}
            value={search}
            setValue={setSearch}
            placeholder="Search by name/id"
            type="text"
            name="search"
            icon="search"
          />
        </>
      }
    >
      <div className={styles.products}>
        <div className={styles.wrapper}>
          <Market searchBy={search} />
        </div>
      </div>
    </Card>
  );
};

export default Sellers;

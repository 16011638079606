import React, { useState, useContext, useEffect } from "react";
import cn from "classnames";
import styles from "./NotificationForm.module.sass";
import Card from "../../../components/Card";
import File from "../../../components/File";
import './Variations.css'
import TextInput from "../../../components/TextInput";
import { createNotification, getSellers, uploadFile } from "../../../Firebase/firebaseDatabase";
import { GlobalState } from "../../../App";
import LoadingDialog from "../../../LoadingScreen/LoadingDialog";
import { validate_if_not_empty } from "../../../utils/extensions";
import axios from "axios";
// import LoadingIndicator from "../../../LoadingScreen/LoadingIndicator";


const NotificationForm = ({ className }) => {



  const {
    isClicked, setIsClicked,
    isActionComplete, setIsActionComplete
  } = useContext(GlobalState)

  const [image, setImage] = useState()
  const [description, setDescription] = useState()
  const [title, setTitle] = useState()

  const handleChangeFiles = () => {
    const id = document.getElementById('notificationCoverImg');
    // console.log("files: ", id.files[0])
    setImage(id.files[0])
  }

  const notificationDetailsObj = {
    id: '',
    dateCreated: '',
    dateEdited: '',
    status: '',
    title: title ?? '',
    description: description ?? '',
    image: image ?? ''
  }



  useEffect(() => {
    setIsClicked(false)
    setIsActionComplete(false)
  }, []);

  useEffect(() => {
    // setIsActionComplete(false)
  }, [isClicked, isActionComplete]);

  const uploadNotification = async () => {
    await createNotification(notificationDetailsObj).then(res => {

    })
  }

  const validateAllFields = () => {
    if (validate_if_not_empty(title, "notficationTitleErrId") &&
      validate_if_not_empty(description, "notificationDescEnErrId") &&
      validate_if_not_empty(image, "notficationImageErrId")) {
      return true
    } else {
      setIsClicked(false)
      return false
    }
  }

  const sendNotification = async (fcm) => {
    const body = {
      "to": fcm,
      "notification": {
        "body": description ?? '',
        "title": title ?? ''
      }
    }

    await axios.post("https://fcm.googleapis.com/fcm/send", body, {
      headers: {
        "Authorization": "AAAAgP9ZCiQ:APA91bF0ZOldenMP_Tl4luWqhp70Mr5C46lMZ57x8xtP2hpe08PnNZ0Qm874MB3j8NWV6_qEQQwBON4RcKqBXLDr1-1LLhIXxZxR-TSM539h3V0YWeaB4mfEcIDCT4yo3w7K1wzsqTM9",
      },
    }).then((res) => {
      console.log(res)
    })
  }

  return (
    <Card
      className={cn(styles.card, className)}
      title="Create Notification"
      classTitle="title-green"
      head={
        <div style={{
          display: 'flex',
          gap: '12px'
        }}>

          <button
            onClick={async () => {
              setIsClicked(true)
              const isValidateAllFields = validateAllFields()
              if (isValidateAllFields) {
                await uploadFile(notificationDetailsObj?.image, "notification").then(url => {
                  notificationDetailsObj.image = url;
                  uploadNotification()
                }
                )
                getSellers().then(res => {
                  console.log(res)
                  res.forEach((sellerData, index) => {
                    sendNotification(sellerData?.data?.fcm)
                    if (res.length - 1 === index) {
                      setIsActionComplete(true)
                      setIsClicked(false)
                    }
                  });
                })
              }
            }}
            className={cn("button", styles.button)} >
            <span>Send</span>
          </button>
        </div>

      }
    >
      {
        isClicked ?
          !isActionComplete ?
            <LoadingDialog /> : window.location.href = "/notifications"
          : <></>
      }

      <div className={styles.description}>

        <div className={styles.fieldset}>

          <div className={`${styles.fieldContainer}`}>
            <TextInput
              className={styles.field}
              classInput={styles.fieldInput}
              label="Title"
              name="notificationTitle"
              type="text"
              onChange={(e) => { setTitle(e.target.value) }}
              value={title}
            />
            <p className={styles.errField} id="notficationTitleErrId"></p>

          </div>
        </div>

        <div style={{
          display: 'flex',
          flexDirection: 'column',
        }} className={styles.fieldContainer}>
          <label className={styles.label}>Description (English)</label>
          <textarea
            placeholder="Type here.."
            className={styles.descriptionField}
            onChange={(e) => { setDescription(e.target.value) }}
            value={description}
          />
          <p className={styles.errField} id="notificationDescEnErrId"></p>
        </div>


        <div className={styles.image_section}>

          <div className={styles.files}>
            <File
              classInput={styles.imageInput}
              className={styles.imageField}
              classInputWraper={styles.imageFieldWrapper}
              title={
                image?.name !== '' && image?.name !== undefined ?
                  image?.name
                  : "Click or drop image"
              }
              id={`notificationCoverImg`}
              label="Cover image"
              onChange={() => handleChangeFiles()}
            // url={isEditForm ? image : ''}
            />
            <p className={styles.errField} id="notficationImageErrId"></p>

          </div>

        </div>

      </div>
    </Card>
  );
};

export default NotificationForm;

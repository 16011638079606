import React, { useContext, useState, useEffect } from 'react';
import './PopupScreen.css';
import cn from "classnames";
import styles from './PopupScreen.module.sass';
// import ThumbsUp from '../GetQuote/FormSection/Images/thumbsUp.png';
import Modal from '@mui/material/Modal';
import Card from '../../../../components/Card';
import Market from "./Market";
import { GlobalState } from '../../../../App';
import Icon from '../../../../components/Icon';


const PopupScreen = () => {
  const { setIsClickedSave, isClickedSave, showModalView, setShowModalView } = useContext(GlobalState)

  useEffect(() => {
    setIsClickedSave(false)
  }, [])

  useEffect(() => {

  }, [isClickedSave])

  const handleClose = () => {
    setShowModalView(false);
  }

  return (
    <Modal
      disableAutoFocus={true}
      open={showModalView}
      onClose={handleClose}
      className='dialog-wrapper'
      sx={{
        outline: '0 !important',
        border: 'none !important'
      }}
    >
      <Card
        className={cn(styles.card)}
        title="Select attributes"
        classTitle="title-green"
        classCardHead={styles.cardHead}
        head={
          <div style={{
            display: 'flex',
            gap: '12px'
          }}>
            <button
              onClick={() => {
                setIsClickedSave(true)
                // popupScreen(false)
              }}
              className={cn("button", styles.button)} >
              <span>Save</span>
            </button>
            <button
              onClick={() => {
                setShowModalView(false)
              }}
              className={cn(styles.closeButton)} >
              <Icon name="close" fill="red" size="16" />
            </button>
          </div>

        }
      >
        <Market
        />
      </Card>

    </Modal>

  )
}

export default PopupScreen
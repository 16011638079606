import React, { useState } from "react";
import cn from "classnames";
import styles from "./Orders.module.sass";
import Card from "../../components/Card";
import Market from './Market'


const Orders = () => {

  return (
    <>
      <Card
        className={styles.card}
        classCardHead={styles.head}
        title="All orders"
        classTitle={cn("title-purple", styles.title)}
      >
        <div className={styles.wrapper}>
          <Market />
        </div>
      </Card>
    </>
  );
};

export default Orders;

import React from "react";
import styles from "./CountryControl.module.sass";
import cn from "classnames";
import Icon from "../../../../../components/Icon";
import { useContext } from "react";
import { GlobalState } from "../../../../../App";


const CountryControl = ({ className, visibleActions, setVisibleActions, up, data, setShowPopup }) => {

  const {
    countriesList, setCountriesList,
    setActiveEditCountry,
    setShowCountryPopup,
    setDeleteCountryIds, } = useContext(GlobalState)

  const deleteFunc = () => {
    for (let index = 0; index < countriesList.length; index++) {
      if (countriesList[index]?.id === data?.id) {
        setDeleteCountryIds((oldData) => [...oldData, data?.id])
        setCountriesList(countriesList.filter(list => {
          if (list?.id !== data?.id) {
            return list
          }
        }
        ))
      }
    }
  }

  return (
    <div className={cn(styles.control, className)}>
      <button onClick={async () => {
        await setActiveEditCountry({ id: data?.id, title: data?.title })
        setShowCountryPopup(true)
      }} className={styles.button}>
        <Icon name="edit" size="15" />
      </button>

      <button onClick={() => {
        deleteFunc()
      }} className={styles.button}>
        <Icon name="trash" size="15" />
      </button>


    </div>
  );
};

export default CountryControl;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import styles from "./Market.module.sass";
import Icon from "../../../../components/Icon";
import Row from "./Row";
import { getCountryById, getProductByField, getSellers } from "../../../../Firebase/firebaseDatabase";
import LoadingIndicator from '../../../../LoadingScreen/LoadingIndicator'
import { GlobalState } from "../../../../App";
import LoadingDialog from "../../../../LoadingScreen/LoadingDialog";

const Market = ({ searchBy }) => {

  const [selectedFilters, setSelectedFilters] = useState([]);
  const [isLoading, setIsLoading] = useState(true)

  const { setIsActionComplete, isActionComplete, isClicked } = useContext(GlobalState)

  const handleChange = (id) => {
    if (selectedFilters.includes(id)) {
      setSelectedFilters(selectedFilters.filter((x) => x !== id));
    } else {
      setSelectedFilters((selectedFilters) => [...selectedFilters, id]);
    }
  };

  const [isPrevActive, setIsPrevActive] = useState(false)
  const [isNextActive, setIsNextActive] = useState(true)
  const [activePageNum, setActivePageNum] = useState(1)
  const [pageCount, setPageCount] = useState(1)

  const page_items_count = 20;
  const [range, setRange] = useState({
    start: 0,
    end: page_items_count
  })

  const [itemsList, setItemsList] = useState([])

  const calculatePageCount = (active_products) => {
    const calculated_page_count = active_products?.length / page_items_count;
    const check_if_isInteger = Number.isInteger(calculated_page_count)
    if (check_if_isInteger) {
      setPageCount(calculated_page_count)
      if (calculated_page_count === 1) {
      }
    } else {
      setPageCount(Math.floor(calculated_page_count) + 1)
      if (Math.floor(calculated_page_count) + 1 === 1) {
      }
    }
  }

  const { sellers, setSellers } = useContext(GlobalState)

  useEffect(() => {
    setIsActionComplete(false)

    getSellers().then(res => {
      if (res.length !== 0) {
        res.forEach((sellerData, index) => {
          getCountryById(sellerData?.data?.country).then(response => {
            if (response !== false || response !== undefined) {
              res[index].data.country = response[0]?.data?.title
            } else {
              res[index].data.country = ''
            }

          })
          getProductByField("sellerId", sellerData?.id).then(list => {
            res[index].data.product = list.length
            if (index === res.length - 1) {
              setSellers(res)
              setIsLoading(false)
            }
          })
        });

      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchBy !== undefined) {
      const active_products = sellers?.filter((data) => {
        if (data.id === searchBy) {
          return data
        } else {
          if (data.data.nameEn.toLowerCase().includes(searchBy.toLowerCase())) {
            return data
          }
        }
      })
      setItemsList(active_products)
      calculatePageCount(active_products);
    } else {
      setItemsList(sellers)
      calculatePageCount(sellers);
    }

  }, [sellers, searchBy]);

  const handleChangePrevClick = () => {

    if (activePageNum > 1) {
      setIsNextActive(true)
      const updatedPageNum = activePageNum - 1;
      setActivePageNum(updatedPageNum)

      if (updatedPageNum === 1) {
        setIsPrevActive(false)
      } else {
        setIsPrevActive(true)
      }

      setRange({
        start: updatedPageNum * page_items_count - page_items_count,
        end: updatedPageNum * page_items_count
      })
    } else {
      setIsPrevActive(false)
    }
  }

  const handleChangeNextClick = () => {

    if (activePageNum < pageCount) {
      setIsPrevActive(true)
      const updatedPageNum = activePageNum + 1;
      setActivePageNum(updatedPageNum);

      if (updatedPageNum === pageCount) {
        setIsNextActive(false)
      } else {
        setIsNextActive(true)
      }

      setRange({
        start: updatedPageNum * page_items_count - page_items_count,
        end: updatedPageNum * page_items_count
      })

    } else {
      setIsNextActive(false)
    }
  }

  useEffect(() => {

  }, [isActionComplete, isClicked])

  return (

    <div className={styles.market}>
      {
        isClicked ?
          !isActionComplete ?
            <LoadingDialog />
            : window.location.reload()

          : <></>
      }
      {isLoading ? <LoadingIndicator /> :
        <>
          <div className={styles.table}>
            <div className={styles.row}>
              <div className={styles.col}>Title</div>
              <div className={styles.col}>Email</div>
              <div className={styles.col}>Country</div>
              <div className={styles.col}>Phone Number</div>
              <div className={styles.col}>Products</div>
            </div>
            {
              itemsList?.sort((a, b) => {
                return (
                  new Date(b.data.dateCreated).getTime() - new Date(a.data.dateCreated).getTime()
                )
              })
                ?.map((x, index) => {
                  return (
                    (index >= range.start) && (index < range.end) ?
                      <Row
                        item={x?.data}
                        keyIndex={index}
                        up={sellers.length - index <= 2}
                        value={selectedFilters.includes(x.id)}
                        onChange={() => handleChange(x.id)}
                      /> : <></>
                  )
                }
                )
            }

          </div>
          {
            pageCount !== 0 && pageCount !== 1 ?
              <div className={styles.foot}>
                <button
                  style={{
                    opacity: isPrevActive ? 1 : 0.1
                  }}
                  onClick={() => {
                    handleChangePrevClick()
                  }} className={styles.arrow}>
                  <Icon name="arrow-left" size="20" />
                </button>

                <button
                  style={{
                    opacity: isNextActive ? 1 : 0.1
                  }}
                  onClick={() => {
                    handleChangeNextClick()
                  }} className={styles.arrow}>
                  <Icon name="arrow-right" size="20" />
                </button>
              </div>
              : <></>
          }
        </>
      }

    </div>
  );
};

export default Market;

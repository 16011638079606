// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getFirestore } from 'firebase/firestore';
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import * as dotenv from 'dotenv'
dotenv.config()

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: "smsm-app.firebaseapp.com",
    projectId: "smsm-app",
    storageBucket: "smsm-app.appspot.com",
    messagingSenderId: process.env.REACT_APP_API_MESSAGING_ID,
    appId: "1:554039839268:web:74a469a58c12920894e6b1",
    measurementId: "G-RFV82L63BG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app)
import React from "react";
import styles from "./SellersList.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import Sellers from "./Sellers";

const SellersList = () => {
  return (
    <>
      <div className={styles.section}>
        <Sellers />
      </div>
      {/* <TooltipGlodal /> */}
    </>
  );
};

export default SellersList;

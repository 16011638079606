import React, { useState } from "react";
import styles from "./Row.module.sass";
import Radio from "../../../../../../components/Radio";

const Row = ({ item, value, onChange, up, disabled, name }) => {
  const [visibleActions, setVisibleActions] = useState(false);

  return (
    <>
      <div className={styles.row}
        onMouseLeave={() => setVisibleActions(false)}
      >

        <div className={styles.col}>
          <Radio
            id={item?.id}
            onChange={onChange}
            value={value}
            disabled={disabled}
            name={name}
          />
        </div>
        <div className={styles.col}>{item.label}</div>

        <div className={styles.col}>
          <div className={styles.number}>
            {item.value}
          </div>
        </div>

      </div>

    </>
  );
};

export default Row;

import React, { useState, useEffect, useContext } from "react";
import styles from "./Market.module.sass";
import Row from "./Row";
import { getProducts } from "../../../../../Firebase/firebaseDatabase";
import {
  collection,
  getDocs,
} from "@firebase/firestore";
import { db } from '../../../../../Firebase/firebaseConfig';
import { GlobalState } from "../../../../../App";
import LoadingIndicator from "../../../../../LoadingScreen/LoadingIndicator";

const Market = () => {

  const [loading, setLoading] = useState(true);

  const [selectedFilters, setSelectedFilters] = useState([]);
  const { categories, isClickedSave, homeCustomProducts, newProductList, setNewProductList } = useContext(GlobalState);

  const handleChange = (id) => {
    if (selectedFilters.includes(id)) {
      setSelectedFilters(selectedFilters.filter((x) => x !== id));
    } else {
      setSelectedFilters((selectedFilters) => [...selectedFilters, id]);
    }
  };

  // const [products, setProducts] = useState([])

  const fetchProducts = async () => {
    await getProducts().then(async (data) => {

      data.forEach(async (element, index) => {
        categories.forEach((category, i) => {
          if (category.id === element?.data?.category) {
            element.data.category = category?.data?.titleEn
          }
        })
        await getDocs(
          collection(db, "product", element?.id, "variation")
        )
          .then(async (doc) => {
            const variationDetails = doc.docs.map((doc) => ({
              data: doc.data(),
              id: doc.id,
            }));
            if (variationDetails.length === 0) {
              setNewProductList((oldData) => [...oldData, element])
            }
            else {
              variationDetails.forEach(async (variationData, i) => {
                // console.log("data in popup market:", homeCustomProducts)
                // add poduct data in variation data of that product
                variationData.product = element;
                variationData.data.images = variationData.data.images[0]

                homeCustomProducts.forEach((globalHomeCstmPrdct, index) => {
                  if (globalHomeCstmPrdct?.id === variationData?.id) {
                    variationData.selected = true
                    // return 1
                  }
                  // else {
                  //   if (index === homeCustomProducts.length - 1) {
                  //     console.log("called............")
                  //     variationData.selected = false
                  //   }
                  // }
                });
                // if (homeCustomProducts.includes(variationData)){
                //   console.log("yeahhhhhhhhhhhhhhhhhhh finally got true..........................................")
                // }
                setNewProductList((oldData) => [...oldData, variationData])
              })
            }
            setLoading(false)
          })
          .catch((e) => {
            // console.log("ORDER ERROR VARIATION", e);
            // setLoading(false);
          });
      });

    }
    )
  }

  useEffect(() => {
    setLoading(true)
    fetchProducts()

  }, []);

  useEffect(() => {



  }, [isClickedSave])


  return (
    <div className={styles.market}>
      {
        loading ?
          <div className={styles.loadingContainer}>
            <LoadingIndicator />
          </div>
          : <>
            <div className={styles.table}>
              <div className={styles.row}>
                <div className={styles.col}></div>
                <div className={styles.col}>Title</div>
                <div className={styles.col}>SKU</div>
                <div className={styles.col}>Category</div>
                <div className={styles.col}>Price</div>
                <div className={styles.col}>Qty</div>
                <div className={styles.col}>Status</div>
              </div>
              {
                newProductList
                  ?.map((x, index) => {
                    return (
                      <Row
                        item={x}
                        indexVal={index}
                        up={newProductList.length - index <= 2}
                        value={selectedFilters.includes(x.id)}
                        onChange={() => handleChange(x.id)}
                      />
                    )
                  })
              }
            </div>

          </>
      }

    </div>
  );
};

export default Market;

import React, { useContext } from "react";
import styles from "./Control.module.sass";
import cn from "classnames";
import Icon from "../../../../components/Icon";
import Actions from "../../../../components/Actions";
import { Link } from "react-router-dom";
import { GlobalState } from "../../../../App";
import { deleteSeller } from "../../../../Firebase/firebaseDatabase";

const Control = ({ className, visibleActions, setVisibleActions, up, data }) => {

  const { setIsActionComplete, setIsClicked } = useContext(GlobalState)

  const actions = [
    {
      title: "Edit",
      icon: "edit",
      action: () => { window.location.href = `/seller/${data.id}/edit` },
    },
    // {
    //   title: "Delete forever",
    //   icon: "trash",
    //   action: () => {
    //     setIsClicked(true)
    //     deleteSeller(data.id, setIsActionComplete, setIsClicked)
    //   },
    // },
  ];

  return (
    <div className={cn(styles.control, className)}>

      <Link to={`/seller/${data?.id}/edit`}>
        <button className={styles.button}>
          <Icon name="edit" size="20" />
        </button>
      </Link>
{/* 
      <button onClick={() => {
        setIsClicked(true)
        deleteSeller(data.id, setIsActionComplete, setIsClicked)
      }} className={styles.button}>
        <Icon name="trash" size="20" />
      </button> */}

      <Actions
        className={styles.actions}
        classActionsHead={styles.actionsHead}
        classActionsBody={styles.actionsBody}
        classActionsOption={styles.actionsOption}
        items={actions}
        visible={visibleActions}
        setVisible={setVisibleActions}
        up={up}
      />
    </div>
  );
};

export default Control;

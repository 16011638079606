import React, { useState, useContext, useEffect } from "react";
import cn from "classnames";
import styles from "./SellerDetails.module.sass";
import Card from "../../../components/Card";
import TextInput from "../../../components/TextInput";
import Dropdown from "../../../components/Dropdown";
import { createSeller, getCountryById, getLocationById, getSellerById, getShippingMethodById, getShippingMethods, updateSellerById, uploadFile } from "../../../Firebase/firebaseDatabase";
import LoadingDialog from "../../../LoadingScreen/LoadingDialog";
import { GlobalState } from "../../../App";
import File from "../../../components/File";
import LoadingIndicator from "../../../LoadingScreen/LoadingIndicator";
import { form_email_validation, sellerSkuGenerator, validate_if_not_empty } from "../../../utils/extensions";
import DropdownNew from "../../../components/DropdownNew";
import Icon from "../../../components/Icon";
import { useHistory } from "react-router-dom";

const SellerDetails = ({ className }) => {

  const { isClicked, setIsClicked,
    isActionComplete, setIsActionComplete, allLocations, allCountries
  } = useContext(GlobalState);

  let history = useHistory();

  const [isEditForm, setIsEditForm] = useState(false)
  const [loading, setLoading] = useState(false)

  const optionsStatus = ["Select status", "Active", "Not Active"]
  const [optionsShippingMethod, setOptionsShippingMethod] = useState(["Select shipping method"])
  const [optionsLocation, setOptionsLocation] = useState(["Select location"])
  const [optionsCountry, setOptionsCountry] = useState(["Select country"])

  const [sellerNameEn, setSellerNameEn] = useState()
  const [sellerNameAr, setSellerNameAr] = useState()
  const [country, setCountry] = useState(optionsCountry[0])
  const [location, setLocation] = useState(optionsLocation[0])
  const [sellerPhone, setSellerPhone] = useState()
  const [sellerEmail, setSellerEmail] = useState()
  const [shippingMethod, setShippingMethod] = useState(optionsShippingMethod[0])
  const [status, setStatus] = useState(optionsStatus[0])
  const [sellerLogo, setSellerLogo] = useState()

  const [dateCreated, setDateCreated] = useState()
  const [id, setId] = useState()

  const [sku, setSku] = useState()

  const getSku = async () => {
    const val = await sellerSkuGenerator()
    setSku(val)
  }

  const sellerDetailsObj = {
    nameEn: sellerNameEn ?? '',
    nameAr: sellerNameAr ?? '',
    country: country ?? '',
    location: location ?? '',
    phone: sellerPhone ?? '',
    email: sellerEmail ?? '',
    logo: sellerLogo ?? '',
    shippingMethod: shippingMethod ?? '',
    status: status ?? '',
    dateEdited: '',
    dateCreated: dateCreated ?? '',
    id: id ?? sku
  }

  const getSellerData = async (id) => {
    await getSellerById(id).then(async (data) => {
      const seller = data[0].data;
      setSellerNameEn(seller.nameEn);
      setSellerNameAr(seller.nameAr);
      getCountryById(seller.country).then(res => {
        if (res !== undefined || res !== false) {
          setCountry([{ id: res[0]?.data?.id, title: res[0]?.data?.title }] ?? optionsCountry[0]);
        } else {
          setCountry(optionsCountry[0]);
        }

      })
      getLocationById(seller?.location).then(res => {
        if (res !== undefined || res !== false) {
          setLocation([{ id: res[0]?.data?.id, title: res[0]?.data?.title }] ?? optionsLocation[0]);
        } else {
          setLocation(optionsLocation[0]);
        }
      })
      setSellerPhone(seller.phone.slice(4));
      setSellerEmail(seller?.email);
      getShippingMethodById(seller?.shippingMethod).then(res => {
        if (res !== undefined || res !== false) {
          setShippingMethod([{ id: res[0]?.data?.id, title: res[0]?.data?.title }] ?? optionsShippingMethod[0]);
        } else {
          setShippingMethod(optionsShippingMethod[0]);
        }
      })
      setStatus(seller?.status ?? optionsStatus[0]);
      setSellerLogo(seller?.logo);
      setId(seller?.id);
      setDateCreated(seller?.dateCreated);

      setLoading(false)
    })
  }

  const [allShippingMethods, setAllShippingMethods] = useState()

  const fetchShippingMethods = async () => {
    await getShippingMethods().then(list => {
      setAllShippingMethods(list)
    })
  }

  useEffect(() => {
    if (allLocations?.length !== 0 && allLocations !== undefined) {
      allLocations?.filter((element) => {
        setOptionsLocation((oldData) => [...oldData, element])
      })
    }
  }, [allLocations])

  useEffect(() => {
    if (allCountries?.length !== 0 && allCountries !== undefined) {
      allCountries?.filter((element) => {
        setOptionsCountry((oldData) => [...oldData, element])
      })
    }
  }, [allCountries])

  useEffect(() => {
    if (allShippingMethods?.length !== 0 && allShippingMethods !== undefined) {
      allShippingMethods?.filter((element) => {
        setOptionsShippingMethod((oldData) => [...oldData, element])
      })
    }

  }, [allShippingMethods]);

  const TAG = "seller component";
  useEffect(() => {
    setIsActionComplete(false)
    getSku()
    fetchShippingMethods()

    if (window.location.pathname.split("/")[2] !== 'add') {
      setIsEditForm(true)
      setLoading(true)
      const sellerId = window.location.pathname.split("/")[2];
      getSellerData(sellerId)
    }
  }, [])

  useEffect(() => {

  }, [isActionComplete, isClicked, sku])


  const handleChangeFiles = () => {
    const id = document.getElementById('subCategoryCoverImg');
    setSellerLogo(id?.files[0])
  }

  const validateAllFields = () => {
    if (
      validate_if_not_empty(sellerNameEn, "sellerNameEnErrId") &&
      validate_if_not_empty(sellerNameAr, "sellerNameArErrId") &&
      validate_if_not_empty(country, "sellerCountryErrId") &&
      validate_if_not_empty(location, "sellerLocationErrId") &&
      validate_if_not_empty(sellerPhone, "sellerPhoneErrId") &&
      form_email_validation(sellerEmail, "sellerEmailErrId") &&
      validate_if_not_empty(shippingMethod, "sellerShippingMethodErrId") &&
      validate_if_not_empty(status, "sellerStatusErrId") &&
      validate_if_not_empty(sellerLogo, "sellerLogoErrId")
    ) {
      return true
    } else {
      return false
    }
  }

  const publishSeller = async () => {
    setIsClicked(true)
    setIsActionComplete(false)

    const checkIfValid = validateAllFields();
    if (checkIfValid) {
      sellerDetailsObj.shippingMethod = sellerDetailsObj.shippingMethod[0]?.id;
      sellerDetailsObj.location = sellerDetailsObj.location[0]?.id;
      sellerDetailsObj.country = sellerDetailsObj.country[0]?.id;
      sellerDetailsObj.phone = "+966" + sellerDetailsObj.phone;

      await uploadFile(sellerDetailsObj.logo, "seller").then(async (url) => {
        sellerDetailsObj.logo = await url;
        createSeller(sellerDetailsObj, id, setIsClicked, setIsActionComplete)
      })
    } else {
      setIsClicked(false)
    }

  }

  const updateSeller = async () => {
    const checkIfValid = validateAllFields();
    if (checkIfValid) {
      setIsClicked(true)

      if (typeof (sellerDetailsObj.country) === "object") {
        sellerDetailsObj.country = sellerDetailsObj.country[0]?.id;
      }

      if (typeof (sellerDetailsObj.shippingMethod) === "object") {
        sellerDetailsObj.shippingMethod = sellerDetailsObj.shippingMethod[0]?.id;
      }

      if (typeof (sellerDetailsObj.location) === "object") {
        sellerDetailsObj.location = sellerDetailsObj.location[0]?.id;
      }

      sellerDetailsObj.phone = "+966" + sellerDetailsObj.phone;
      if (sellerDetailsObj?.logo?.toString().includes("https")) {
        updateSellerById(sellerDetailsObj, setIsActionComplete, setIsClicked)
      }
      else {
        const imageUrl = await uploadFile(sellerLogo, "seller");
        sellerDetailsObj.logo = imageUrl;
        updateSellerById(sellerDetailsObj, setIsActionComplete, setIsClicked)
      }
    }

  }

  return (
    <Card
      className={cn(styles.card, className)}
      title="Name & Value"
      classTitle="title-green"
      head={
        <div style={{
          display: 'flex',
          gap: '12px'
        }}>

          <button className={cn("button", styles.backButton)} onClick={() => history.goBack()} >
            <Icon name="arrow-left" size={20} fill="black" />
            <span>Back</span>
          </button>
          <button
            onClick={() => {
              isEditForm ? updateSeller()
                :
                publishSeller()
            }}
            className={cn("button", styles.button)} >
            <span>Save</span>
          </button>
        </div>

      }
    >

      {
        isClicked ?
          isActionComplete ?
            window.location.href = "/sellers"
            :
            <LoadingDialog />
          :
          <></>
      }

      {loading ?
        <LoadingIndicator />
        :
        <div className={styles.description}>

          {isEditForm ?
            <div className={styles.fieldContainer}>
              <TextInput
                className={styles.field}
                classInput={styles.fieldInput}
                label="Seller Id"
                name="sellerId"
                type="text"
                readOnly={true}
                // onChange={(e) => { setSellerId(e.target.value) }}
                value={id}
                required
              />
              {/* <p className={styles.errField} id="sellerIdErrId"></p> */}
            </div>
            : <></>}


          <div className={styles.fieldContainer}>
            <TextInput
              className={styles.field}
              classInput={styles.fieldInput}
              label="Seller Name (English)"
              name="sellerNameEn"
              type="text"
              onChange={(e) => { setSellerNameEn(e.target.value) }}
              value={sellerNameEn}
              required
            />
            <p className={styles.errField} id="sellerNameEnErrId"></p>
          </div>

          <div className={styles.fieldContainer}>
            <TextInput
              className={styles.field}
              classInput={styles.fieldInput}
              label="Seller Name (Arabic)"
              name="sellerNameAr"
              type="text"
              onChange={(e) => { setSellerNameAr(e.target.value) }}
              value={sellerNameAr}
              required
            />
            <p className={styles.errField} id="sellerNameArErrId"></p>
          </div>

          <div className={styles.fieldContainer}>
            <DropdownNew
              label="Country"
              value={country}
              setValue={setCountry}
              options={optionsCountry}
            />
            <p className={styles.errField} id="sellerCountryErrId"></p>
          </div>

          <div className={styles.fieldContainer}>
            <DropdownNew
              label="Location"
              value={location}
              setValue={setLocation}
              options={optionsLocation}
            />
            <p className={styles.errField} id="sellerLocationErrId"></p>
          </div>

          <div className={styles.fieldContainer}>
            <TextInput
              className={styles.field}
              classInput={styles.fieldInput}
              label="Seller Phone"
              name="sellerPhone"
              // type="number"
              phoneNumInitials={true}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
                setSellerPhone(e.target.value)
              }}
              value={sellerPhone}
              required
            />
            <p className={styles.errField} id="sellerPhoneErrId"></p>
          </div>

          <div className={styles.fieldContainer}>
            <TextInput
              className={styles.field}
              classInput={styles.fieldInput}
              label="Seller E-Mail Address"
              name="sellerEmail"
              onChange={(e) => { setSellerEmail(e.target.value) }}
              value={sellerEmail}
              required
            />
            <p className={styles.errField} id="sellerEmailErrId"></p>
          </div>

          <div className={styles.fieldContainer}>
            <DropdownNew
              label="Shipping Method"
              value={shippingMethod}
              setValue={setShippingMethod}
              options={optionsShippingMethod}
            />
            <p className={styles.errField} id="sellerShippingMethodErrId"></p>
          </div>

          <div className={styles.fieldContainer}>
            <Dropdown
              label="Status"
              value={status}
              setValue={setStatus}
              options={optionsStatus}
            />
            <p className={styles.errField} id="sellerStatusErrId"></p>
          </div>

          <div className={styles.image_section}>

            <div className={styles.files}>
              <File
                classInput={styles.imageInput}
                className={styles.imageField}
                classInputWraper={styles.imageFieldWrapper}
                title={
                  sellerLogo?.name !== '' && sellerLogo?.name !== undefined ?
                    sellerLogo?.name
                    : "Click or drop image"
                }
                id={`subCategoryCoverImg`}
                label="Cover image"
                onChange={() => handleChangeFiles()}
                url={isEditForm ? sellerLogo : ''}
              />
            </div>
            <p className={styles.errField} id="sellerLogoErrId"></p>
          </div>

        </div>
      }

    </Card >
  );
};

export default SellerDetails;

import React, { useState, useEffect, useContext } from "react";
import cn from "classnames";
import styles from "./Product.module.sass";
import Control from "./Control";
import Icon from "../../../components/Icon";
import Checkbox from "../../../components/Checkbox";
import { getBanners, getSubCategoryById } from "../../../Firebase/firebaseDatabase";
import { GlobalState } from "../../../App";
import LoadingIndicator from "../../../LoadingScreen/LoadingIndicator";
import LoadingDialog from "../../../LoadingScreen/LoadingDialog";
import Row from "./Row";

const Product = () => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(true)
  const [bannersList, setBannersList] = useState([])
  const { categories } = useContext(GlobalState);

  const [visibleActions, setVisibleActions] = useState(false);

  const { isClicked, setIsClicked,
    isActionComplete, setIsActionComplete } = useContext(GlobalState)

  const [selectedFilters, setSelectedFilters] = useState([]);
  const handleChange = (id) => {
    if (selectedFilters.includes(id)) {
      setSelectedFilters(selectedFilters.filter((x) => x !== id));
    } else {
      setSelectedFilters((selectedFilters) => [...selectedFilters, id]);
    }
  };

  const fetchBanners = async () => {
    await getBanners().then((list) => {
      console.log(list)
      if (list.length !== 0) {
        list.forEach((bannerData, index) => {
          if (bannerData.category !== "") {
            categories.forEach((category, i) => {
              if (category.id === bannerData?.category) {
                bannerData.category = category?.data?.titleEn

                getSubCategoryById(category.id, bannerData.subCategory).then((res) => {
                  console.log(res)
                  if (res.length !== 0) {
                    bannerData.subCategory = res[0].data.titleEn
                  }
                })
              }
            })
          }

          setBannersList((oldData) => [...oldData, bannerData])

          if (list.length - 1 === index) {
            setLoading(false)
          }
        });
      }
    })
  }

  const [showPlaceHolder, setShowPlaceHolder] = useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setShowPlaceHolder(false)
    }, 100);
  }, [])

  useEffect(() => {
    setLoading(true)
    setIsActionComplete(false)
    setIsClicked(false)
    fetchBanners()

  }, [])

  useEffect(() => {
    console.log("banners list:", bannersList)
  }, [bannersList])

  return (
    <div
      className={cn(styles.product)}

    >
      {
        isClicked ?
          !isActionComplete ?
            <LoadingDialog />
            : window.location.reload()
          : <></>
      }
      {
        loading ? <LoadingIndicator /> :
          bannersList?.map((banner, index) => {
            return (
              <Row
                item={banner}
                key={index}
                up={bannersList.length - index <= 2}
                value={selectedFilters.includes(banner.id)}
                onChange={() => handleChange(banner.id)}
                setIsActionComplete={setIsActionComplete}
                setIsClicked={setIsClicked}
              />
              // <div
              //   key={index}
              //   className={cn(styles.preview, { [styles.active]: visible })}
              //   style={{
              //     backgroundImage: showPlaceHolder ? `url('/images/content/placeholderImg.png')` : `url(${banner?.coverImage})`,
              //     backgroundRepeat: 'no-repeat',
              //     backgroundPositionX: 'center',
              //     backgroundPositionY: 'center',
              //     backgroundSize: 'cover',
              //   }}
              //   onMouseLeave={() => setVisibleActions(false)}
              // >
              //   <div className={styles.bannerTitleContainer}>
              //     <div className={styles.title}>{banner.titleEn}</div>
              //     <Control
              //       className={styles.control}
              //       visibleActions={visibleActions}
              //       setVisibleActions={setVisibleActions}
              //       up={bannersList.length - index <= 2}
              //       data={banner}
              //       key={index}
              //       setIsActionComplete={setIsActionComplete}
              //       setIsClicked={setIsClicked}
              //     />
              //   </div>
              // </div>
            )
          })
      }



    </div>
  );
};

export default Product;

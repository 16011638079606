import React, { useState, useEffect, useContext } from "react";
import styles from "./Market.module.sass";
import Icon from "../../../../components/Icon";
import Row from "./Row";
import { getCoupons } from "../../../../Firebase/firebaseDatabase";
import LoadingIndicator from '../../../../LoadingScreen/LoadingIndicator'
import { GlobalState } from "../../../../App";
import LoadingDialog from "../../../../LoadingScreen/LoadingDialog";

const Market = ({ searchBy }) => {

  const [selectedFilters, setSelectedFilters] = useState([]);
  const [isLoading, setIsLoading] = useState(true)
  const { setIsActionComplete, isActionComplete, isClicked } = useContext(GlobalState)
  const handleChange = (id) => {
    if (selectedFilters.includes(id)) {
      setSelectedFilters(selectedFilters.filter((x) => x !== id));
    } else {
      setSelectedFilters((selectedFilters) => [...selectedFilters, id]);
    }
  };

  const [coupons, setCoupons] = useState()

  const fetchCouponsList = async () => {
    await getCoupons().then((list) => {
      setCoupons(list)
      setIsLoading(false)
    })
  }

  const [isPrevActive, setIsPrevActive] = useState(false)
  const [isNextActive, setIsNextActive] = useState(true)
  const [activePageNum, setActivePageNum] = useState(1)
  const [pageCount, setPageCount] = useState(1)

  const page_items_count = 10;
  const [range, setRange] = useState({
    start: 0,
    end: page_items_count
  })

  const [itemsList, setItemsList] = useState([])

  const calculatePageCount = (active_products) => {
    const calculated_page_count = active_products?.length / page_items_count;
    const check_if_isInteger = Number.isInteger(calculated_page_count)
    if (check_if_isInteger) {
      setPageCount(calculated_page_count)
      if (calculated_page_count === 1) {
      }
    } else {
      setPageCount(Math.floor(calculated_page_count) + 1)
      // console.log(Math.floor(calculated_page_count) + 1)
      if (Math.floor(calculated_page_count) + 1 === 1) {
      }
    }
  }

  useEffect(() => {

    if (searchBy !== null) {
      const active_products = coupons?.filter(data => {
        if (data.data.code.toLowerCase().includes(searchBy.toLowerCase())) {
          return data
        }
      })
      // console.log(active_products)
      setItemsList(active_products)
      calculatePageCount(active_products);
    } else {
      setItemsList(coupons)
      calculatePageCount(coupons);
    }

  }, [coupons, searchBy]);

  useEffect(() => {
    setIsActionComplete(false)
    fetchCouponsList()
  }, []);

  const handleChangePrevClick = () => {

    if (activePageNum > 1) {
      setIsNextActive(true)
      const updatedPageNum = activePageNum - 1;
      setActivePageNum(updatedPageNum)

      if (updatedPageNum === 1) {
        setIsPrevActive(false)
      } else {
        setIsPrevActive(true)
      }

      setRange({
        start: updatedPageNum * page_items_count - page_items_count,
        end: updatedPageNum * page_items_count
      })
    } else {
      setIsPrevActive(false)
    }
  }

  const handleChangeNextClick = () => {

    if (activePageNum < pageCount) {
      setIsPrevActive(true)
      const updatedPageNum = activePageNum + 1;
      setActivePageNum(updatedPageNum);

      if (updatedPageNum === pageCount) {
        setIsNextActive(false)
      } else {
        setIsNextActive(true)
      }

      setRange({
        start: updatedPageNum * page_items_count - page_items_count,
        end: updatedPageNum * page_items_count
      })

    } else {
      setIsNextActive(false)
    }
  }

  useEffect(() => {

  }, [coupons, isActionComplete, isClicked])

  return (

    <div className={styles.market}>
      {
        isClicked ?
          !isActionComplete ?
            <LoadingDialog />
            : window.location.reload()

          : <></>
      }
      {isLoading ? <LoadingIndicator /> :
        <>
          <div className={styles.table}>
            <div className={styles.row}>
              <div className={styles.col}>Coupon code</div>
              <div className={styles.col}>Condition</div>
              <div className={styles.col}>Amount/Percentage</div>
              <div className={styles.col}>Expiry date</div>
              <div className={styles.col}>Status</div>
            </div>
            {
              itemsList?.sort((a, b) => {
                return (
                  new Date(b.data.dateCreated).getTime() - new Date(a.data.dateCreated).getTime()
                )
              })
                ?.map((x, index) => {
                  return (
                    (index >= range.start) && (index < range.end) ?
                      <Row
                        item={x?.data}
                        key={index}
                        up={coupons.length - index <= 2}
                        value={selectedFilters.includes(x.id)}
                        onChange={() => handleChange(x.id)}
                      /> : <></>
                  )
                }
                )
            }
          </div>

          {
            pageCount !== 0 && pageCount !== 1 ?
              <div className={styles.foot}>
                <button
                  style={{
                    opacity: isPrevActive ? 1 : 0.1
                  }}
                  onClick={() => {
                    handleChangePrevClick()
                  }} className={styles.arrow}>
                  <Icon name="arrow-left" size="20" />
                </button>

                <button
                  style={{
                    opacity: isNextActive ? 1 : 0.1
                  }}
                  onClick={() => {
                    handleChangeNextClick()
                  }} className={styles.arrow}>
                  <Icon name="arrow-right" size="20" />
                </button>
              </div>
              : <></>
          }

        </>
      }
      {/* <div className={styles.foot}>
        <button className={styles.arrow}>
          <Icon name="arrow-left" size="20" />
        </button>
        <button className={styles.arrow}>
          <Icon name="arrow-right" size="20" />
        </button>
      </div> */}
    </div>
  );
};

export default Market;

import React, { useState } from "react";
import styles from "./Sidebar.module.sass";
import { Link, NavLink } from "react-router-dom";
import cn from "classnames";
import Icon from "../Icon";
import Dropdown from "./Dropdown";
import Image from "../Image";
import ShopingCart from '../Icons/cart.png'
import Target from '../Icons/target.svg'

const navigation = [
  {
    title: "Dashboard",
    icon: "home",
    url: "/",
  },
  {
    title: "Products",
    slug: ["products"],
    slug1: ["attributes"],
    icon: "diamond",
    add: true,
    dropdown: [
      {
        title: "List of products",
        url: "/products/dashboard",
      },
      {
        title: "Create product",
        url: "/products/add",
        // counter: "2",
        // colorCounter: "#FFBC99",
      },
      {
        title: "List of attributes",
        url: "/products/attributes",
      },
      {
        title: "Create attribute",
        url: "/products/attributes/add",
      },
      {
        title: "List of keywords",
        url: "/products/keywords",
      },
      {
        title: "Create keyword",
        url: "/products/keywords/add",
      },
    ],
  },
  {
    title: "Orders",
    icon: "store",
    url: "/orders",
  },
  {
    title: "Categories",
    icon: "pie-chart",
    url: "/categories",
  },
  {
    title: "Collections",
    icon: "pie-chart",
    url: "/collections",
  },
  {
    title: "Sellers",
    slug: ["seller"],
    icon: "profile-circle",
    dropdown: [
      {
        title: "List of sellers",
        url: "/sellers",
      },
      {
        title: "Create seller",
        url: "/seller/add",
      },
    ]
  },
  {
    title: "Home Page Customiser",
    image: Target,
    // url: "/homePageCustomiser",
    slug: ["featuredProducts"],
    slug1: ["banners"],
    dropdown: [
      {
        title: "Featured products list",
        url: "/featuredProducts",
      },
      {
        title: "Home page banners",
        url: "/banners",
      },
    ]
  },

  {
    title: "Coupons",
    icon: "promotion",
    slug: ["coupon"],
    dropdown: [
      {
        title: "List of coupons",
        url: "/coupons",
      },
      {
        title: "Create coupons",
        url: "/coupon/add",
      },
    ]
  },
  {
    title: "Notifications",
    icon: "notification",
    url: "/notifications",
  },
  {
    title: "Store Rules",
    image: ShopingCart,
    url: "/storeRules",
  },
];

const Sidebar = ({ className, onClose }) => {
  const [visibleHelp, setVisibleHelp] = useState(false);
  const [visible, setVisible] = useState(false);

  return (
    <>
      <div
        className={cn(styles.sidebar, className, { [styles.active]: visible })}
      >
        <button className={styles.close} onClick={onClose}>
          <Icon name="close" size="24" />
        </button>
        <Link className={styles.logo} to="/" onClick={onClose}>
          <Image
            className={styles.pic}
            src="/images/logo-dark.png"
            srcDark="/images/logo-light.png"
            alt="Core"
          />
        </Link>
        <div className={styles.menu}>
          {navigation.map((x, index) =>
            x.url ? (
              <NavLink
                className={styles.item}
                activeClassName={styles.active}
                to={x.url}
                key={index}
                exact
                onClick={onClose}
              >
                {x?.image ?
                  <img className={styles.cartImage} style={{
                    marginRight: '8px',
                    marginLeft: '-3px'
                  }} width={30} height={30} src={x.image} alt="" /> :
                  <Icon name={x.icon} size="24" />
                }
                {x.title}
              </NavLink>
            ) : (
              <Dropdown
                className={styles.dropdown}
                visibleSidebar={visible}
                setValue={setVisible}
                key={index}
                item={x}
                onClose={onClose}
              />
            )
          )}
        </div>
        <button className={styles.toggle} onClick={() => setVisible(!visible)}>
          <Icon name="arrow-right" size="24" />
          <Icon name="close" size="24" />
        </button>

      </div>

      <div
        className={cn(styles.overlay, { [styles.active]: visible })}
        onClick={() => setVisible(false)}
      ></div>
    </>
  );
};

export default Sidebar;

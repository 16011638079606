import React, { useContext, useEffect, useState } from "react";
import styles from "./NewProduct.module.sass";
import NameAndDescription from "./NameAndDescription";
import ImagesAndCTA from "./ImagesAndCTA";
import './newproduct.css';
import { GlobalState } from "../../App";
import Icon from "../../components/Icon";
import cn from "classnames";
import { useLocation } from "react-router";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Control from './Control'

const NewProduct = () => {

  const theme = useTheme();
  const matches1023 = useMediaQuery(theme.breakpoints.down(1024));

  const {
    formTypeProduct, setIsActionComplete, setIsClicked
  } = useContext(GlobalState);

  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleActions, setVisibleActions] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());

  const location = useLocation()

  const [activeProductId, setActiveProductId] = useState()
  const [activeVariationId, setActiveVariationId] = useState()

  useEffect(() => {
    const pageType = location.pathname.split("/")[2]

    if (pageType === "edit" || pageType === "details") {

      setActiveProductId(location.pathname.split("/")[3])
      setActiveVariationId(location.pathname.split("/")[4])

    }
  }, []);

  return (
    <>
      <div className={styles.row}>
        {
          !matches1023 ?
            <>
              <div className={styles.col}>
                <NameAndDescription className={styles.card} />
                <ImagesAndCTA className={styles.card} />
              </div>
              {
                formTypeProduct === "details" ?
                  <div
                    onMouseLeave={() => setVisibleActions(false)}
                    className={styles.col}>
                    <div className={styles.orderActionButtonsContainer}>
                      <button
                        onClick={() => { window.location.href = `/products/edit/${activeProductId}/${activeVariationId}` }}
                        className={cn("button", styles.button)} >
                        <Icon name="edit" size={22} />
                        <span>Edit product</span>
                      </button>

                      <Control
                        className={styles.control}
                        visibleActions={visibleActions}
                        setVisibleActions={setVisibleActions}
                        // up={up}
                        data={activeProductId}
                        setIsActionComplete={setIsActionComplete}
                        setIsClicked={setIsClicked}
                      />
                      {/* <button
                        className={cn("button", styles.button, styles.deleteProductBtn)} >
                        <Icon fill="red" name="trash" size={22} />
                        <span>Delete product</span>
                      </button> */}
                    </div>
                  </div>
                  : <></>
              }
            </>
            :
            <>
              {
                formTypeProduct === "details" ?
                  <div className={styles.col}>
                    <div className={styles.orderActionButtonsContainer}>
                      <button
                        onClick={() => { window.location.href = `/products/edit/${activeProductId}/${activeVariationId}` }}
                        className={cn("button", styles.button)} >
                        <Icon name="edit" size={22} />
                        <span>Edit product</span>
                      </button>

                      <Control
                        className={styles.control}
                        visibleActions={visibleActions}
                        setVisibleActions={setVisibleActions}
                        // up={up}
                        data={activeProductId}
                        setIsActionComplete={setIsActionComplete}
                        setIsClicked={setIsClicked}
                      />
                      {/* <button
                        className={cn("button", styles.button, styles.deleteProductBtn)} >
                        <Icon fill="red" name="trash" size={22} />
                        <span>Delete product</span>
                      </button> */}
                    </div>
                  </div>
                  : <></>
              }

              <div className={styles.col}>
                <NameAndDescription className={styles.card} />
                <ImagesAndCTA className={styles.card} />
              </div>
            </>
        }

      </div>
    </>
  );
};

export default NewProduct;

export const CREATE_NEW_PRODUCT = "CREATE_NEW_PROJECT"
export const UPDATE_PRODUCT = "UPDATE_PROJECT"
export const CREATE_NEW_ATTRIBUTE = "CREATE_NEW_ATTRIBUTE"
export const UPDATE_NEW_ATTRIBUTE = "UPDATE_NEW_ATTRIBUTE"
export const ORDER_STATUS = "ORDER_STATUS"
export const ORDER_STATUS_1 = "ORDER_STATUS_1"

// dropdown constants
export const FIXED_PRICE = "Fixed Price"
export const PERCENTAGE = "Percentage"

export const VARIATION = "variation"

export const PRODUCTS_TO_SHOW = 20



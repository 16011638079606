import React, { useState, useContext, useEffect } from "react";
import cn from "classnames";
import styles from "./CreateBanner.module.sass";
import Card from "../../components/Card";
import TextInput from "../../components/TextInput";
import Dropdown from "../../components/Dropdown";
import DropdownNew from "../../components/DropdownNew";
import { useHistory } from "react-router-dom";
import Icon from "../../components/Icon";
import File from "../../components/File";
import { createBanner, getCategories, getCollection, getSubCategories, uploadFile } from "../../Firebase/firebaseDatabase";
import { validate_if_not_empty } from "../../utils/extensions";
import DropdownSubCategory from "../../components/DropdownSubCategory";
import DropdownCategory from "../../components/DropdownCategory";
import { GlobalState } from "../../App";
import LoadingDialog from "../../LoadingScreen/LoadingDialog";
import DropdownCollection from "../../components/DropdownCollection";

const CreateBanner = ({ className }) => {

  let history = useHistory();

  const { isClicked, setIsClicked,
    isActionComplete, setIsActionComplete,
  } = useContext(GlobalState);

  const [optionsType, setOptionsType] = useState(["Select type", "Category", "Product", "Collection"])
  const [optionCategories, setOptionCategories] = useState(["Select category"]);
  const [optionSubCategories, setOptionSubCategories] = useState(["Select Sub Category"]);
  const [optionsCollection, setOptionsCollection] = useState(["Select collection"]);

  // form fileds states
  const [titleEn, setTitleEn] = useState()
  const [titleAr, setTitleAr] = useState()
  const [url, setUrl] = useState()
  const [image, setImage] = useState()
  const [productSku, setProductSku] = useState()
  const [type, setType] = useState(optionsType[0])
  const [category, setCategory] = useState(optionCategories[0])
  const [subCategory, setSubCategory] = useState(optionSubCategories[0])
  const [collection, setCollection] = useState(optionsCollection[0])

  // store all categories fetched from firebase
  const [allCategories, setAllCategories] = useState();
  const [allCollections, setAllCollections] = useState()

  const getCategory = async () => {
    const list = await getCategories();
    setAllCategories(list);
  };

  const fetchCollection = async () => {
    const list = await getCollection();
    console.log(list)
    setAllCollections(list);
  };

  useEffect(() => {
    setIsClicked(false)
    setIsActionComplete(false)
    getCategory();
    fetchCollection()
  }, [])

  useEffect(() => {
    setCategory(optionCategories[0])
    setSubCategory(optionSubCategories[0])
    setProductSku('')
    setCollection(optionsCollection[0])
  }, [type])

  const bannerObj = {
    titleEn: titleEn ?? '',
    titleAr: titleAr ?? '',
    url: url ?? '',
    coverImage: image ?? '',
    type: type ?? '',
    category: category === "Select category" ? "" : category ?? '',
    subCategory: subCategory === "Select Sub Category" ? '' : subCategory ?? '',
    productSKU: productSku ?? '',
    collection: collection === "Select collection" ? '' : collection ?? ''
  }

  useEffect(() => {
    if (allCategories?.length !== 0) {
      allCategories?.filter((data) =>
        setOptionCategories((oldData) => [...oldData, data?.data])
      );
    }
  }, [allCategories]);

  useEffect(() => {
    if (allCollections?.length !== 0) {
      allCollections?.filter((data) =>
        setOptionsCollection((oldData) => [...oldData, data?.data])
      );
    }
  }, [allCollections]);

  const getSubCategory = async (categoryId) => {
    const list = await getSubCategories(categoryId);
    list.filter((data) =>
      setOptionSubCategories((oldData) => [...oldData, data?.data])
    );
  };

  useEffect(() => {
    if (validate_if_not_empty(category)) {
      if (allCategories?.length !== 0) {
        allCategories?.filter((data) => {
          if (data?.data?.id === category[0]?.id) {
            setOptionSubCategories(["Select Sub Category"]);
            getSubCategory(category[0]?.id);
          }
        });
      }
    }
  }, [category]);

  const validateAllFields = () => {
    if (
      validate_if_not_empty(titleEn, "bannerTitleEnErrId") &&
      validate_if_not_empty(titleAr, "bannerTitleArErrId") &&
      (type === "Category" ?
        validate_if_not_empty(category, "bannerCategoryErrId") :
        type === "Product" ?
          validate_if_not_empty(productSku, "bannerProductSkuErrId") :
          type === "Collection" &&
          validate_if_not_empty(collection, "bannerCollectionErrId")
      ) &&
      validate_if_not_empty(image, "bannerImageErrId")
    ) {
      return true
    } else {
      return false
    }
  }

  const handleChangeFiles = () => {
    const id = document.getElementById('bannerCoverImg');
    setImage(id?.files[0])
  }

  return (
    <Card
      className={cn(styles.card, className)}
      title="Add new banner"
      classTitle="title-green"
      head={
        <div style={{
          display: 'flex',
          gap: '12px'
        }}>

          <button className={cn("button", styles.backButton)} onClick={() => history.goBack()} >
            <Icon name="arrow-left" size={20} fill="black" />
            <span>Back</span>
          </button>
          <button
            onClick={async () => {
              setIsClicked(true)
              const isValid = validateAllFields()
              if (isValid) {
                console.log(bannerObj)
                if (bannerObj.type === "Category") {
                  bannerObj.category = bannerObj.category[0].id
                  if (bannerObj.subCategory !== "") {
                    bannerObj.subCategory = bannerObj.subCategory[0].id
                  }
                }
                if (bannerObj.type === "Collection") {
                  bannerObj.collection = bannerObj.collection[0].id
                }

                const uploadedUrl = await uploadFile(bannerObj.coverImage, "banner")
                bannerObj.coverImage = uploadedUrl

                console.log(bannerObj)

                await createBanner(bannerObj).then((res) => {
                  if (res === true) {
                    setIsActionComplete(true)
                    setIsClicked(false)
                  }
                })

              } else {
                setIsClicked(false)
              }
            }}
            className={cn("button", styles.button)} >
            <span>Save</span>
          </button>
        </div>

      }
    >
      {
        isClicked ?
          isActionComplete ?
            window.location.href = "/banners"
            :
            <LoadingDialog />
          :
          <></>
      }

      {/* {loading ?
        <LoadingIndicator />
        : */}
      <div className={styles.description}>

        <div className={styles.fieldContainer}>
          <TextInput
            className={styles.field}
            classInput={styles.fieldInput}
            label="Title (en)"
            name="bannerTitleEn"
            type="text"
            onChange={(e) => { setTitleEn(e.target.value) }}
            value={titleEn}
            required
          />
          <p className={styles.errField} id="bannerTitleEnErrId"></p>
        </div>

        <div className={styles.fieldContainer}>
          <TextInput
            className={styles.field}
            classInput={styles.fieldInput}
            label="Title (ar)"
            name="bannerTitleAr"
            type="text"
            onChange={(e) => { setTitleAr(e.target.value) }}
            value={titleAr}
            required
          />
          <p className={styles.errField} id="bannerTitleArErrId"></p>
        </div>

        <div className={styles.fieldContainer}>
          <Dropdown
            label="Type (optional)"
            value={type}
            setValue={setType}
            options={optionsType}
          />
          <p className={styles.errField} id="bannerTypeErrId"></p>
        </div>

        {
          type === "Category" ?
            <div className={styles.fieldset}>
              <div className={`${styles.fieldContainer}  ${styles.smallFields}`}>
                <DropdownCategory
                  className={styles.smallDropdownField}
                  classDropdownLabel={styles.smallDropdownlabel}
                  label="Category"
                  value={category}
                  setValue={setCategory}
                  options={optionCategories}
                />
                <p className={styles.errField} id="bannerCategoryErrId"></p>
              </div>

              {validate_if_not_empty(category) ? (
                <div className={`${styles.fieldContainer}  ${styles.smallFields}`}>
                  <DropdownSubCategory
                    className={styles.smallDropdownField}
                    classDropdownLabel={styles.smallDropdownlabel}
                    label="Sub Category (optional)"
                    value={subCategory}
                    setValue={setSubCategory}
                    options={optionSubCategories}
                  />
                  <p className={styles.errField} id="bannerSubCategoryErrId"></p>
                </div>
              ) : (
                <></>
              )}
            </div>
            : type === "Product" ?

              <div className={styles.fieldContainer}>
                <TextInput
                  className={styles.field}
                  classInput={styles.fieldInput}
                  label="Product SKU"
                  name="bannerProductSku"
                  onChange={(e) => {
                    setProductSku(e.target.value)
                  }}
                  value={productSku}
                  required
                />
                <p className={styles.errField} id="bannerProductSkuErrId"></p>
              </div>

              : type === "Collection" &&
              <div className={styles.fieldContainer}>
                <DropdownCollection
                  label="Collection"
                  value={collection}
                  setValue={setCollection}
                  options={optionsCollection}
                />
                <p className={styles.errField} id="bannerCollectionErrId"></p>
              </div>
        }

        <div className={styles.fieldContainer}>
          <TextInput
            className={styles.field}
            classInput={styles.fieldInput}
            label="URL (optional)"
            name="bannerUrl"
            onChange={(e) => {
              setUrl(e.target.value)
            }}
            value={url}
            required
          />
          <p className={styles.errField} id="bannerUrlErrId"></p>
        </div>

        <div className={styles.image_section}>

          <div className={styles.files}>
            <File
              classInput={styles.imageInput}
              className={styles.imageField}
              classInputWraper={styles.imageFieldWrapper}
              title={
                image?.name !== '' && image?.name !== undefined ?
                  image?.name
                  : "Click or drop image"
              }
              id={`bannerCoverImg`}
              label="Image"
              onChange={() => handleChangeFiles()}
            // url={isEditForm ? sellerLogo : ''}
            />
          </div>
          <p className={styles.errField} id="bannerImageErrId"></p>
        </div>

      </div>
      {/* } */}

    </Card >
  );
};

export default CreateBanner;
